import { Component, Input } from '@angular/core';

@Component({
  selector: 'progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['progress-bar.component.scss'],
})
export class ProgressBarComponent {
  @Input() determinate: boolean = true;
  @Input() value: number = 0;
  @Input() disabled: boolean = false;

  get progressWidth() {
    return Math.floor(this.value);
  }
}
