<mat-form-field class="w-full flex-auto s-mat-dense s-mat-no-subscript">
  <div class="inline-block selected-option" *ngIf="selectedCompany">
    <div class="inline-block w-9" *ngIf="selectedCompany.imageUrl">
      <img src="{{ selectedCompany.imageUrl }}" class="icon-size-6 rounded-md mt-1" />
    </div>
  </div>
  <mat-select
    [placeholder]="this.isLoading ? 'Loading companies...' : 'Select company'"
    [(ngModel)]="selectedCompany"
    (ngModelChange)="onSelectedCompany()"
    [disabled]="disabled || isLoading">
    <div *ngIf="companies.length">
      <mat-option *ngFor="let company of companies" [value]="company">
        <div class="flex mt-1">
          <div class="inline-block justify-self-start" *ngIf="company?.imageUrl">
            <img src="{{ company.imageUrl }}" class="mr-3 rounded-md icon-size-6" />
          </div>
          <div class="inline-block w-100 truncate">{{ company?.name }}</div>
        </div>
      </mat-option>
    </div>
  </mat-select>
</mat-form-field>
