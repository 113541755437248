import { Pipe, PipeTransform } from '@angular/core';
import { AppCampaignStatus } from '@shared/AppEnums';
import { StatusBadgeStatusType } from '@shared/components/user-interface/status-badge/status-badge.component';

@Pipe({
  name: 'campaignStatusText',
})
export class CampaignsStatusTextPipe implements PipeTransform {
  transform(status: AppCampaignStatus): StatusBadgeStatusType {
    return (
      {
        [AppCampaignStatus.DRAFT]: 'Draft',
        [AppCampaignStatus.LOCKED]: 'Locked',
        [AppCampaignStatus.PAUSED]: 'Paused',
        [AppCampaignStatus.CANCELED]: 'Canceled',
        [AppCampaignStatus.FAILED]: 'Failed',
        [AppCampaignStatus.FINISHED]: 'Finished',
        [AppCampaignStatus.IN_PROGRESS]: 'Ongoing',
        [AppCampaignStatus.IN_PROGRESS_TRAILING]: 'Ongoing',
        [AppCampaignStatus.STARTING]: 'Starting',
      }[status as string] || 'default'
    );
  }
}
