import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'svg-icon',
  templateUrl: 'svg-icon.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AsyncPipe],
})
export class SvgIconComponent implements OnChanges {
  @Input() public iconPath: string;
  public svgValue$: Observable<string>;

  constructor(
    private httpClient: HttpClient,
    private sanitizer: DomSanitizer,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    this.svgValue$ = this.httpClient
      .get(`${this.iconPath}`, { responseType: 'text' })
      .pipe(map((value) => this.sanitizer.bypassSecurityTrustHtml(value) as string));
  }
}
