import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'abp-modal-footer',
  templateUrl: './abp-modal-footer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AbpModalFooterComponent {
  @Input() cancelLabel = 'Cancel';
  @Input() cancelDisabled: boolean;
  @Input() saveLabel = 'Save';
  @Input() saveDisabled: boolean;

  @Output() onCancelClick = new EventEmitter<number>();
}
