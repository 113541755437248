import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { AppSSIScoreLabels } from '@shared/AppEnums';
import { LinkedInUserProfileSsiDto } from '@shared/service-proxies/service-proxies';
@Component({
  selector: 'ssi-bar-chart-component',
  templateUrl: './ssi-bar-chart.component.html',
  styleUrls: ['./ssi-bar-chart.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SSIBarChartComponent implements OnInit {
  @Input() ssiData: LinkedInUserProfileSsiDto;
  public AppSSIScoreLabels = AppSSIScoreLabels;
  constructor() {}

  ngOnInit(): void {}
}
