import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  LinkedInUserListDto,
  LinkedInUserListServiceProxy,
} from '@shared/service-proxies/service-proxies';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'rename-user-list-dialog',
  templateUrl: 'rename-user-list-dialog.component.html',
})
export class RenameUserListDialogComponent {
  list: LinkedInUserListDto;
  isSaving: boolean = false;
  newListName: string = '';

  constructor(
    private _linkedinUserListsService: LinkedInUserListServiceProxy,
    private _dialogRef: MatDialogRef<RenameUserListDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
  ) {
    this.list = this._data.list;
    this.newListName = this.list.name;
  }

  onSubmit(): void {
    this.isSaving = true;
    this._dialogRef.disableClose = true;
    this._linkedinUserListsService
      .renameList(this.list.id, this.newListName)
      .pipe(
        finalize(() => {
          this._dialogRef.disableClose = false;
          this.isSaving = false;
        }),
      )
      .subscribe((res) => {
        this._dialogRef.close(this.newListName);
      });
  }

  onCancelRename(): void {
    this._dialogRef.close(null);
  }
}
