<div class="md:flex md:flex-col sm:block text-center">
  <code-input
    #codeInput
    class="w-full"
    [isCodeHidden]="false"
    [class.invalid-pin]="applyRedBorder"
    [codeLength]="6"
    (codeChanged)="onCodeChange($event)"
    code=""
    [disabled]="disabled"></code-input>
</div>
