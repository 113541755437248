import { Component, EventEmitter, Input, Output } from '@angular/core';
import { appModuleAnimation } from '@shared/animations/routerTransition';

@Component({
  selector: 'from-to-timepicker',
  templateUrl: './from-to-timepicker.component.html',
  animations: [appModuleAnimation()],
})
export class FromToTimePickerComponent {
  @Input() fromLabel: string = 'From';
  @Input() toLabel: string = 'To';
  @Input() disabled: boolean;
  @Input() format: number = 24;

  @Input() fromTime: string;
  @Output() fromTimeChange = new EventEmitter<string>();
  @Input() toTime: string;
  @Output() toTimeChange = new EventEmitter<string>();
  @Output() change = new EventEmitter<void>();

  onFromTimeChange(): void {
    const startTimeSeparated = this.fromTime.split(':');
    const startTimeVal = parseInt(startTimeSeparated[0]) * 60 + parseInt(startTimeSeparated[1]);
    const endTimeSeparated = this.toTime.split(':');
    const endTimeVal = parseInt(endTimeSeparated[0]) * 60 + parseInt(endTimeSeparated[1]);

    if (startTimeVal > endTimeVal) {
      this.toTime = this.fromTime;
      this.toTimeChange.emit(this.toTime);
    }

    this.fromTimeChange.emit(this.fromTime);
    this.change.emit();
  }

  onToTimeChange(): void {
    const startTimeSeparated = this.fromTime.split(':');
    const startTimeVal = parseInt(startTimeSeparated[0]) * 60 + parseInt(startTimeSeparated[1]);
    const endTimeSeparated = this.toTime.split(':');
    const endTimeVal = parseInt(endTimeSeparated[0]) * 60 + parseInt(endTimeSeparated[1]);

    if (startTimeVal > endTimeVal) {
      this.fromTime = this.toTime;
      this.fromTimeChange.emit(this.fromTime);
    }

    this.toTimeChange.emit(this.toTime);
    this.change.emit();
  }
}
