<div class="flex flex-row m-0 items-center gap-x-3 p-0">
  <user-avatar
    [imageUrl]="profile?.imageUrl"
    [fullName]="profile?.fullName"
    [firstName]="profile?.firstName"
    [lastName]="profile?.lastName"
    [emailAddress]="emailAddress"
    [tiny]="tiny"></user-avatar>
  <div class="flex flex-col">
    <user-display-name [profile]="profile" [emailAddress]="emailAddress"></user-display-name>
    <linkedin-account-subscriptions
      *ngIf="linkedInAccount && showSubscriptionExpanded"
      [account]="linkedInAccount"
      [showSubscriptionName]="true"
      [textSize]="'small'"
      [iconsSize]="'small'"></linkedin-account-subscriptions>
  </div>
  <div class="flex justify-center items-center" *ngIf="showSubscriptionSmall">
    <linkedin-user-profile-subscriptions [profile]="profile"></linkedin-user-profile-subscriptions>
  </div>
</div>
