<div class="flex items-center justify-center text-base mt-2 px-10">
  <ng-container *ngFor="let step of stepsData; let index = index">
    <div class="flex items-center" [ngClass]="{ 'opacity-50': !step.active && !step.success }">
      <div
        class="ml-4 w-5 h-5 rounded-full flex items-center justify-center text-white text-base font-medium"
        [ngClass]="{
          'bg-primary': step.active,
          'bg-secondary': !step.active,
          'bg-success': step.success
        }">
        <span *ngIf="!step.success">{{ step.position }}</span>
        <span *ngIf="step.success">
          <mat-icon class="icon-size-2 relative top-0.5" svgIcon="custom:checkmark-only"></mat-icon>
        </span>
      </div>
      <span
        class="ml-2 font-medium min-w-max mr-4 sm:hidden lg:block xl:block md:block"
        [ngClass]="{ 'text-primary': step.active, 'text-success': step.success }">
        {{ step.name }}
      </span>
    </div>
    <div
      *ngIf="index < stepsData.length - 1"
      class="h-0.5 lg:w-1/12 md:w-4 bg-secondary-muted"></div>
  </ng-container>
</div>
