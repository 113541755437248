<div class="flex flex-col items-center gap-y-4 w-100">
  <h1>Export Lead/s to CRM</h1>

  <div class="flex flex-col items-center w-full items-stretch gap-4">
    <mat-label>CRM</mat-label>
    <mat-form-field>
      <mat-select [(ngModel)]="crmIntegrationId" (ngModelChange)="onSelectedCrmChange()">
        <mat-option *ngFor="let crm of crmIntegrations" [value]="crm.id">
          <crm-image-name
            [crmName]="crm.name"
            [crmImageUrl]="getCrmTypeImage(crm.crmType)"></crm-image-name>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <ng-container *ngIf="leadStages?.length > 0">
      <mat-label>Lead Stage (optional)</mat-label>
      <mat-form-field>
        <mat-select [disabled]="!crmIntegrationId" [(ngModel)]="exportProperties.contactLabel">
          <mat-option *ngFor="let leadStage of leadStages" [value]="leadStage.label">
            {{ leadStage.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>
    <ng-container *ngIf="leadOwners?.length > 0">
      <mat-label>Lead Owner (optional)</mat-label>
      <mat-form-field>
        <mat-select [disabled]="!crmIntegrationId" [(ngModel)]="exportProperties.contactOwner">
          <mat-option *ngFor="let leadOwner of leadOwners" [value]="leadOwner.id">
            {{
              leadOwner.firstName && leadOwner.lastName
                ? leadOwner.firstName + ' ' + leadOwner.lastName
                : leadOwner.email
            }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>
    <ng-container *ngIf="leadStatuses?.length > 0">
      <mat-label>Lead Status (optional)</mat-label>
      <mat-form-field>
        <mat-select [disabled]="!crmIntegrationId" [(ngModel)]="exportProperties.contactStatus">
          <mat-option *ngFor="let leadStatus of leadStatuses" [value]="leadStatus.id">
            {{ leadStatus.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>
    <button
      [disabled]="isCrmButtonDisabled"
      heyreachButton
      buttonType="primary"
      (click)="exportToCrm()">
      <span [loading]="loading">Export</span>
    </button>
  </div>
</div>
