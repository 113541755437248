<div
  [ngClass]="{ 'flex-col': showSubscriptionName, 'flex-row': !showSubscriptionName }"
  class="flex gap-x-2">
  <div class="inline-flex items-center justify-start gap-x-1" *ngIf="showPremium">
    <mat-icon
      matTooltip="LinkedIn Premium"
      [ngClass]="['text-linkedin-premium', 'icon-size-' + (iconsSize === 'small' ? 4 : 5)]"
      svgIcon="custom:linkedin-premium"></mat-icon>
    <span *ngIf="showSubscriptionName" [ngClass]="{ 'text-sm': textSize === 'small' }">
      LinkedIn Premium
    </span>
  </div>
  <div class="inline-flex items-center justify-start gap-x-1" *ngIf="account?.hasNavigator">
    <mat-icon
      matTooltip="LinkedIn Sales Navigator"
      [ngClass]="[
        account.salesNavigatorState == SalesNavigatorState._1 &&
        account.accountState == LinkedInAccountState._1
          ? 'text-linkedin-sales-navigator'
          : 'text-muted',
        'icon-size-' + (iconsSize === 'small' ? 4 : 5)
      ]"
      svgIcon="custom:linkedin-sales-navigator"></mat-icon>
    <span
      class="pt-0.5"
      *ngIf="showSubscriptionName"
      [ngClass]="{
        'text-sm': textSize === 'small',
        'text-muted': !account.isValidNavigator && account.authIsValid
      }">
      Sales Navigator
    </span>
    <svg-icon
      matTooltip="Connecting Sales Navigator"
      class="animate-spin-mirror-slow"
      *ngIf="account.hasNavigator && account.salesNavigatorState == SalesNavigatorState._0"
      [iconPath]="'/assets/icons/accounts-icons/syncing-icon.svg'"></svg-icon>
    <mat-icon
      *ngIf="
        account.salesNavigatorState == SalesNavigatorState._2 &&
        account.accountState == LinkedInAccountState._1
      "
      svgIcon="custom:warn"
      [matTooltip]="invalidNavigatorTooltip"
      class="icon-size-4 text-muted"></mat-icon>
  </div>
  <div class="inline-flex items-center justify-start gap-x-1" *ngIf="account?.hasRecruiter">
    <mat-icon
      matTooltip="LinkedIn Recruiter"
      [ngClass]="[
        account.recruiterState == RecruiterState._1 &&
        account.accountState == LinkedInAccountState._1
          ? 'text-linkedin-recruiter'
          : 'text-muted',
        'icon-size-' + (iconsSize === 'small' ? 4 : 5)
      ]"
      svgIcon="custom:linkedin-recruiter"></mat-icon>
    <span
      class="pt-0.5"
      *ngIf="showSubscriptionName"
      [ngClass]="{
        'text-sm': textSize === 'small',
        'text-muted': !account.isValidRecruiter && account.authIsValid
      }">
      Recruiter
    </span>
    <svg-icon
      matTooltip="Connecting Recruiter"
      class="animate-spin-mirror-slow"
      *ngIf="account.hasRecruiter && account.recruiterState == RecruiterState._0"
      [iconPath]="'/assets/icons/accounts-icons/syncing-icon.svg'"></svg-icon>
    <mat-icon
      *ngIf="
        account.recruiterState == RecruiterState._2 &&
        account.accountState == LinkedInAccountState._1
      "
      svgIcon="custom:warn"
      [matTooltip]="invalidRecruiterTooltip"
      class="icon-size-4 text-muted"></mat-icon>
  </div>

  <span class="font-medium text-base" *ngIf="showFreeAccountText">Free Account</span>
</div>
