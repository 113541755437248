import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'user-avatar',
  styleUrls: ['./user-avatar.component.scss'],
  templateUrl: './user-avatar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserAvatarComponent implements OnInit {
  @Input() imageUrl: string;
  @Input() firstName: string;
  @Input() lastName: string;
  @Input() fullName: string;
  @Input() emailAddress: string;
  @Input() size: number = 1.6;
  @Input() hasDynamicFont: boolean = true;
  @Input() customFontSize: number = 23;
  @Input() tiny: boolean = false;

  fontSize: number;

  ngOnInit(): void {
    this.fontSize = this.size / 1.8;
  }
}
