<div class="absolute text-center adjust-overall-info" *ngIf="ssiData?.memberScore">
  <p
    [class.text-2xl]="!infoSizeLarge"
    [class.text-4xl]="infoSizeLarge"
    class="font-bold leading-4 tracking-normal">
    {{ ssiData?.memberScore?.overall | number: '1.1-1' }}
  </p>
  <p
    *ngIf="ssiData?.memberScore?.overall != null && ssiData?.memberScore?.overall !== undefined"
    [class.text-base]="!infoSizeLarge"
    [class.text-xl]="infoSizeLarge"
    class="text-normal text-gray-400 tracking-normal">
    out of 100
  </p>
</div>

<apx-chart
  class="w-full flex"
  [chart]="chartOptions.chart"
  [labels]="chartOptions.labels"
  [colors]="chartOptions.colors"
  [series]="chartOptions.series"
  [stroke]="chartOptions.stroke"
  [tooltip]="chartOptions.tooltip"
  [xaxis]="chartOptions.xaxis"
  [yaxis]="chartOptions.yaxis"
  [plotOptions]="chartOptions.plotOptions"></apx-chart>
