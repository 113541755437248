import {
  AfterContentChecked,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { MessageBoxType } from '../table-models/message-box-type.model';
import { hasNoChildNodes } from '../helpers/has-no-child-nodes';

@Component({
  selector: 'app-message-box',
  templateUrl: './message-box.component.html',
  styleUrls: ['./message-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageBoxComponent implements AfterContentChecked {
  @Input() public title = '';
  @Input() public content!: string;
  @Input() public icon!: string;
  @Input() public closeable!: boolean;
  @Input() public type: MessageBoxType = 'info';
  @Output() public readonly closed = new EventEmitter<void>();

  @ViewChild('contentContainer') private readonly contentContainer!: ElementRef<HTMLElement>;

  public hasContent!: boolean;

  public ngAfterContentChecked(): void {
    this.hasContent = !hasNoChildNodes(this.contentContainer?.nativeElement) && !!this.content;
  }
}
