import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'abp-modal-header',
  templateUrl: './abp-modal-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AbpModalHeaderComponent {
  @Input() title: string;

  @Output() onCloseClick = new EventEmitter<number>();
}
