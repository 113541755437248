import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LinkedInUserProfileDto } from '@shared/service-proxies/service-proxies';

@Component({
  selector: 'user-display-name',
  templateUrl: './user-display-name.component.html',
  styleUrls: ['./user-display-name.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserDisplayNameComponent {
  @Input() profile: LinkedInUserProfileDto;
  @Input() emailAddress: string;
  @Input() tooltipDisabled: boolean = false;
}
