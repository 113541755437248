import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tagUpdateInProgress',
})
export class TagUpdateInProgressPipe implements PipeTransform {
  transform(tagId: number, inProgressTags: number[]): boolean {
    return inProgressTags.includes(tagId);
  }
}
