import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import {
  CampaignDto,
  CampaignServiceProxy,
  CreateCampaignDto,
  UpdateCampaignDto,
} from '@shared/service-proxies/service-proxies';
import { catchError, finalize, map, switchMap, tap } from 'rxjs/operators';
import { AppCampaignStatus } from '@shared/AppEnums';
import { NewCampaignDialogComponent } from '@app/campaigns/new-campaign-dialog/new-campaign-dialog.component';
import { MatDialog } from '@angular/material/dialog';

export interface CampaignActionPerformedData {
  campaignId: number;
  status: AppCampaignStatus | 'DISCARDED';
}

@Injectable({
  providedIn: 'root',
})
export class CampaignActionsService {
  public campaignsWhereThereIsAnOperationInProgressSubject$: BehaviorSubject<number[]> =
    new BehaviorSubject<number[]>([]);
  public campaignsWhereThereIsAnOperationInProgress$: Observable<number[]> =
    this.campaignsWhereThereIsAnOperationInProgressSubject$.asObservable();

  private onCampaignActionPerformedSubject$: BehaviorSubject<CampaignActionPerformedData> =
    new BehaviorSubject<CampaignActionPerformedData>(undefined);
  public onCampaignActionPerformed$: Observable<CampaignActionPerformedData> =
    this.onCampaignActionPerformedSubject$.asObservable();

  private isCampaignCreationInProgressSubject$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public isCampaignCreationInProgress$: Observable<boolean> =
    this.isCampaignCreationInProgressSubject$.asObservable();

  private onCampaignCreatedSubject$: BehaviorSubject<number> = new BehaviorSubject<number>(
    undefined,
  );
  public onCampaignCreated$: Observable<number> = this.onCampaignCreatedSubject$.asObservable();

  constructor(
    private _campaignsService: CampaignServiceProxy,
    private _dialog: MatDialog,
  ) {}

  public discard(campaignId: number): void {
    this.campaignsWhereThereIsAnOperationInProgressSubject$.next([
      ...this.campaignsWhereThereIsAnOperationInProgressSubject$.value,
      campaignId,
    ]);
    this._campaignsService
      .discard(campaignId)
      .pipe(
        finalize(() => {
          this.campaignsWhereThereIsAnOperationInProgressSubject$.next(
            this.campaignsWhereThereIsAnOperationInProgressSubject$.value.filter(
              (id) => id !== campaignId,
            ),
          );
        }),
      )
      .subscribe(() => {
        this.onCampaignActionPerformedSubject$.next({
          campaignId: campaignId,
          status: 'DISCARDED',
        });
      });
  }

  public cancel(campaignId: number): void {
    this.campaignsWhereThereIsAnOperationInProgressSubject$.next([
      ...this.campaignsWhereThereIsAnOperationInProgressSubject$.value,
      campaignId,
    ]);
    this._campaignsService
      .cancelCampaign(campaignId)
      .pipe(
        finalize(() => {
          this.campaignsWhereThereIsAnOperationInProgressSubject$.next(
            this.campaignsWhereThereIsAnOperationInProgressSubject$.value.filter(
              (id) => id !== campaignId,
            ),
          );
        }),
      )
      .subscribe(() => {
        this.onCampaignActionPerformedSubject$.next({
          campaignId: campaignId,
          status: AppCampaignStatus.CANCELED,
        });
      });
  }

  public pause(campaignId: number): void {
    this.campaignsWhereThereIsAnOperationInProgressSubject$.next([
      ...this.campaignsWhereThereIsAnOperationInProgressSubject$.value,
      campaignId,
    ]);
    this._campaignsService
      .pause(campaignId)
      .pipe(
        finalize(() => {
          this.campaignsWhereThereIsAnOperationInProgressSubject$.next(
            this.campaignsWhereThereIsAnOperationInProgressSubject$.value.filter(
              (id) => id !== campaignId,
            ),
          );
        }),
      )
      .subscribe(() => {
        this.onCampaignActionPerformedSubject$.next({
          campaignId: campaignId,
          status: AppCampaignStatus.PAUSED,
        });
      });
  }

  public resume(campaignId: number) {
    this.campaignsWhereThereIsAnOperationInProgressSubject$.next([
      ...this.campaignsWhereThereIsAnOperationInProgressSubject$.value,
      campaignId,
    ]);
    this._campaignsService
      .resume(campaignId)
      .pipe(
        finalize(() => {
          this.campaignsWhereThereIsAnOperationInProgressSubject$.next(
            this.campaignsWhereThereIsAnOperationInProgressSubject$.value.filter(
              (id) => id !== campaignId,
            ),
          );
        }),
      )
      .subscribe(() => {
        this.onCampaignActionPerformedSubject$.next({
          campaignId: campaignId,
          status: AppCampaignStatus.STARTING,
        });
      });
  }

  public updateCampaignStatus(campaignId: number, status: AppCampaignStatus) {
    this.onCampaignActionPerformedSubject$.next({ campaignId: campaignId, status: status });
  }

  public createNewCampaign(name: string) {
    this.isCampaignCreationInProgressSubject$.next(true);
    this._campaignsService
      .createDraft(new CreateCampaignDto({ name: name }))
      .pipe(
        finalize(() => {
          this.isCampaignCreationInProgressSubject$.next(false);
        }),
      )
      .subscribe((id) => {
        this.onCampaignCreatedSubject$.next(id);
        setTimeout(() => this.onCampaignCreatedSubject$.next(undefined), 0);
      });
  }

  renameCampaign(campaign: CampaignDto): Observable<string> {
    return this._dialog
      .open(NewCampaignDialogComponent, {
        width: 'auto',
        maxHeight: '95vh',
        data: {
          campaignName: campaign.name,
        },
      })
      .afterClosed()
      .pipe(
        switchMap((campaignName: string) => {
          const previousName = campaign.name;
          if (campaignName) {
            const updateDto = new UpdateCampaignDto();
            updateDto.id = campaign.id;
            updateDto.name = campaignName;
            return this._campaignsService.renameCampaign(updateDto).pipe(
              map(() => campaignName),
              catchError(() => {
                return of(previousName);
              }),
            );
          } else {
            return of(previousName);
          }
        }),
      );
  }
}
