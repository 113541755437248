import { Pipe, PipeTransform } from '@angular/core';
import { AppCampaignStatus } from '@shared/AppEnums';

@Pipe({
  name: 'badgeStatusClass',
})
export class BadgeStatusClassPipe implements PipeTransform {
  transform(status: AppCampaignStatus): string {
    return BADGE_STATUS_CLASS_RECORD[status as string] || 'bg-warn-muted text-secondary';
  }
}

export const BADGE_STATUS_CLASS_RECORD = {
  danger: 'bg-danger-muted text-warn',
  warning: 'bg-warning-muted text-warning',
  success: 'bg-success-muted text-success',
  progress: 'bg-primary-muted text-primary',
  idle: 'bg-warn-muted text-secondary',
  queued: 'bg-queued-muted text-queued',
  challenged: 'bg-challenged-background text-challenged-background-text',
};
