import { Component, Input } from '@angular/core';
import { LinkedInUserListDto } from '@shared/service-proxies/service-proxies';
import { AppListType } from '@shared/AppEnums';

@Component({
  selector: 'list-in-new-tab',
  templateUrl: './list-in-new-tab.component.html',
})
export class ListInNewTabComponent {
  @Input() list?: LinkedInUserListDto;
  @Input() listId?: number;
  @Input() listType?: AppListType;
}
