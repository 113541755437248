import { Component, ElementRef, Input, OnInit } from '@angular/core';
import {
  LinkedInAccountDto,
  LinkedInUserProfileDto,
} from '@shared/service-proxies/service-proxies';

@Component({
  selector: 'user-image-name',
  templateUrl: './user-image-name.component.html',
  styleUrls: ['./user-image-name.component.scss'],
})
export class UserImageNameComponent implements OnInit {
  @Input() profile: LinkedInUserProfileDto;
  @Input() emailAddress: string;
  @Input() showSubscriptionSmall: boolean = true;
  @Input() tiny: boolean = false;
  @Input() showSubscriptionExpanded: boolean = false;
  @Input() linkedInAccount: LinkedInAccountDto; // this field is used only for the premium plans (used only when we display our accounts)

  constructor(public element: ElementRef) {}

  ngOnInit(): void {}
}
