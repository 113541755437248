<ng-container *ngIf="company">
  <div class="flex flex-col items-center mt-8 px-7">
    <user-avatar [imageUrl]="company.imageUrl" [fullName]="company.name" [size]="9"></user-avatar>
    <div class="mt-4 text-lg font-medium text-center">
      {{ company.name }}
    </div>
  </div>

  <div class="py-5 px-7">
    <div class="mt-10 space-y-4">
      <div class="text-lg font-medium mb-3">Details</div>
      <ng-container *ngIf="company.description">
        <div>
          <div class="font-medium text-secondary">Description:</div>
          <div>{{ company.description }}</div>
        </div>
      </ng-container>
      <ng-container *ngIf="company.industry">
        <div>
          <div class="font-medium text-secondary">Industry:</div>
          <div>{{ company.industry }}</div>
        </div>
      </ng-container>
      <ng-container *ngIf="company.companySize">
        <div>
          <div class="font-medium text-secondary">Company size:</div>
          <div>{{ company.companySize }}</div>
        </div>
      </ng-container>
      <ng-container *ngIf="company.employeesOnLinkedIn">
        <div>
          <div class="font-medium text-secondary">Employees on Linkedin:</div>
          <div>{{ company.employeesOnLinkedIn }}</div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>
