import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostListener,
  Input,
  Renderer2,
} from '@angular/core';

@Directive({
  selector: '[table-header]',
  host: { class: 'bg-gray-50 z-10 border-b border-gray-200' },
})
export class TableHeaderDirective implements AfterViewInit {
  @Input() stickyHeader: boolean = false;

  constructor(
    private el: ElementRef,
    private _renderer: Renderer2,
  ) {}

  ngAfterViewInit(): void {
    if (this.stickyHeader) {
      this._renderer.addClass(this.el.nativeElement, 'sticky');
      this._renderer.addClass(this.el.nativeElement, 'top-0');
    }
  }
}
