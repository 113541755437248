import { Pipe, PipeTransform } from '@angular/core';
import {
  LinkedInAccountDto,
  LinkedInAccountState,
  LinkedInAccountStateReason,
  RecruiterState,
  SalesNavigatorState,
} from '@shared/service-proxies/service-proxies';
import { AccountActionData } from '@app/linkedin-accounts/linkedin-accounts.component';

@Pipe({
  name: 'accountActions',
  pure: true,
})
export class AccountActionsPipe implements PipeTransform {
  private static disconnectAction: AccountActionData = {
    action: 'disconnect',
    text: 'Disconnect',
    icon: 'assets/icons/accounts-icons/not-connected-icon.svg',
    disabled: false,
    disabledTooltip: '',
  };
  private static reconnectAction: AccountActionData = {
    action: 'reconnect',
    text: 'Reconnect',
    icon: 'assets/icons/accounts-icons/reconnect-icon.svg',
    disabled: false,
    disabledTooltip: '',
  };
  private static configureProxyAction: AccountActionData = {
    action: 'configureProxy',
    text: 'Configure proxy',
    icon: 'assets/icons/settings-icon.svg',
    disabled: false,
    disabledTooltip: '',
  };
  private static manageLimitsAction: AccountActionData = {
    action: 'manageLimits',
    text: 'Configure sending limits',
    icon: 'assets/icons/accounts-icons/manage-limits-icon.svg',
    disabled: false,
    disabledTooltip: '',
  };
  private static manageScheduleAction: AccountActionData = {
    action: 'manageSchedule',
    text: 'Configure working hours',
    icon: 'assets/icons/accounts-icons/manage-schedule-icon.svg',
    disabled: false,
    disabledTooltip: '',
  };
  private static inboxConfigurationAction: AccountActionData = {
    action: 'inboxScrape',
    text: 'Inbox privacy configuration',
    icon: 'assets/icons/archive-icon.svg',
    disabled: false,
    disabledTooltip: '',
  };
  // re-sync action is when the account has been scraped previously, but we want to update it
  private static reSyncAction: AccountActionData = {
    action: 'reSync',
    text: 'Re-sync',
    icon: 'assets/icons/accounts-icons/re-sync-icon.svg',
    disabled: false,
    disabledTooltip: '',
  };
  // sync action is when the account has never been scraped
  private static syncAction: AccountActionData = {
    action: 'sync',
    text: 'Sync',
    icon: 'assets/icons/accounts-icons/re-sync-icon.svg',
    disabled: false,
    disabledTooltip: '',
  };
  private static reLogNavigatorAction: AccountActionData = {
    action: 'reLogNavigator',
    text: 'Connect Sales Navigator',
    icon: 'assets/icons/accounts-icons/re-log-plan-icon.svg',
    disabled: false,
    disabledTooltip: '',
  };
  private static reLogRecruiterAction: AccountActionData = {
    action: 'reLogRecruiter',
    text: 'Connect Recruiter',
    icon: 'assets/icons/accounts-icons/re-log-plan-icon.svg',
    disabled: false,
    disabledTooltip: '',
  };

  private static stateReasonMappings: Record<LinkedInAccountStateReason, AccountActionData[]> = {
    [LinkedInAccountStateReason._0]: [],
    [LinkedInAccountStateReason._1]: [
      AccountActionsPipe.reconnectAction,
      AccountActionsPipe.configureProxyAction,
    ],
    [LinkedInAccountStateReason._2]: [
      AccountActionsPipe.reconnectAction,
      AccountActionsPipe.configureProxyAction,
    ],
    [LinkedInAccountStateReason._3]: [
      AccountActionsPipe.reconnectAction,
      AccountActionsPipe.configureProxyAction,
    ],
    [LinkedInAccountStateReason._4]: [
      AccountActionsPipe.reconnectAction,
      AccountActionsPipe.configureProxyAction,
    ],
    [LinkedInAccountStateReason._5]: [
      AccountActionsPipe.reconnectAction,
      AccountActionsPipe.configureProxyAction,
    ],
    [LinkedInAccountStateReason._6]: [],
    [LinkedInAccountStateReason._7]: [
      AccountActionsPipe.reconnectAction,
      AccountActionsPipe.configureProxyAction,
    ],
    [LinkedInAccountStateReason._8]: [],
    [LinkedInAccountStateReason._9]: [],
    [LinkedInAccountStateReason._10]: [
      AccountActionsPipe.reconnectAction,
      AccountActionsPipe.configureProxyAction,
    ],
    [LinkedInAccountStateReason._11]: [],
    [LinkedInAccountStateReason._12]: [
      AccountActionsPipe.reconnectAction,
      AccountActionsPipe.configureProxyAction,
    ],
    [LinkedInAccountStateReason._13]: [],
    [LinkedInAccountStateReason._14]: [
      AccountActionsPipe.reconnectAction,
      AccountActionsPipe.configureProxyAction,
    ],
    [LinkedInAccountStateReason._15]: [
      AccountActionsPipe.reconnectAction,
      AccountActionsPipe.configureProxyAction,
    ],
    [LinkedInAccountStateReason._16]: [],
    [LinkedInAccountStateReason._17]: [
      AccountActionsPipe.reconnectAction,
      AccountActionsPipe.configureProxyAction,
    ],
    [LinkedInAccountStateReason._18]: [],
    [LinkedInAccountStateReason._19]: [],
    [LinkedInAccountStateReason._20]: [
      AccountActionsPipe.reconnectAction,
      AccountActionsPipe.configureProxyAction,
    ],
    [LinkedInAccountStateReason._21]: [],
  };

  private static stateMappings({
    inActiveCampaigns,
    hasRecruiter,
    hasNavigator,
    stateReason,
    recruiterState,
    salesNavigatorState,
    accountCreatorUserId,
    currentUserId,
  }: {
    inActiveCampaigns: boolean;
    hasRecruiter: boolean;
    hasNavigator: boolean;
    stateReason: LinkedInAccountStateReason;
    recruiterState: RecruiterState;
    salesNavigatorState: SalesNavigatorState;
    accountCreatorUserId: number;
    currentUserId: number;
  }): Record<LinkedInAccountState, AccountActionData[]> {
    return {
      [LinkedInAccountState._0]: [AccountActionsPipe.disconnectAction],
      [LinkedInAccountState._1]: [
        inActiveCampaigns
          ? {
              ...AccountActionsPipe.disconnectAction,
              disabledTooltip:
                'This account is currently a part of an active campaign. Please cancel or update the campaign then unlink the account.',
              disabled: true,
            }
          : AccountActionsPipe.disconnectAction,
        AccountActionsPipe.reSyncAction,
        AccountActionsPipe.manageLimitsAction,
        AccountActionsPipe.manageScheduleAction,
        AccountActionsPipe.configureProxyAction,
        accountCreatorUserId != currentUserId
          ? {
              ...AccountActionsPipe.inboxConfigurationAction,
              disabled: true,
              disabledTooltip:
                'You cannot change the inbox privacy configuration settings for this account because you are not the owner. Only the person in your organization who has connected this account can change this setting.',
            }
          : AccountActionsPipe.inboxConfigurationAction,
        ...(hasRecruiter && recruiterState == RecruiterState._2
          ? [AccountActionsPipe.reLogRecruiterAction]
          : []),
        ...(hasNavigator && salesNavigatorState == SalesNavigatorState._2
          ? [AccountActionsPipe.reLogNavigatorAction]
          : []),
      ],
      [LinkedInAccountState._2]: [
        AccountActionsPipe.disconnectAction,
        ...this.stateReasonMappings[stateReason],
      ],
      [LinkedInAccountState._3]: [AccountActionsPipe.disconnectAction],
      [LinkedInAccountState._4]: [AccountActionsPipe.disconnectAction],
      [LinkedInAccountState._5]: [AccountActionsPipe.disconnectAction],
      [LinkedInAccountState._6]: [
        AccountActionsPipe.disconnectAction,
        AccountActionsPipe.manageLimitsAction,
        AccountActionsPipe.manageScheduleAction,
        AccountActionsPipe.configureProxyAction,
        AccountActionsPipe.inboxConfigurationAction,
      ],
      [LinkedInAccountState._7]: [
        AccountActionsPipe.disconnectAction,
        AccountActionsPipe.manageLimitsAction,
        AccountActionsPipe.manageScheduleAction,
        AccountActionsPipe.configureProxyAction,
        AccountActionsPipe.inboxConfigurationAction,
        AccountActionsPipe.syncAction,
      ],
    };
  }

  transform(linkedInAccount: LinkedInAccountDto, currentUserId: number): AccountActionData[] {
    const result: Set<AccountActionData> = new Set<AccountActionData>();
    this.addActions(
      result,
      AccountActionsPipe.stateMappings({
        stateReason: linkedInAccount.accountStateReason,
        hasRecruiter: linkedInAccount.hasRecruiter,
        inActiveCampaigns: linkedInAccount.activeCampaigns > 0,
        hasNavigator: linkedInAccount.hasNavigator,
        salesNavigatorState: linkedInAccount.salesNavigatorState,
        recruiterState: linkedInAccount.recruiterState,
        accountCreatorUserId: linkedInAccount.creatorUserId,
        currentUserId: currentUserId,
      })[linkedInAccount.accountState],
    );

    return [...result];
  }

  private addActions(result: Set<AccountActionData>, actions: AccountActionData[]): void {
    actions.forEach((x) => result.add(x));
  }
}
