<dialog-container>
  <dialog-header (closeClick)="onCancelRename()" title="Rename list"></dialog-header>
  <form class="form-horizontal" autocomplete="off" (ngSubmit)="onSubmit()">
    <div class="w-100 mb-4 truncate">
      <span>
        Enter a new name for
        <strong>{{ list.name }}</strong>
      </span>
    </div>

    <mat-form-field class="w-100 mb-4">
      <mat-label>New List Name</mat-label>
      <input type="text" matInput [(ngModel)]="newListName" name="newListName" required />
    </mat-form-field>

    <div class="flex justify-end" *ngIf="!isSaving">
      <button heyreachButton buttonType="primary" [disabled]="isSaving || newListName.length == 0">
        <span>Confirm</span>
      </button>
    </div>
    <div *ngIf="isSaving" class="flex justify-center">
      <progress-spinner [diameter]="10" color="primary"></progress-spinner>
    </div>
  </form>
</dialog-container>
