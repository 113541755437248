import { Pipe, PipeTransform } from '@angular/core';
import {
  LinkedInAccountSlotDto,
  LinkedInAccountState,
  LinkedInAccountStateReason,
  RecruiterState,
  SalesNavigatorState,
} from '@shared/service-proxies/service-proxies';
import { AccountStatusBadgeData } from '@app/linkedin-accounts/linkedin-accounts.component';

@Pipe({
  name: 'accountStatusBadge',
  pure: true,
})
export class AccountStatusBadgePipe implements PipeTransform {
  transform(slot: LinkedInAccountSlotDto): AccountStatusBadgeData {
    return !slot.isActive
      ? ({
          tooltipText:
            'The seat for this LinkedIn account is not active. Reactivate the seat to use the account.',
          text: 'Inactive seat',
          icon: 'assets/icons/accounts-icons/error-icon.svg',
          badgeType: 'challenged',
        } as AccountStatusBadgeData)
      : stateMappings({
          stateReason: slot.linkedInAccount.accountStateReason,
          hasOwnProxy: slot.linkedInAccount.hasOwnProxy,
          isValidRecruiter: slot.linkedInAccount.recruiterState == RecruiterState._1,
          isValidNavigator: slot.linkedInAccount.salesNavigatorState == SalesNavigatorState._1,
          hasRecruiter: slot.linkedInAccount.hasRecruiter,
          hasNavigator: slot.linkedInAccount.hasNavigator,
          activeCampaigns: slot.linkedInAccount.activeCampaigns,
        })[slot.linkedInAccount.accountState];
  }
}

function stateMappings({
  stateReason,
  hasOwnProxy,
  activeCampaigns,
  hasNavigator,
  hasRecruiter,
  isValidNavigator,
  isValidRecruiter,
}: {
  stateReason: LinkedInAccountStateReason;
  hasOwnProxy: boolean;
  activeCampaigns: number;
  hasNavigator: boolean;
  isValidNavigator: boolean;
  hasRecruiter: boolean;
  isValidRecruiter: boolean;
}): Record<LinkedInAccountState, AccountStatusBadgeData> {
  return {
    [LinkedInAccountState._0]: {
      text: 'Connecting',
      badgeType: 'idle',
      icon: 'assets/icons/accounts-icons/connecting-icon.svg',
      tooltipText:
        'The account is currently being connected to HeyReach. Please wait for a few minutes as this process takes some time.',
    },
    [LinkedInAccountState._1]: {
      ...(activeCampaigns > 0
        ? {
            badgeType: 'progress',
            text: `In ${activeCampaigns} campaign${activeCampaigns !== 1 ? 's' : ''}`,
            icon: 'assets/icons/accounts-icons/campaigns-icon.svg',
            tooltipText: '',
          }
        : {
            tooltipText: '',
            badgeType: 'success',
            text: 'Available',
            icon: 'assets/icons/accounts-icons/success-icon.svg',
          }),
      ...{
        tooltipText:
          hasNavigator && !isValidNavigator
            ? "Sales navigator is not synced properly. Click the 'Re-log Sales Navigator' button to continue."
            : hasRecruiter && !isValidRecruiter
              ? "Recruiter is not synced properly. Click the 'Re-log Recruiter' button to continue."
              : '',
      },
    },
    [LinkedInAccountState._2]: stateReasonMappings(hasOwnProxy)[stateReason],
    [LinkedInAccountState._3]: stateReasonMappings(hasOwnProxy)[stateReason],
    [LinkedInAccountState._4]: {
      tooltipText:
        'The account is currently being connected to HeyReach. Please wait for a few minutes as this process takes some time.',
      badgeType: 'idle',
      text: 'Connecting',
      icon: 'assets/icons/accounts-icons/connecting-icon.svg',
    },
    // remove this LinkedInAccountState.FAIL is not used
    [LinkedInAccountState._5]: null,
    [LinkedInAccountState._6]: {
      text: 'Syncing',
      icon: 'assets/icons/accounts-icons/syncing-icon.svg',
      badgeType: 'queued',
      tooltipText:
        'We are loading the LinkedIn profile information about this sender. This process takes a few minutes',
    },
    [LinkedInAccountState._7]: {
      text: 'Not Synced',
      icon: 'assets/icons/accounts-icons/not-synced-icon.svg',
      badgeType: 'warning',
      tooltipText: `The LinkedIn profile is not synced properly. Click the 'Re-sync' button to try loading the account information again.`,
    },
  };
}

function stateReasonMappings(
  hasOwnProxy: boolean,
): Record<LinkedInAccountStateReason, AccountStatusBadgeData> {
  return {
    [LinkedInAccountStateReason._0]: {
      text: 'Not connected',
      badgeType: 'danger',
      icon: 'assets/icons/accounts-icons/not-connected-icon.svg',
      tooltipText:
        'The LinkedIn account is not authenticated. Either the session expired or additional action is required.',
    },
    [LinkedInAccountStateReason._1]: {
      text: 'Not connected',
      badgeType: 'danger',
      icon: 'assets/icons/accounts-icons/not-connected-icon.svg',
      tooltipText:
        'There seems to be a problem authenticating this account at the moment, on LinkedIn’s side. Try again in some time or contact support.',
    },
    [LinkedInAccountStateReason._2]: {
      text: 'Not connected',
      badgeType: 'danger',
      icon: 'assets/icons/accounts-icons/not-connected-icon.svg',
      tooltipText:
        'The LinkedIn account is not authenticated. Either the session expired or additional action is required.',
    },
    [LinkedInAccountStateReason._3]: {
      tooltipText: `The LinkedIn account is not authenticated. Either the session expired or additional action is required.`,
      text: 'Not connected',
      badgeType: 'danger',
      icon: 'assets/icons/accounts-icons/not-connected-icon.svg',
    },
    [LinkedInAccountStateReason._4]: {
      text: 'Not connected',
      badgeType: 'danger',
      icon: 'assets/icons/accounts-icons/not-connected-icon.svg',
      tooltipText:
        'The LinkedIn account is not authenticated. Either the session expired or additional action is required.',
    },
    [LinkedInAccountStateReason._5]: {
      text: 'Not connected',
      badgeType: 'danger',
      icon: 'assets/icons/accounts-icons/not-connected-icon.svg',
      tooltipText:
        'The LinkedIn account is not authenticated. Either the session expired or additional action is required.',
    },
    [LinkedInAccountStateReason._6]: {
      tooltipText:
        'Verification PIN is required. Check your email for the PIN that LinkedIn sent you and enter it here so you can verify your account',
      text: 'PIN Required',
      icon: 'assets/icons/accounts-icons/error-icon.svg',
      badgeType: 'challenged',
    },
    [LinkedInAccountStateReason._7]: {
      tooltipText:
        'The PIN you entered is expired. Reconnect the LinkedIn account to receive a new PIN from LinkedIn.',
      icon: 'assets/icons/accounts-icons/error-icon.svg',
      badgeType: 'challenged',
      text: 'Expired PIN',
    },
    [LinkedInAccountStateReason._8]: {
      tooltipText:
        'The verification code you entered isn’t valid. Please check the code and try again.',
      text: 'Wrong PIN',
      icon: 'assets/icons/accounts-icons/error-icon.svg',
      badgeType: 'challenged',
    },
    [LinkedInAccountStateReason._9]: {
      tooltipText:
        'Verification PIN is required. Check your authenticator app or your SMS for the PIN and enter it here so you can verify your account.',
      text: 'PIN required',
      badgeType: 'challenged',
      icon: 'assets/icons/accounts-icons/error-icon.svg',
    },
    [LinkedInAccountStateReason._10]: {
      tooltipText: 'The PIN you entered is expired.',
      icon: 'assets/icons/accounts-icons/error-icon.svg',
      badgeType: 'challenged',
      text: 'Expired PIN',
    },
    [LinkedInAccountStateReason._11]: {
      tooltipText:
        'The verification code you entered isn’t valid. Please check the code and try again.',
      text: 'Wrong PIN',
      icon: 'assets/icons/accounts-icons/error-icon.svg',
      badgeType: 'challenged',
    },
    [LinkedInAccountStateReason._12]: {
      text: 'Wrong credentials',
      icon: 'assets/icons/accounts-icons/error-icon.svg',
      badgeType: 'challenged',
      tooltipText:
        'Your LinkedIn username or password is wrong. Make sure your credentials are correct and reconnect the account.',
    },
    [LinkedInAccountStateReason._13]: {
      tooltipText: `There seems to be an issue with this LinkedIn account. Go directly to LinkedIn as additional input may be required from your side.`,
      text: 'Not connected',
      badgeType: 'danger',
      icon: 'assets/icons/accounts-icons/not-connected-icon.svg',
    },
    [LinkedInAccountStateReason._14]: {
      text: 'Not connected',
      badgeType: 'danger',
      icon: 'assets/icons/accounts-icons/not-connected-icon.svg',
      tooltipText:
        'The LinkedIn account is not authenticated. Either the session expired or additional action is required.',
    },
    [LinkedInAccountStateReason._15]: {
      text: 'Not connected',
      badgeType: 'danger',
      icon: 'assets/icons/accounts-icons/not-connected-icon.svg',
      tooltipText:
        'The LinkedIn account is not authenticated. Either the session expired or additional action is required.',
    },
    [LinkedInAccountStateReason._16]: {
      tooltipText: `There seems to be an issue with this LinkedIn account. Navigate to <a href="https://www.linkedin.com">linkedin.com</a> as additional input may be required from your side.`,
      text: 'Not connected',
      badgeType: 'danger',
      icon: 'assets/icons/accounts-icons/not-connected-icon.svg',
    },
    [LinkedInAccountStateReason._17]: {
      text: 'Not connected',
      badgeType: 'danger',
      icon: 'assets/icons/accounts-icons/not-connected-icon.svg',
      tooltipText: hasOwnProxy
        ? `There's a problem with the proxy attached to this LinkedIn account. Change it and try connecting the LinkedIn account again.`
        : 'The LinkedIn account is not authenticated. Either the session expired or additional action is required.',
    },
    [LinkedInAccountStateReason._18]: {
      tooltipText: `There seems to be a problem authenticating this account at the moment on LinkedIn's side. Try again in some time or contact support.`,
      text: 'Not connected',
      badgeType: 'danger',
      icon: 'assets/icons/accounts-icons/not-connected-icon.svg',
    },
    [LinkedInAccountStateReason._19]: {
      text: 'Not connected',
      badgeType: 'danger',
      icon: 'assets/icons/accounts-icons/not-connected-icon.svg',
      tooltipText:
        'The LinkedIn account is not authenticated. Either the session expired or additional action is required.',
    },
    [LinkedInAccountStateReason._20]: {
      text: 'Not connected',
      badgeType: 'danger',
      icon: 'assets/icons/accounts-icons/not-connected-icon.svg',
      tooltipText:
        'The LinkedIn account is not authenticated. Either the session expired or additional action is required.',
    },
    [LinkedInAccountStateReason._21]: {
      text: 'Not connected',
      badgeType: 'danger',
      icon: 'assets/icons/accounts-icons/not-connected-icon.svg',
      tooltipText:
        'The LinkedIn account is not authenticated. Either the session expired or additional action is required.',
    },
  };
}
