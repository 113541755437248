import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { accountModuleAnimation } from '@shared/animations/routerTransition';
import { Subject } from 'rxjs';
import { debounceTime, delay, filter, map, takeUntil, tap } from 'rxjs/operators';
import { EndpointAutocompleteOption } from './shared-endpoint-autocomplete-interfaces';

@Component({
  selector: 'endpoint-autocomplete',
  templateUrl: './shared-endpoint-autocomplete.component.html',
  animations: [accountModuleAnimation()],
})
export class SharedEndpointAutocompleteComponent implements OnInit, OnDestroy {
  @Input() placeholderLabel: string = '';
  @Input() disabled: boolean = true;
  @Input() searchInputDebounceTime: number = 500;
  @Input() searchInputMinimumCharacters: number = 2;
  @Input() defaultIcon: string = '';
  searchModelChanged: Subject<string> = new Subject<string>();
  searchTerm: string = '';
  private _options: EndpointAutocompleteOption<any>[] = [];
  public get options(): EndpointAutocompleteOption<any>[] {
    return this._options;
  }
  @Input()
  public set options(value: EndpointAutocompleteOption<any>[]) {
    this._options = value;
    this.labelToDisplay = this._options.length === 0 ? 'NO_RESULTS' : 'NONE';
  }
  @Input() selectedOptions: EndpointAutocompleteOption<any>[] = [];
  @Input() defaultOptions: EndpointAutocompleteOption<any>[] = [];
  @Output() selectedOptionsChange = new EventEmitter<EndpointAutocompleteOption<any>[]>();
  @Output() searchTermChange = new EventEmitter<string>();

  public optionsServerSideFilteringControl: FormControl = new FormControl('');

  @Input() searching: boolean = false;
  labelToDisplay: AutocompleteLabelToDisplayType = 'NONE';

  protected _onDestroy = new Subject<void>();

  ngOnInit(): void {
    this.configureSearch();
  }

  configureSearch(): void {
    this.optionsServerSideFilteringControl.valueChanges
      .pipe(
        filter((search) => search.length < this.searchInputMinimumCharacters),
        tap((search) => {
          this.searching = false;
          this.options = search.length === 0 ? [] : this.options;
          this.labelToDisplay = search.length !== 0 ? 'MIN_CHARACTERS' : 'NONE';
        }),
        takeUntil(this._onDestroy),
      )
      .subscribe();

    this.optionsServerSideFilteringControl.valueChanges
      .pipe(
        map((search) => {
          this.searchTerm = search;
          return search;
        }),
        filter((search) => search.length >= this.searchInputMinimumCharacters),
        tap(() => {
          this.searching = true;
          this.labelToDisplay = 'SEARCHING';
        }),
        takeUntil(this._onDestroy),
        debounceTime(this.searchInputDebounceTime),
        map((search) => {
          this.searchTermChange.emit(search);
        }),
        delay(500),
        takeUntil(this._onDestroy),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  onSelectionsChange(): void {
    this.selectedOptionsChange.emit(this.selectedOptions);
  }

  onSelectionRemove(option: EndpointAutocompleteOption<any>) {
    this.selectedOptions = this.selectedOptions.filter((item) => {
      return item !== option;
    });
    this.selectedOptionsChange.emit(this.selectedOptions);
  }

  get optionsToShow(): EndpointAutocompleteOption<any>[] {
    if (this.optionsServerSideFilteringControl.value?.length === 0) {
      let opts = this.defaultOptions;
      this.selectedOptions.forEach((x) => {
        let index = opts.findIndex((y) => x.id == y.id);
        if (index !== -1) {
          opts.splice(index, 1, x);
        }
      });
      return opts.filter((x) => !this.selectedOptions.map((y) => y.id).includes(x.id));
    }
    let opts = this.options;
    this.selectedOptions.forEach((x) => {
      let index = opts.findIndex((y) => x.id == y.id);
      if (index !== -1) {
        opts.splice(index, 1);
      }
    });
    return opts;
  }
}

type AutocompleteLabelToDisplayType = 'NONE' | 'MIN_CHARACTERS' | 'SEARCHING' | 'NO_RESULTS';
