import { Component, Input } from '@angular/core';
@Component({
  selector: 'table-loading-animation',
  templateUrl: './table-loading-animation.component.html',
})
export class TableLoadingAnimationComponent {
  @Input() numberOfElements: number = 48;
  animationDivCount: number[] = Array(this.numberOfElements).fill(0);

  constructor() {}

  trackByFn(index: number): any {
    return index;
  }
}
