import { Component } from '@angular/core';
import { IntegrationsService } from '../crm-integrations/integrations-service/integrations.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'crm-button',
  templateUrl: './crm-button.component.html',
})
export class CrmButtonComponent {
  constructor(private _frontIntegrationsService: IntegrationsService) {}

  readonly crmsAvailable$ = this._frontIntegrationsService.crmIntegrations$.pipe(
    map((res) => res.length != 0),
  );
}
