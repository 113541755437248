import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { accountModuleAnimation } from '@shared/animations/routerTransition';
import { AddLeadsFromMemberIdsOrInboxComponent } from '@shared/components/dialogs/add-leads-to-inbox/add-leads-from-member-ids-or-inbox-dialog.component';
import {
  CrmExportType,
  ExportToCrmComponent,
} from '@shared/components/dialogs/export-to-crm/export-to-crm.component';
import { InboxService } from '@shared/inbox-service/inbox.service';
import {
  CampaignServiceProxy,
  LinkedInProfileTagDto,
  LinkedInUserProfileDto,
  LnkLinkedInProfileTagDto,
} from '@shared/service-proxies/service-proxies';
import { NotifyService } from 'abp-ng2-module';

@Component({
  selector: 'linkedin-profile-display',
  templateUrl: './linkedin-profile-display.component.html',
  animations: [accountModuleAnimation()],
})
export class LinkedinProfileDisplayComponent {
  @Input() profile: LinkedInUserProfileDto;
  @Input() accountId: number;
  @Output() onAssignTagChange: EventEmitter<LnkLinkedInProfileTagDto[]> = new EventEmitter<
    LnkLinkedInProfileTagDto[]
  >();
  @Output() onUnassignTagChange = new EventEmitter<number>();
  @Output() onUpdateTagChange: EventEmitter<LinkedInProfileTagDto> =
    new EventEmitter<LinkedInProfileTagDto>();
  @Output() addToCampaign = new EventEmitter<void>();

  constructor(
    private _inboxService: InboxService,
    private _dialog: MatDialog,
  ) {}

  onAssignTag(tagLnks: LnkLinkedInProfileTagDto[]) {
    // TODO: This is not needed code for unibox we can remove it , check if it's being used from other components
    // THe idea is that we are changing the tags in state service that provides the profile
    // so there is no need to change it locally in the profile here.
    const profileIds = tagLnks.map((x) => x.profileId);
    if (profileIds.includes(this.profile.id)) {
      const tags = tagLnks.filter((z) => z.profileId == this.profile.id);
      // push with loop for reference instead of concat
      for (const tagLnk of tags) {
        if (!this.profile.tagLinks.includes(tagLnk)) {
          this.profile.tagLinks.push(tagLnk);
        }
        this.onAssignTagChange.emit(this.profile.tagLinks);
      }
    }
    // TODO: END OF NOT NEEDED CODE

    const selectedChat = this._inboxService.selectedChatValue();
    if (selectedChat?.correspondentProfile) {
      this._inboxService.assignTag(tagLnks);
    }
  }

  // TODO : when a tag is deleted actually remove it from all tag fields
  // possible solution to this is InboxTagComponent to bind on onInboxChange and
  // check if InboxTagService.tags contains all tags from all profiles, if not then
  // delete the tags from all profiles; also do the same when a tag is edited
  onUnassignTag(tagId: number) {
    const selectedChat = this._inboxService.selectedChatValue();
    if (selectedChat?.correspondentProfile) {
      this._inboxService.unAssignTag(tagId);
    }
    this.onUnassignTagChange.emit(tagId);
    this.profile.tagLinks = this.profile.tagLinks.filter((tag) => tag.profileTagId !== tagId);
  }

  updateTag(updatedTag: LinkedInProfileTagDto): void {
    this.onUpdateTagChange.emit(updatedTag);
  }

  onExportToCrmClick(): void {
    const exportType: CrmExportType = 'CONTACTS';
    this._dialog.open(ExportToCrmComponent, {
      data: {
        linkedInProfileMemberIds: [this.profile.id],
        exportType: exportType,
      },
    });
  }

  openAddToCampaignDialog(): void {
    if (this.accountId == null) {
      return;
    }

    this.addToCampaign.emit();
    // const dialogRef = this._dialog.open<AddLeadsToCampaignComponent, LeadsCount>(
    //   AddLeadsToCampaignComponent,
    //   {
    //     data: {
    //       leadsCount: 1,
    //     },
    //   },
    // );
    //
    // dialogRef.afterClosed().subscribe((result) => {
    //   this.campaignServiceProxy
    //     .addLeadsToCampaign(
    //       new AddLeadsToCampaignInputDto({
    //         campaignId: result.campaignId,
    //         leads: [
    //           new MemberAndAccountIdDto({
    //             memberId: this.profile?.id,
    //             linkedInAccountId: this.accountId,
    //           }),
    //         ],
    //       }),
    //     )
    //     .subscribe((res) => {
    //       this.notify.success(`Successfully added ${res} leads to a campaign!`);
    //     });
    // });
  }

  openAddToListDialog(): void {
    this._dialog.open(AddLeadsFromMemberIdsOrInboxComponent, {
      autoFocus: false,
      disableClose: true,
      data: {
        mode: 'FROM_MEMBER_IDS',
        memberIds: [this.profile?.id],
      },
      width: '700px',
    });
  }
}
