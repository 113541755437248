<div class="inline-flex">
  <!-- <mat-form-field class="mr-2">
    <mat-label>{{fromLabel}}</mat-label>
    <input matInput type="time" [(ngModel)]="fromTime" (ngModelChange)="onFromTimeChange()" [max]="toTime">
  </mat-form-field>

  <mat-form-field>
    <mat-label>{{toLabel}}</mat-label>
    <input matInput type="time" [(ngModel)]="toTime" (ngModelChange)="onToTimeChange()" [min]="fromTime">
  </mat-form-field> -->

  <div class="w-30 mr-2">
    <div class="text-gray-700 font-medium">{{ fromLabel }}</div>
    <timepicker
      [max]="toTime"
      [(value)]="fromTime"
      (valueChange)="onFromTimeChange()"
      [disabled]="disabled"></timepicker>
  </div>
  <div class="w-30">
    <div class="text-gray-700 font-medium">{{ toLabel }}</div>
    <timepicker
      [min]="fromTime"
      [(value)]="toTime"
      (valueChange)="onToTimeChange()"
      [disabled]="disabled"></timepicker>
  </div>
</div>
