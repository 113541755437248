<div class="relative h-1.5">
  <div
    *ngIf="determinate"
    [ngClass]="disabled ? 'bg-gray' : 'bg-primary-muted'"
    class="flex h-full w-full">
    <div
      [style.width.%]="progressWidth"
      [ngClass]="disabled ? 'bg-gray' : 'bg-primary'"
      class="flex items-center justify-end font-semibold text-xs leading-none"></div>
  </div>
  <div
    *ngIf="!determinate"
    [ngClass]="disabled ? 'bg-gray-200' : 'bg-primary-muted'"
    class="w-full flex h-full overflow-hidden">
    <div
      [ngClass]="disabled ? 'bg-gray' : 'bg-primary'"
      class="progress w-full h-full left-right"></div>
  </div>
</div>
