import {
  Component,
  Input,
  HostListener,
  ElementRef,
  forwardRef,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject, filter } from 'rxjs';

export interface SelectOptionItem {
  id: string;
  label: string;
  checked?: boolean;
  value: any;
  icon?: string;
}

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    },
  ],
})
export class SelectComponent implements ControlValueAccessor {
  @Output() selectedItem = new EventEmitter<SelectOptionItem[]>();
  @Input() disabled: boolean = false;
  @Input() showSearchField: boolean = true;
  @Input() customClass: string = '';
  @Input() iconName: string = '';
  @Input() resetValue: BehaviorSubject<boolean>;
  private onChange: (value: any) => void = () => {};
  private onTouched: () => void = () => {};

  writeValue(value: any): void {
    if (value !== null && value !== undefined) {
      this.selectedItems = this.checkboxItems.filter((item) => item.value === value);
      this.updateSelectedItemsValue();
    } else {
      this.selectedItems = [];
      this.selectedItemsValue = '';
    }
    this.onChange(value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {}

  @Input() placeholder: string = '';
  @Input() dropdownId: string = 'dropdownBgHover';
  @Input() checkboxItems: SelectOptionItem[] = [];
  @Input() singleSelect: boolean = false;
  @Input() defaultValue: SelectOptionItem[] = [];
  @Input() selectedItems: SelectOptionItem[] = [];
  @Input() selectedItemsValue: string;
  @HostListener('document:click', ['$event'])
  clickout(event: MouseEvent) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.isDropdownOpen = false;
      this.onTouched();
    }
  }

  isDropdownOpen: boolean = false;
  searchTerm: string = '';

  constructor(private elementRef: ElementRef) {}

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
    this.onTouched();
  }

  ngOnInit() {
    if (this.resetValue) {
      this.resetValue.pipe(filter((value) => true)).subscribe((value) => {
        this.checkboxItems.forEach((item) => {
          item.checked = false;
        });
        this.selectedItems = [];
        this.selectedItemsValue = '';
        this.onChange(null);
      });
    }
  }

  ngOnChanges() {
    if (this.defaultValue.length > 0) {
      this.selectedItems = this.defaultValue;
      this.updateSelectedItemsValue();
    }
  }

  trackById(index: number, item: { id: string }): string {
    return item.id;
  }

  selectItem(item: SelectOptionItem) {
    if (this.singleSelect) {
      if (this.selectedItems.length > 0 && this.selectedItems[0].id === item.id) {
        this.isDropdownOpen = false;
      }

      this.checkboxItems.forEach((checkboxItem) => (checkboxItem.checked = false));

      item.checked = true;
      this.selectedItems = [item];
      this.selectedItemsValue = item.label;
      this.onChange(item.value); // or set the selected value
      this.isDropdownOpen = false;
    } else {
      item.checked = !item.checked;
      if (item.checked) {
        this.selectedItems.push(item);
      } else {
        this.selectedItems = this.selectedItems.filter((x) => x.id !== item.id);
      }
      this.updateSelectedItemsValue();
      this.onChange(this.selectedItems);
    }
    this.selectedItem.emit(this.selectedItems);
  }

  private updateSelectedItemsValue() {
    this.selectedItemsValue = this.selectedItems.map((x) => x.label).join(', ');
  }

  get filteredCheckboxItems(): SelectOptionItem[] {
    if (!this.searchTerm.trim()) {
      return this.checkboxItems;
    }
    const searchTerm = this.searchTerm.trim().toLowerCase();
    return this.checkboxItems.filter((item) => item.label.toLowerCase().includes(searchTerm));
  }

  reset() {
    this.checkboxItems.forEach((item) => {
      item.checked = false;
    });
    this.selectedItems = [];
    this.selectedItemsValue = '';
    this.onChange(null);
    this.selectedItem.emit(this.selectedItems);
  }

  handleSearchFilter(searchTerm: string | null) {
    this.searchTerm = searchTerm || '';
  }
}
