import { Component, Input, OnDestroy } from '@angular/core';

@Component({
  selector: 'auto-open-menu',
  templateUrl: 'auto-open-menu.component.html',
})
export class AutoOpenMenuComponent implements OnDestroy {
  @Input() menuClasses: string;
  @Input() timeToCloseMenu: number = 200;

  private timedOutCloser;

  constructor() {}

  ngOnDestroy(): void {
    if (this.timedOutCloser) {
      clearTimeout(this.timedOutCloser);
    }
  }

  mouseEnter(trigger) {
    if (this.timedOutCloser) {
      clearTimeout(this.timedOutCloser);
    }
    trigger.openMenu();
  }

  mouseLeave(trigger) {
    if (this.timedOutCloser) {
      clearTimeout(this.timedOutCloser);
    }
    this.timedOutCloser = setTimeout(() => {
      trigger.closeMenu();
    }, this.timeToCloseMenu); // Adjust the delay time as needed
  }
}
