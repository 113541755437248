import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { SortBy, SortOrderType } from '../table-models/sort-types.model';

@Component({
  selector: 'app-column-name',
  templateUrl: './column-name.component.html',
  styleUrls: ['./column-name.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColumnNameComponent {
  @Input() public key = '';
  @Input() public heading = '';
  @Input() public index!: number;
  @Input() public active = false;
  @Input() public type = 'string';
  @Output() public readonly sortByEvent: EventEmitter<SortBy> = new EventEmitter<SortBy>();

  public toggle = false;
  public clicked = 1;
  public sortOrder!: SortOrderType;
  public ascending = SortOrderType.ASC;

  @HostBinding('attr.title')
  public get titleAttribute(): string | null {
    return this.heading ? this.heading : null;
  }

  @HostListener('keyup.enter')
  @HostListener('keyup.space')
  @HostListener('click')
  public handleClick(): void {
    this.clicked++;
    this.sortOrder = this.clicked % 2 ? SortOrderType.DESC : SortOrderType.ASC;
    this.toggle = !this.toggle;
    this.sortByEvent.emit({
      key: this.key,
      activeIndex: this.index,
      sortOrder: this.sortOrder,
      type: this.type,
    });
  }
}
