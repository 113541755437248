<div class="modal-footer justify-content-between border-none">
  <button
    heyreachButton
    buttonType="default"
    type="button"
    [disabled]="cancelDisabled"
    (click)="onCancelClick.emit()">
    {{ cancelLabel }}
  </button>
  <button type="submit" heyreachButton buttonType="primary" [disabled]="saveDisabled">
    {{ saveLabel }}
  </button>
</div>
