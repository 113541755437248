import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { accountModuleAnimation } from '@shared/animations/routerTransition';
import { LinkedInAccountDto } from '@shared/service-proxies/service-proxies';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EventEmitter } from '@angular/core';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import * as _ from 'lodash';

@Component({
  selector: 'linkedin-select-account',
  templateUrl: './linkedin-select-account.component.html',
  animations: [accountModuleAnimation()],
  encapsulation: ViewEncapsulation.None,
})
export class LinkedinSelectAccountComponent implements OnInit, OnDestroy, OnChanges {
  @Input() hasTitle: boolean = false;
  @Input() selectedAccounts: LinkedInAccountDto[];
  @Output() selectedAccountChange = new EventEmitter<LinkedInAccountDto[]>();

  @Input() accounts: LinkedInAccountDto[] = [];
  @Input() allowNull = false;
  @Input() nullOptionText = 'None';

  @Input() placeholderLabel = '';
  @Input() defaultAccountImage: boolean = false;
  @Input() multiple = false;
  @Input() disabled = false;
  @Input() showSenderIcon: boolean = false;
  @Input() resetValue?: BehaviorSubject<boolean>;
  searchTerm$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  filteredAccounts: BehaviorSubject<LinkedInAccountDto[]> = new BehaviorSubject<
    LinkedInAccountDto[]
  >([]);

  @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;

  protected destroy$ = new Subject<void>();

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.accounts &&
      !_.isEqual(changes.accounts.currentValue, changes.accounts.previousValue)
    ) {
      this.setInitialValue();
    }

    if (changes.selectedAccounts && changes.selectedAccounts.currentValue) {
      this.selectedAccounts = changes.selectedAccounts.currentValue;
      this.filteredAccounts.next(this.accounts);
    }
  }

  ngOnInit(): void {
    this.searchTerm$.pipe(takeUntil(this.destroy$)).subscribe((searchTerm) => {
      this.filterAccounts(searchTerm);
    });

    if (this.selectedAccounts && this.selectedAccounts.length > 0) {
      this.singleSelect.value = this.selectedAccounts[0];
    }

    this.resetValue?.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      this.selectedAccounts = [];
      this.searchTerm$.next('');
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  setInitialValue() {
    this.filteredAccounts.next(this.accounts);
  }

  filterAccounts(search: string): void {
    if (!this.accounts) {
      return;
    }
    if (!search) {
      this.filteredAccounts.next(this.accounts);
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredAccounts.next(
      this.accounts.filter(
        (acc) =>
          acc.emailAddress?.toLowerCase().indexOf(search) > -1 ||
          acc.linkedInUserProfile?.firstName?.toLowerCase().indexOf(search) > -1 ||
          acc.linkedInUserProfile?.lastName?.toLowerCase().indexOf(search) > -1 ||
          acc.username?.toLowerCase().indexOf(search) > -1,
      ),
    );
  }

  onSelectedAccountChange(event: MatSelectChange): void {
    if (!this.multiple) {
      this.selectedAccountChange.emit([event.value]);
      this.selectedAccounts = [event.value];
    } else {
      this.selectedAccounts = event.value;
      this.selectedAccountChange.emit(this.selectedAccounts);
    }
  }
}
