import { EventEmitter, Injectable } from '@angular/core';

/**
 * Handles hot reload
 */
@Injectable()
export class HotReloadService {
  private canReload: boolean = true;

  public onReloadQueue = new EventEmitter<void>();
  private interval: NodeJS.Timeout;

  constructor() {}

  public enableReload() {
    this.canReload = true;
  }

  public disableReload() {
    this.canReload = false;
  }

  public clearReloadQueue() {
    clearInterval(this.interval);
  }

  public queueReloadPage(page: string = '') {
    if (page) {
      if (page == 'all' || window.location.href.indexOf(page) != -1) this.queueReload();
    } else {
      this.queueReload();
    }
  }

  private queueReload() {
    var $this = this;
    this.interval = setInterval(() => {
      $this.tryPerformReload();
    }, 1000);
    this.onReloadQueue.emit();
  }

  private tryPerformReload() {
    // console.log('Attempting to reload!');
    if (this.canReload) {
      window.location.reload();
    }
  }
}
