<div class="flex items-center justify-center">
  <div class="inline-flex items-center justify-center" *ngIf="showPremium">
    <mat-icon
      matTooltip="LinkedIn Premium"
      class="text-linkedin-premium icon-size-4"
      svgIcon="custom:linkedin-premium"></mat-icon>
  </div>
  <div class="inline-flex items-center justify-center" *ngIf="showNavigator">
    <mat-icon
      matTooltip="LinkedIn SalesNavigator"
      class="text-linkedin-sales-navigator icon-size-4"
      svgIcon="custom:linkedin-sales-navigator"></mat-icon>
  </div>
  <div class="inline-flex items-center justify-center" *ngIf="showRecruiter">
    <mat-icon
      matTooltip="LinkedIn Recruiter"
      class="text-linkedin-recruiter inline icon-size-4"
      svgIcon="custom:linkedin-recruiter"></mat-icon>
  </div>
</div>
