<div class="w-full m-0 px-4 py-3 flex justify-between border-t border-gray-100 text-secondary">
  <div class="justify-start flex-grow py-1 pb-0">
    <span>
      Showing
      <span class="font-semibold">
        {{ pageNumber * resultsPerPage + 1 }}-{{
          resultsPerPage * (pageNumber + 1) | minNumber: total
        }}
      </span>
      of
      <span class="font-semibold">{{ total }}</span>
    </span>
  </div>
  <div class="flex shadow-sm h-7 w-40 mt-auto" role="group">
    <button
      (click)="prevButtonClick()"
      [disabled]="isPrevButtonDisabled"
      class="w-1/2 s-mat-button-small px-4 text-sm font-medium bg-white border border-gray-200 rounded-l-lg text-secondary"
      [ngClass]="
        isPrevButtonDisabled
          ? 'cursor-default bg-secondary-muted'
          : 'text-dark hover:bg-gray-100 hover:text-primary focus:ring-primary focus:text-primary'
      ">
      Previous
    </button>
    <button
      (click)="nextButtonClick()"
      [disabled]="isNextButtonDisabled"
      class="w-1/2 s-mat-button-small px-4 text-sm font-medium bg-white border border-b border-gray-200 rounded-r-lg text-secondary"
      [ngClass]="
        isNextButtonDisabled
          ? 'cursor-default bg-secondary-muted'
          : 'text-dark hover:bg-gray-100 hover:text-primary focus:ring-primary focus:text-primary'
      ">
      Next
    </button>
  </div>
</div>
