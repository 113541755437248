import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'maxNumber',
})
export class MaxPipe implements PipeTransform {
  transform(a: number, b: number): number {
    return Math.max(a, b);
  }
}
