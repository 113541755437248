import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
@Component({
  selector: 'step-info-component',
  templateUrl: './step-info.component.html',
  styleUrls: ['./step-info.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class StepInfoComponent implements OnInit {
  @Input() stepsData: any;
  constructor() {}
  ngOnInit(): void {}
}
