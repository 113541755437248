import { Pipe, PipeTransform } from '@angular/core';
import { InboxFiltersDto } from '@shared/service-proxies/service-proxies';

@Pipe({
  name: 'hasFilters',
})
export class HasFiltersPipe implements PipeTransform {
  transform(filters: InboxFiltersDto, countAll?: boolean): number {
    let numberOfFilters = 0;
    if (filters.campaignIds.length > 0) {
      numberOfFilters += 1;
    }
    if (countAll && filters.linkedInAccountIds.length > 0) {
      numberOfFilters += 1;
    }
    if (filters.tagIds.length > 0) {
      numberOfFilters += 1;
    }
    if (countAll && !!filters.searchString) {
      numberOfFilters += 1;
    }
    if (filters.conversationType !== undefined) {
      numberOfFilters += 1;
    }
    if (filters.notReplied !== undefined) {
      numberOfFilters += 1;
    }
    if (countAll && filters.seen !== undefined) {
      numberOfFilters += 1;
    }
    if (filters.sortBy !== 0 && filters.sortBy !== undefined) {
      numberOfFilters += 1;
    }
    return numberOfFilters;
  }
}
