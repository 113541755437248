import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'digitsOnly',
})
export class DigitsOnlyPipe implements PipeTransform {
  private digitRegex = /^\d+$/;

  transform(value: string): boolean {
    if (!value) {
      return false;
    }
    return this.digitRegex.test(value);
  }
}
