<div class="flex items-center text-sm space-x-1 cursor-pointer" (click)="handleClick()">
  <p class="text-sm">{{ heading }}</p>
  <mat-icon
    *ngIf="active && sortOrder === ascending"
    svgIcon="custom:arrow-down"
    class="icon-size-4"></mat-icon>
  <mat-icon
    *ngIf="active && sortOrder !== ascending"
    svgIcon="custom:arrow-up"
    class="icon-size-4"></mat-icon>
</div>

<!-- we should have an icon here that is going to show the sort ascending or descending
example :   [icon]="sortOrder !== ascending ? 'arrow-up' : 'arrow-down'"
with a condition that if the sort order is not ascending then show the arrow up icon else show the arrow down icon
and if the condition is even active   *ngIf="active" -->
