import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { InterceptorDisabler } from '@shared/interceptors/disable-interceptor';
import {
  InvitationServiceProxy,
  InviteOverLinkedInInputDto,
  LinkedInAccountDto,
  LinkedInUserProfileDto,
  UserInvitationDto,
  UserInvitationInputDto,
} from '@shared/service-proxies/service-proxies';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppUserInvitationResultType } from '@shared/AppEnums';
import { PluralOnNumber } from '@shared/AppFunctions';
import { EmailValidator } from '@shared/consts/validators/email-validator-regex';
import { WhiteLabelService } from '@app/white-label.service';

@Component({
  selector: 'team-invite',
  templateUrl: './team-invite.component.html',
  styleUrls: ['team-invite.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TeamInviteComponent implements OnInit {
  connectedAccount: LinkedInAccountDto;
  @Input() shouldShowTeamInvite: boolean = true;
  emailValidator = new EmailValidator();

  inviteEmails: any[] = [];

  selectedProfiles: LinkedInUserProfileDto[] = [];
  companyName = this._whiteLabelService.companyName;
  invitationsInProgress: boolean = false;
  @Output() onTeamClose = new EventEmitter<any>();
  @Output() onSkipAndInviteButtonClicked: EventEmitter<void> = new EventEmitter<void>();
  public validators = [this.isValidEmail.bind(this)];
  public errorMessages = {
    emailValidator: 'Please enter a valid email address',
  };

  constructor(
    private _inviteService: InvitationServiceProxy,
    private _interceptorDisabler: InterceptorDisabler,
    private _toaster: MatSnackBar,
    private _whiteLabelService: WhiteLabelService,
  ) {}

  get isInviteButtonDisabled(): boolean {
    return (
      (this.inviteEmails?.length == 0 && this.selectedProfiles?.length == 0) ||
      this.invitationsInProgress
    );
  }

  isValidEmail(control: FormControl) {
    if (control.value && !this.emailValidator.userEmailPattern.test(control.value)) {
      return { emailValidator: true };
    }
    return null;
  }

  ngOnInit(): void {
    if (this.shouldShowTeamInvite) {
      this.handletoggleBodyScroll(true);
    }
  }

  toggleBodyScroll(shouldScroll: boolean): void {
    document.body.classList.toggle('overflow-hidden', !shouldScroll);
  }

  handletoggleBodyScroll(isVisible: boolean): void {
    this.toggleBodyScroll(!isVisible);
  }

  async goToNextStep() {
    await this.sendInvites();
  }

  async sendInvites(): Promise<void> {
    this.invitationsInProgress = true;
    const invitesOverLinkedInDto: InviteOverLinkedInInputDto = new InviteOverLinkedInInputDto();
    invitesOverLinkedInDto.linkedInMemberIds = this.selectedProfiles.map((x) => x.id);
    invitesOverLinkedInDto.accountId = this.connectedAccount?.id;

    const invitesOverEmail: UserInvitationInputDto[] = this.inviteEmails.map(
      (x) => ({ emailAddress: x.value }) as UserInvitationDto,
    );
    try {
      if (this.inviteEmails.length > 0) {
        let message: string = '';
        this._interceptorDisabler.disable();
        const response = await this._inviteService.inviteMultiple(invitesOverEmail).toPromise();
        const successInvites = response.filter((x) => x.resultType === 0);
        const failedInvites = response.filter((x) => x.resultType !== 0);

        if (successInvites.length > 0) {
          message = `${successInvites.length} invitation${PluralOnNumber(successInvites.length)} ${successInvites.length === 1 ? 'has' : 'have'} been successfuly sent!`;
        }

        if (failedInvites.length > 0) {
          const invitesInOtherOrganization = failedInvites
            .filter(
              (x) => x.resultType == AppUserInvitationResultType.USER_ALREADY_PART_OF_ORGANIZATION,
            )
            .map((x) => x.invitation.emailAddress);

          const invitesInOtherOrganizationString = invitesInOtherOrganization.join(', ');
          message = `${failedInvites.length} e-mail invitation${PluralOnNumber(failedInvites.length)} failed to send.`;
          if (invitesInOtherOrganization.length > 0) {
            message += ` User${PluralOnNumber(invitesInOtherOrganization.length)} already in another organization: ${invitesInOtherOrganizationString}.`;
          }
        }

        this._toaster.open(message, undefined, {
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
          duration: 9000,
        });
      }
    } catch (error: any) {}

    try {
      if (invitesOverLinkedInDto.linkedInMemberIds.length > 0) {
        this._interceptorDisabler.disable();
        await this._inviteService.inviteOverLinkedIn(invitesOverLinkedInDto).toPromise();
      }
    } catch (error: any) {
      this._toaster.open(
        'There was a problem while inviting people over LinkedIn. Try re-sending the invitations and if the issue persists contact support.',
        undefined,
        {
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
          duration: 4500,
        },
      );
    }

    this.skipStep();
  }

  async skipStep() {
    this.onSkipAndInviteButtonClicked.emit();
    document.body.classList.remove('overflow-hidden');
    this.invitationsInProgress = false;
    this.onTeamClose.emit();
  }

  onTextAreaClick() {
    document.getElementsByTagName('tag-input')[0].getElementsByTagName('input')[0].focus();
  }
}
