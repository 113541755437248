<div
  class="dropdown-select"
  [attr.title]="
    selectedItems.length === 0
      ? placeholder
        ? placeholder
        : 'Select'
      : multiSelectEnabled
        ? null
        : toStringFunction(selectedItems[0].displayValue)
  ">
  <button
    [disabled]="disabled || readonly"
    type="button"
    class="btn dropdown-select__toggle"
    [class.dropdown-select__toggle--invalid]="invalid"
    [attr.aria-controls]="formFieldId + '__list'"
    (click)="toggleDropdown()"
    [attr.id]="formFieldId"
    [attr.title]="
      selectedItems.length === 0
        ? placeholder
          ? placeholder
          : 'Select'
        : multiSelectEnabled
          ? null
          : toStringFunction(selectedItems[0].displayValue)
    "
    data-cy="dropdown-select__toggle">
    <div
      class="dropdown-select__toggle-content"
      [class.dropdown-select__toggle-content--disabled]="disabled || readonly">
      <ng-container *ngIf="selectedItems.length > 0; else placeholderTpl">
        <div
          class="dropdown-select__selected-item"
          *ngFor="let selectedItem of selectedItems.slice(0, selectedItemLimit)"
          [class.dropdown-select__selected-item--multiple]="multiSelectEnabled"
          [attr.title]="
            itemTemplate ? toStringFunction(selectedItem?.displayValue) : selectedItem?.displayValue
          ">
          <div class="dropdown-select__selected-item-wrapper">
            <ng-container *ngIf="itemTemplate; else noItemTemplate">
              <ng-container
                *ngIf="multiSelectEnabled"
                [ngTemplateOutlet]="removeItemTmpl"></ng-container>
              <ng-container
                [ngTemplateOutlet]="itemTemplate"
                [ngTemplateOutletContext]="{ $implicit: selectedItem }"></ng-container>
            </ng-container>
            <ng-template #noItemTemplate>
              <ng-container
                *ngIf="multiSelectEnabled"
                [ngTemplateOutlet]="removeItemTmpl"></ng-container>
              <ng-container *ngIf="selectedItem.displayValue; else emptyValue">
                {{ toStringFunction(selectedItem.displayValue) }}
              </ng-container>
            </ng-template>
            <ng-template #removeItemTmpl>
              <button
                *ngIf="!disabled"
                class="clean-btn dropdown-select__remove-item"
                title="Remove selected option"
                (click)="handleItemClick(selectedItem, false, $event)">
                <svg-icon [iconPath]="'assets/icons/button-icons/close.svg'"></svg-icon>
              </button>
            </ng-template>
          </div>
        </div>
        <div
          class="dropdown-select__select-item"
          *ngIf="selectedItems.length > selectedItemLimit"
          tooltipClass="tooltip--wide tooltip--limit-height"
          container="body">
          + {{ selectedItems.length - selectedItemLimit }} more
        </div>
        <ng-template #moreSelectedOptionsTooltipTemplate>
          <div class="dropdown-select__more-selected">
            <h4 class="dropdown-select__more-selected-title">Other selected items:</h4>
            <ul class="dropdown-select__more-selected-options">
              <li *ngFor="let selectedItem of selectedItems | moreSelectedOptions">
                {{ selectedItem }}
              </li>
            </ul>
          </div>
        </ng-template>
      </ng-container>

      <ng-template #placeholderTpl>
        <span class="dropdown-select__item--no-selection">{{ placeholder ?? 'Select' }}</span>
      </ng-template>
    </div>
  </button>
  <!-- class=""  -->
  <div
    role="listbox"
    [attr.id]="formFieldId + '__list'"
    ngbDropdownMenu
    *ngIf="isDropdownOpen"
    [attr.aria-labelledby]="formFieldId"
    [style.maxHeight.px]="calculatedMaxHeight ? calculatedMaxHeight : null"
    class="dropdown-select__menu"
    data-cy="dropdown-select__menu">
    <button [disabled]="true" *ngIf="itemsLimited" data-cy="dropdown-select__items-limited">
      Visible options limited to {{ itemLimit }} / {{ items.length.toLocaleString() }}
    </button>
    <button
      *ngIf="multiSelectEnabled && selectAllEnabled"
      type="button"
      class="dropdown-item dropdown-select__item dropdown-select__item--select-all"
      title="Select all"
      (click)="handleSelectAll()"
      data-cy="dropdown-select__select-all">
      <input
        type="checkbox"
        class="form-check-input dropdown-select__checkbox"
        [attr.checked]="allAreSelected ? true : null" />
      {{ allAreSelected ? 'Unselect all' : 'Select all' }}
    </button>
    <input
      type="text"
      (keyup.enter)="handleSearchKeyupEnter()"
      #search
      class="form-control dropdown-select__input"
      aria-autocomplete="list"
      placeholder="Search"
      data-cy="dropdown-select__search"
      [attr.aria-expanded]="dropdownOpen ? 'true' : 'false'"
      [attr.aria-controls]="formFieldId + '__list'"
      [formControl]="searchFormControl"
      *ngIf="searchEnabled" />
    <button
      type="button"
      *ngIf="noSelectionAllowed || (multiSelectEnabled && selectedItems?.length !== 0)"
      class="dropdown-item dropdown-select__item dropdown-select__item--clear-selection"
      (click)="handleClearSelection()"
      title="{{ noSelection ?? 'Clear selection' }}"
      data-cy="dropdown-select__clear-selection"
      [disabled]="selectedItems?.length === 0">
      <svg-icon [iconPath]="'assets/icons/button-icons/close.svg'"></svg-icon>
      {{ noSelection ?? 'Clear selection' }}
    </button>
    <ng-container *ngFor="let item of availableItems">
      <ng-container
        [ngTemplateOutlet]="optionTpl"
        [ngTemplateOutletContext]="{ $implicit: item, group: !!item.subOptions }"></ng-container>
      <ng-container *ngIf="item.subOptions">
        <ng-container
          *ngFor="let subItem of item.subOptions"
          [ngTemplateOutlet]="optionTpl"
          [ngTemplateOutletContext]="{ $implicit: subItem, subItem: true }"></ng-container>
      </ng-container>
      <ng-template #optionTpl let-item let-group="group" let-subItem="subItem">
        <button
          data-cy="dropdown-select__item"
          [disabled]="group && !multiSelectEnabled"
          type="button"
          class="dropdown-item dropdown-select__item"
          [class.dropdown-select__item--sub-item]="subItem"
          [class.dropdown-select__item--selected]="isSelectedItem(item, group) && !itemTemplate"
          [attr.title]="item.displayValue"
          (click)="handleItemClick(item, group)">
          <input
            type="checkbox"
            *ngIf="multiSelectEnabled"
            class="form-check-input dropdown-select__checkbox"
            [attr.checked]="isSelectedItem(item, group) ? true : null"
            tabindex="-1" />
          <ng-container *ngIf="!itemTemplate">
            <div
              *ngIf="item.displayValue; else emptyValue"
              class="dropdown-select__item-inner"
              [innerHTML]="toStringFunction(item.displayValue, searchFormControl.value)"></div>
          </ng-container>
          <div *ngIf="itemTemplate" class="dropdown-select__item-inner">
            <ng-container
              [ngTemplateOutlet]="itemTemplate"
              [ngTemplateOutletContext]="{
                $implicit: item,
                active: isSelectedItem(item, group),
                highlight: searchFormControl.value
              }"></ng-container>
          </div>
        </button>
      </ng-template>
    </ng-container>
    <button
      [disabled]="true"
      *ngIf="availableItems?.length === 0"
      data-cy="dropdown-select__no-options">
      No options available
    </button>
  </div>
</div>
<ng-template #emptyValue>
  <span class="dropdown-select__item--empty-value">{{ emptyDisplayValue }}</span>
</ng-template>
