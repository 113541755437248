import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'minNumber',
})
export class MinPipe implements PipeTransform {
  transform(a: number, b: number): number {
    return Math.min(a, b);
  }
}
