<!-- TODO: Wrap all of this in an alert of some sort that is already in the application-->

<div class="message-box w-full text-center">
  <div class="message-box__inner">
    <p
      class="message-box__title text-base py-3 text-secondary font-medium"
      [class.message-box__title--has-content]="hasContent">
      {{ title }}
    </p>
    <div
      #contentContainer
      *ngIf="content"
      class="message-box__content"
      [class.message-box__content--has-title]="title">
      <ng-container>{{ content }}</ng-container>
      <ng-content select="app-message-content" *ngIf="!content"></ng-content>
    </div>
  </div>
</div>
