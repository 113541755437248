import { EventEmitter, Injectable } from '@angular/core';
import { NgFlowchartStepComponent } from '@r-howitzer/ng-flowchart';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class NodeDrawerService {
  public readonly: boolean = false;
  public semiReadonly: boolean = false;
  public drawerPayload: NodeDrawerPayload;
  public campaignId: number;
  public copyWorkflowScreenDisplayed: boolean = false;
  public isSequnceLoaded: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
  public initNode = {
    root: {
      id: 's1669285574184',
      type: 'init',
      data: {},
      children: [],
    },
  };
  onNodeClick = new EventEmitter<NodeDrawerPayload>();
  triggerRender = new EventEmitter<void>();
  onComponentInserted = new EventEmitter<NgFlowchartStepComponent<any>>();
}

export interface NodeDrawerPayload {
  data: any;
  sender: any;
}
