import { Directive, ElementRef, Input, AfterViewInit, Renderer2 } from '@angular/core';
import { UserInterfaceService } from '@shared/user-interface/user-interface.service';

@Directive({
  selector: '[table-cell]',
  host: { class: 'whitespace-nowrap truncate border-none pl-3 pr-3 py-2' },
})
export class TableCellDirective implements AfterViewInit {
  @Input() textAlign: 'left' | 'center' = 'left';

  // Useful when having multiple rows of text in a single row
  @Input() itemsAlignCenter: boolean = true;

  private divs: HTMLElement[];

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private _uiService: UserInterfaceService,
  ) {}

  ngAfterViewInit(): void {
    this.divs = this.el.nativeElement.querySelectorAll('div:first-child');

    for (const div of this.divs) {
      this.renderer.setStyle(div, 'overflow', 'hidden');
      this.renderer.addClass(div, 'flex');

      if (this.itemsAlignCenter) {
        this.renderer.addClass(div, 'items-center');
      }

      this.applyTextAlignment(div);
    }
  }

  private applyTextAlignment(div: HTMLElement) {
    if (this.textAlign === 'center') {
      this.renderer.addClass(div, 'justify-center');
    } else {
      this.renderer.addClass(div, 'justify-start');
    }
  }
}
