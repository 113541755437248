import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AppLinkedInPremiumPlans } from '@shared/AppEnums';
import {
  AdminLinkedInAccountDto,
  LinkedInAccountDto,
  LinkedInAccountState,
  RecruiterState,
  SalesNavigatorState,
} from '@shared/service-proxies/service-proxies';
import { SvgIconComponent } from '@shared/components/svg-icon/svg-icon.component';

@Component({
  imports: [CommonModule, MatIconModule, MatTooltipModule, SvgIconComponent],
  selector: 'linkedin-account-subscriptions',
  templateUrl: './linkedin-account-subscriptions.component.html',
  standalone: true,
})
export class LinkedInAccountSubscriptionsComponent implements OnChanges {
  @Input() account: LinkedInAccountDto | AdminLinkedInAccountDto;

  @Input() iconsSize: 'small' | 'large' = 'large';
  @Input() textSize: 'small' | 'large' = 'large';
  @Input() showSubscriptionName: boolean = false;
  @Input() showFreeAccountTextIfNoSubscription: boolean = false;

  @Input() invalidNavigatorTooltip =
    'This account has a LinkedIn Sales Navigator subscription, but it has not been properly connected to the tool. You can reconnect it on the LinkedIn accounts screen.';

  @Input() invalidRecruiterTooltip =
    'This account has a LinkedIn Recruiter subscription, but it has not been properly connected to the tool. You can reconnect it on the LinkedIn accounts screen.';

  showPremium: boolean = false;

  showFreeAccountText: boolean;

  protected readonly SalesNavigatorState = SalesNavigatorState;
  protected readonly LinkedInAccountState = LinkedInAccountState;
  protected readonly RecruiterState = RecruiterState;

  ngOnChanges(event: SimpleChanges): void {
    if (event.account) {
      this.determineShownPlans();
    }
  }

  private determineShownPlans() {
    this.showPremium =
      this.account?.linkedInUserProfile?.premiumPlans.includes(AppLinkedInPremiumPlans.PREMIUM) &&
      !this.account?.hasRecruiter &&
      !this.account?.hasNavigator;
    this.showFreeAccountText =
      !this.account?.hasRecruiter &&
      !this.account?.hasNavigator &&
      !this.showPremium &&
      this.showFreeAccountTextIfNoSubscription;
  }
}
