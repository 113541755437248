import { Pipe, PipeTransform } from '@angular/core';
import { LinkedInUserProfileDto } from '@shared/service-proxies/service-proxies';

@Pipe({
  name: 'userDisplayNamePipe',
})
export class UserDisplayNamePipe implements PipeTransform {
  transform(profile: LinkedInUserProfileDto, altName: string): string {
    if (profile?.fullName) {
      return profile.fullName;
    }

    if (profile?.firstName && profile?.lastName) {
      return profile.firstName + ' ' + profile.lastName;
    } else {
      return altName;
    }
  }
}
