import { Directive, ElementRef, HostListener, OnInit, Renderer2 } from '@angular/core';
import { NgModel } from '@angular/forms';

@Directive({
  selector: '[standardInput]',
  host: { class: 'text-14px px-1 font-medium text-secondary' },
})
export class InputStyleDirective implements OnInit {
  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private ngModel: NgModel,
  ) {}

  ngOnInit() {
    this.checkForInputLength();
  }

  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    // Delay the execution to allow ngModel to update
    setTimeout(() => {
      this.checkForInputLength();
    });
  }

  private checkForInputLength() {
    const inputValue: string = this.ngModel.model;
    if (inputValue && inputValue.length > 0) {
      this.renderer.addClass(this.el.nativeElement, 'text-dark');
      this.renderer.removeClass(this.el.nativeElement, 'text-secondary');
    } else {
      this.renderer.addClass(this.el.nativeElement, 'text-secondary');
      this.renderer.removeClass(this.el.nativeElement, 'text-dark');
    }
  }
}
