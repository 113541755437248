import { Component, Inject, Injector } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModularDialogData } from './modular-dialog-interfaces';

// Only works with single input (for now)
@Component({
  selector: 'modular-dialog',
  templateUrl: 'modular-dialog.component.html',
})
export class ModularDialogComponent {
  data: ModularDialogData;
  input: string;
  isLoading: boolean;

  constructor(
    private _injector: Injector,
    private _dialogRef: MatDialogRef<ModularDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: ModularDialogData,
  ) {
    this.data = _data;
  }

  onConfirm(): void {
    this.isLoading = true;

    this.data
      .callbackFn(this.input)
      .then((res) => {
        if (res) {
          this.onFunctionCompletedSuccessfully();
        }
      })
      .catch((err) => {
        this.onFunctionFailed();
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  onFunctionCompletedSuccessfully(): void {
    this._dialogRef.close(true);
  }

  onFunctionFailed(): void {
    this._dialogRef.close(false);
  }

  onCancel(): void {
    this._dialogRef.close(false);
  }

  get isInputValid(): boolean {
    return this.input && this.input.length !== 0;
  }
}
