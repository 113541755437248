import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Filter } from '../filter.model';
import { UntypedFormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-dynamic-field',
  templateUrl: './dynamic-field.component.html',
  styleUrls: ['./dynamic-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicFieldComponent implements OnInit {
  @Input() public filter!: Filter;

  @Output() public readonly filterChange: EventEmitter<Filter> = new EventEmitter<Filter>();

  public readonly control = new UntypedFormControl();

  ngOnInit(): void {
    this.control.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)),
      )
      .subscribe((value) => {
        this.filterChange.emit({ ...this.filter, value });
      });
  }
}
