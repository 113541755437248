import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'password-visibility',
  templateUrl: './password-visibility.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./password-visibility.component.scss'],
})
export class PasswordVisibilityComponent {
  @Input() disableAutofill = false;
  @Input() placeholder: string = '';
  @Input() disabled: boolean = false;
  @Input() isValid: boolean = false;
  @Output() passwordChange: EventEmitter<string> = new EventEmitter<string>();
  @Input() inputPasswordChange: string = '';
  isPasswordVisible: boolean = false;

  emitPasswordChange() {
    this.passwordChange.emit(this.inputPasswordChange);
  }

  // this is a workaround for the browsers' ball sweating not allowing us to stop autofill
  togglePasswordVisibility(passwordField: HTMLInputElement) {
    this.isPasswordVisible = !this.isPasswordVisible;
    if (this.disableAutofill) {
      passwordField.style.fontFamily = this.isPasswordVisible ? 'inherit' : 'password-font';
    } else {
      passwordField.type = this.isPasswordVisible ? 'text' : 'password';
    }
  }
}
