import { Component, EventEmitter, Input, Output } from '@angular/core';
import { accountModuleAnimation } from '@shared/animations/routerTransition';
import { InboxScrapeConfiguration } from '@shared/service-proxies/service-proxies';
import { getInboxConfigurations } from '@app/linkedin-accounts/connect-account/connect-account-consts';
import { WhiteLabelService } from '@app/white-label.service';

@Component({
  selector: 'linkedin-inbox-configuration',
  templateUrl: 'inbox-configuration.component.html',
  animations: [accountModuleAnimation()],
})
export class LinkedInInboxConfigurationComponent {
  @Input() inboxConfigurations;
  @Input() label: string;
  @Input() inboxPrivacyConfiguration: InboxScrapeConfiguration;
  @Output() onSelectedInboxOptionChange = new EventEmitter<InboxScrapeConfiguration>();

  constructor(private _whiteLabelService: WhiteLabelService) {
    this.inboxConfigurations = getInboxConfigurations(this._whiteLabelService.companyName);
  }

  onSelectedInboxConfig(): void {
    this.onSelectedInboxOptionChange.emit(this.inboxPrivacyConfiguration);
  }
}
