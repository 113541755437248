<div class="xl:flex lg:flex md:block flex-col md:px-60 md:mt-5 lg:px-0 lg:mt-0 gap-0.5">
  <span class="text-lg text-gray-500 dark:text-gray-400">
    <b>{{ this.ssiData?.memberScore?.subScores[0].score | number: '1.2-2' }}</b>
    | {{ AppSSIScoreLabels.PROFESSIONAL_BRAND }}
  </span>
  <progress-bar
    class="text-[#E55800]"
    [value]="this.ssiData?.memberScore?.subScores[0].score / 0.25"></progress-bar>

  <span class="text-lg text-gray-500 dark:text-gray-400">
    <b>{{ this.ssiData?.memberScore?.subScores[1].score | number: '1.2-2' }}</b>
    | {{ AppSSIScoreLabels.FIND_RIGHT_PEOPLE }}
  </span>
  <progress-bar
    class="text-[#6200EE]"
    [value]="this.ssiData?.memberScore?.subScores[1].score / 0.25"></progress-bar>

  <span class="text-lg text-gray-500 dark:text-gray-400 w-full">
    <b>{{ this.ssiData?.memberScore?.subScores[2].score | number: '1.2-2' }}</b>
    | {{ AppSSIScoreLabels.INSIGHT_ENGAGEMENT }}
  </span>
  <progress-bar
    class="text-[#087889]"
    [value]="this.ssiData?.memberScore?.subScores[2].score / 0.25"></progress-bar>

  <span class="text-lg text-gray-500 dark:text-gray-400 w-full">
    <b>{{ this.ssiData?.memberScore?.subScores[3].score | number: '1.2-2' }}</b>
    | {{ AppSSIScoreLabels.STRONG_RELATIONSHIP }}
  </span>
  <progress-bar
    class="text-[#0091CA]"
    [value]="this.ssiData?.memberScore?.subScores[3].score / 0.25"></progress-bar>
</div>
