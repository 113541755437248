import { Pipe, PipeTransform } from '@angular/core';
import { ConnectAccountConsts } from '@app/linkedin-accounts/connect-account/connect-account-consts';

@Pipe({
  name: 'isPinValidPipe',
  pure: true,
})
export class IsPinValidPipe implements PipeTransform {
  transform(pin: string): boolean {
    return ConnectAccountConsts.isPinValid(pin);
  }
}
