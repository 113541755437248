import { Pipe, PipeTransform } from '@angular/core';
import { AppCampaignStatus } from '@shared/AppEnums';

@Pipe({
  name: 'showWorkflowCopyButton',
})
export class showWorkflowCopyButton implements PipeTransform {
  transform(status: AppCampaignStatus): AppCampaignStatus {
    return status === AppCampaignStatus.DRAFT;
  }
}
