import * as _ from 'lodash';
import * as moment from 'moment';

export class TimeUtility {
  public static getTimeInDayFromMinutes(value: number): string {
    const hours = Math.floor(value / 60);
    value -= hours * 60;
    const minutes = value;

    let displayedHours = hours.toString();
    let displayedMinutes = minutes.toString();

    displayedHours = _.padStart(displayedHours, 2, '0');
    displayedMinutes = _.padStart(displayedMinutes, 2, '0');
    return `${displayedHours}:${displayedMinutes}`;
  }

  public static getTimeInDayInMinutesFromString(value: string): number {
    const valueSeparated = value.split(':');
    if (valueSeparated.length < 2) {
      return undefined;
    }
    return parseInt(valueSeparated[0]) * 60 + parseInt(valueSeparated[1]);
  }

  public static defaultTimeSelectOptions: string[] = [
    '00:00',
    '00:30',
    '01:00',
    '01:30',
    '02:00',
    '02:30',
    '03:00',
    '03:30',
    '04:00',
    '04:30',
    '05:00',
    '05:30',
    '06:00',
    '06:30',
    '07:00',
    '07:30',
    '08:00',
    '08:30',
    '09:00',
    '09:30',
    '10:30',
    '10:00',
    '11:00',
    '11:30',
    '12:00',
    '12:30',
    '13:00',
    '13:30',
    '14:00',
    '14:30',
    '15:00',
    '15:30',
    '16:00',
    '16:30',
    '17:00',
    '17:30',
    '18:00',
    '18:30',
    '19:00',
    '19:30',
    '20:00',
    '20:30',
    '21:00',
    '21:30',
    '22:00',
    '22:30',
    '23:00',
    '23:30',
  ];

  public static AddDaysToDate(date: Date, days: number): Date {
    var date = new Date(date.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  }

  public static GetUtcShiftedMoment(date: Date): moment.Moment {
    let offset = date.getTimezoneOffset();
    return moment(date).subtract(offset, 'minutes');
  }
}
