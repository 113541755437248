import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

export interface AppConfig {
  notUs: boolean;
  companyLogo: string;
  companyName: string;
  companyLogoSidebar: string;
  loginDashboardLogo: string;
  colorProps: {
    prop: string;
    value: string;
  }[];
  favIcon: string;
}

@Injectable({
  providedIn: 'root',
})
export class WhiteLabelService {
  appConfig = {
    notUs: false,
    companyName: 'HeyReach',
    companyLogo: 'assets/images/logo/logo-black.png',
    companyLogoSidebar: 'assets/images/logo/heyreach-icon.svg',
    loginDashboardLogo: 'assets/images/auth/app-sample.png',
    colorProps: [
      {
        prop: '--primary-color',
        value: '#1C64F2',
      },
      {
        prop: '--secondary-color',
        value: '#6B7280',
      },
      {
        prop: '--success-color',
        value: '#31C48D',
      },
      {
        prop: '--failed-color',
        value: '#FDE8E8',
      },
      {
        prop: '--main-background-color',
        value: '#100f26',
      },
      {
        prop: '--main-background-text-color',
        value: '#d9d9d9',
      },
      {
        prop: '--secondary-background-color',
        value: '#1F2433',
      },
      {
        prop: '--secondary-background-text-color',
        value: '#d9d9d9',
      },
      {
        prop: '--third-background-color',
        value: '#FFFFFF',
      },
      {
        prop: '--third-background-primary-text-color',
        value: '#1F2A37',
      },
      {
        prop: '--third-background-secondary-text-color',
        value: '#6B7280',
      },
      {
        prop: '--challenged-text-color',
        value: '#F9FAFB',
      },
      {
        prop: '--challenged-background-color',
        value: '#6B7280',
      },
      {
        prop: '--error-color',
        value: '#DF2F48',
      },
      {
        prop: '--warn-color',
        value: '#F98080',
      },
      {
        prop: '--warn-yellow-color',
        value: '#FDF6B2',
      },
      {
        prop: '--warn-yellow-contrast-color',
        value: '#C27803',
      },
      {
        prop: '--dark-color',
        value: '#111927',
      },
      {
        prop: '--light-muted-color',
        value: '#E5E7EB',
      },
      {
        prop: '--light-color',
        value: '#F4F6F8',
      },
      {
        prop: '--lime-color',
        value: '#6DECA9',
      },
      {
        prop: '--sky-color',
        value: '#29CCE5',
      },
      {
        prop: '--ink-color',
        value: '#172B4D',
      },
      {
        prop: '--ink-muted-color',
        value: '#9ab4e1',
      },
      {
        prop: '--pale-color',
        value: '#E5E7EB',
      },
      {
        prop: '--pale-muted-color',
        value: '#D6DAE2',
      },
      {
        prop: '--primary-muted-color',
        value: '#E1EFFE',
      },
      {
        prop: '--secondary-muted-color',
        value: '#EEEEEE',
      },
      {
        prop: '--success-muted-color',
        value: '#DEF7EC',
      },
      {
        prop: '--error-muted-color',
        value: '#D0BC80',
      },
      {
        prop: '--warn-muted-color',
        value: '#F3F4F6',
      },
      {
        prop: '--dark-muted-color',
        value: '#374151',
      },
      {
        prop: '--purple-color',
        value: '#8556f8',
      },
      {
        prop: '--purple-muted-color',
        value: '#eae8fe',
      },
      {
        prop: '--pink-color',
        value: '#CD8DE5',
      },
      {
        prop: '--sand-color',
        value: '#df960a',
      },
      {
        prop: '--sand-muted-color',
        value: '#fefedc',
      },
      {
        prop: '--amber-color',
        value: '#D97706',
      },
      {
        prop: '--amber-muted-color',
        value: '#FEECDC',
      },
      {
        prop: '--primary-pale-color',
        value: '#C3DDFD',
      },
      {
        prop: '--connections-sent-dashboard-color',
        value: '#5285D5',
      },
      {
        prop: '--connections-accepted-dashboard-color',
        value: '#00D589',
      },
      {
        prop: '--messages-sent-dashboard-color',
        value: '#F4A12A',
      },
      {
        prop: '--message-replies-dashboard-color',
        value: '#9b59b6',
      },
      {
        prop: '--inmails-sent-dashboard-color',
        value: '#24bce2',
      },
      {
        prop: '--inmail-replies-dashboard-color',
        value: '#34495e',
      },
      {
        prop: '--selected-color',
        value: '#ffffff1a',
      },
      {
        prop: '--linkedin-premium-color',
        value: '#B39332',
      },
      {
        prop: '--linkedin-sales-navigator-color',
        value: '#2E74AB',
      },
      {
        prop: '--linkedin-recruiter-color',
        value: '#2E74AB',
      },
      {
        prop: '--light-green-color',
        value: '#d1fae5',
      },
      {
        prop: '--dark-green-color',
        value: '#065f46',
      },
      {
        prop: '--danger-muted-color',
        value: '#FDE8E8',
      },
      {
        prop: '--warning-muted-color',
        value: '#FDF6B2',
      },
      {
        prop: '--warning-color',
        value: '#C27803',
      },
      {
        prop: '--queued-color',
        value: '#7E3AF2',
      },
      {
        prop: '--queued-muted-color',
        value: '#DCD7FE',
      },
    ],
    favIcon: 'assets/favicon.ico',
  };

  notUs = false;
  companyLogo = '';
  companyName = '';
  companyLogoSidebar = '';
  loginDashboardLogo = '';
  colorProps = [];

  favIcon: HTMLLinkElement = document.querySelector('#appIcon');

  constructor(private titleService: Title) {}

  applyTheme() {
    this.colorProps.forEach((color) => {
      document.documentElement.style.setProperty(color.prop, color.value);
    });
    // https://p7.hiclipart.com/preview/531/180/958/5bbc0f82754ff-thumbnail.jpg example icon
  }

  setUpConfig(appConfig: AppConfig) {
    this.notUs = appConfig.notUs ?? this.appConfig.notUs;
    this.companyLogo = appConfig.companyLogo ?? this.appConfig.companyLogo;
    this.companyLogoSidebar = appConfig.companyLogoSidebar ?? this.appConfig.companyLogoSidebar;
    this.loginDashboardLogo = appConfig.loginDashboardLogo ?? this.appConfig.loginDashboardLogo;
    this.favIcon.href = appConfig.favIcon ?? this.appConfig.favIcon;
    this.companyName = appConfig.companyName ?? this.appConfig.companyName;

    if (appConfig.colorProps) {
      this.colorProps = this.appConfig.colorProps.map((x) =>
        appConfig.colorProps.some((y) => y.prop == x.prop && y.value)
          ? appConfig.colorProps.find((y) => y.prop == x.prop)
          : x,
      );
    } else {
      this.colorProps = this.appConfig.colorProps;
    }

    this.titleService.setTitle(this.companyName);
  }

  setUpDefaultConfig() {
    this.notUs = this.appConfig.notUs;
    this.companyLogo = this.appConfig.companyLogo;
    this.companyLogoSidebar = this.appConfig.companyLogoSidebar;
    this.loginDashboardLogo = this.appConfig.loginDashboardLogo;
    this.favIcon.href = this.appConfig.favIcon;
    this.companyName = this.appConfig.companyName;
    this.colorProps = this.appConfig.colorProps;
    this.titleService.setTitle(this.companyName);
  }
}
