import { Component, Inject, Injector, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ImportCSVComponent } from '@shared/components/import-csv/import-csv.component';

@Component({
  selector: 'import-csv-dialog',
  templateUrl: 'import-csv-dialog.component.html',
})
export class ImportCSVDialogComponent {
  listId: number = undefined;

  get closeDisabled(): boolean {
    if (!this.importCsvComponent) {
      return false;
    }
    if (this.importCsvComponent.isLoading) {
      return true;
    }
    return false;
  }

  @ViewChild(ImportCSVComponent) importCsvComponent: ImportCSVComponent;

  constructor(
    private _injector: Injector,
    private _dialogRef: MatDialogRef<ImportCSVDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private _listId: number,
  ) {
    if (_listId) this.listId = _listId;
    _dialogRef.disableClose = true;
  }

  onConfirm(): void {
    this._dialogRef.close(true);
  }

  onCancel(): void {
    this._dialogRef.close(false);
  }

  close() {
    if (!this.closeDisabled) {
      this._dialogRef.close();
    }
  }
}

export interface ConfirmationDialogData {
  message: string;
}
