<div class="flex flex-row gap-x-2">
  <ng-container>
    <status-badge class="flex" [status]="statusBadgeData.badgeType">
      <div class="flex flex-row gap-x-2">
        <svg-icon class="flex items-center" [iconPath]="statusBadgeData.icon"></svg-icon>
        <span class="pt-0.5">{{ statusBadgeData.text }}</span>
      </div>
    </status-badge>
  </ng-container>
  <span
    *ngIf="!!statusBadgeData.tooltipText"
    class="flex items-center"
    [matTooltip]="statusBadgeData.tooltipText">
    <svg-icon
      class="flex items-center"
      [iconPath]="'./../../assets/icons/accounts-icons/help-icon.svg'"></svg-icon>
  </span>
</div>
