import { Pipe, PipeTransform } from '@angular/core';
import { LnkLinkedInProfileTagDto } from '@shared/service-proxies/service-proxies';
import { ChipWithOverflow } from '../profile-tag/chips-with-overflow.model';

@Pipe({
  name: 'tagLinks',
})
export class ProfileTagsPipe implements PipeTransform {
  transform(tagLinks: LnkLinkedInProfileTagDto[]): ChipWithOverflow[] {
    if (!tagLinks || tagLinks.length === 0) {
      return [];
    }
    return tagLinks.map((x) => {
      return new ChipWithOverflow(
        x.profileTag.displayName,
        x.profileTag.colorHex,
        '',
        false,
        '',
        '',
        'rounded-full',
      );
    });
  }
}
