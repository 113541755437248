import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Filter } from '../filter.model';
import { TableAction } from '../table-models/table-action.model';

@Component({
  selector: 'app-dynamic-filter-row',
  templateUrl: './dynamic-filter-row.component.html',
  styleUrls: ['./dynamic-filter-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicFilterRowComponent {
  @Input() public filters: Filter[] = [];
  @Input() public actions: TableAction<any>[] = [];
  @Input() public isDataLoading = true;
  @Input() public hasSelectedData: boolean = false;
  @Input() public hasSelectedList: boolean = false;
  @Input() public positionTableActionsAt: 'start' | 'end' = 'start';
  @Input() public showButtons: boolean;
  @Output() public readonly filtersChange: EventEmitter<Filter[]> = new EventEmitter<Filter[]>();

  filterValueChanged(event: Filter): void {
    this.filters.filter((x) => x.id == event.id).forEach((x) => (x.value = event.value));
    this.filtersChange.emit(this.filters);
  }

  public handleActionClick(tableAction: TableAction<any>): void {
    tableAction.action();
  }

  public trackByFn = (index: number, x: Filter) => x.id;
}
