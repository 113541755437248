import { EventEmitter, Injectable } from '@angular/core';
import {
  ApiException,
  EmailEnrichmentServiceProxy,
  LinkedInUserProfileDto,
  SubscriptionServiceProxy,
} from '@shared/service-proxies/service-proxies';
import { AppSessionService } from '@shared/session/app-session.service';

@Injectable()
export class AddonFeaturesService {
  public emailCredits: number = 0;
  public generationCredits: number = 0;

  public onChange: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private _emailEnrichService: EmailEnrichmentServiceProxy,
    private _subscriptionService: SubscriptionServiceProxy,
    private _appSession: AppSessionService,
  ) {
    this.adjustCredits();
  }

  public async enrichEmailFromLists(listId: number): Promise<LinkedInUserProfileDto[]> {
    let enrich = await this._emailEnrichService.emailEnrichList(listId).toPromise();

    this.adjustCredits();

    return enrich.linkedInUserProfiles;
  }

  public async enrichEmailProfiles(profileIds: string[]): Promise<LinkedInUserProfileDto[]> {
    let enrich = await this._emailEnrichService.emailEnrichLinkedInProfiles(profileIds).toPromise();

    this.adjustCredits();

    return enrich.linkedInUserProfiles;
  }

  public async adjustCredits() {
    // refresh or smthing
    if (!this._appSession.tenantId) {
      return;
    }
    this._subscriptionService.getCredits().subscribe((res) => {
      this.emailCredits = res.creditsCtegories?.emailEnrichmentCredits?.totalCredits;
      this.generationCredits = res.creditsCtegories?.messageGenerationCredits?.totalCredits;
      this.onChange.emit();
    });
  }
}
