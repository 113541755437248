import { Pipe, PipeTransform } from '@angular/core';
import { SelectOption } from '../data-table/filter.model';
import { SELECTED_ITEM_LIMIT, ITEM_LIMIT } from './dropdown-select.component';

@Pipe({
  name: 'moreSelectedOptions',
})
export class MoreSelectedOptionsPipe implements PipeTransform {
  public transform(selectOptions: SelectOption[]): string[] {
    const leftOver = selectOptions.length - SELECTED_ITEM_LIMIT - ITEM_LIMIT;
    return [
      ...selectOptions
        .slice(SELECTED_ITEM_LIMIT, ITEM_LIMIT)
        .map((selectOption) => selectOption.displayValue),
      ...(leftOver > 0 ? [`+ ${leftOver} more items`] : []),
    ];
  }
}
