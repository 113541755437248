import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
})
export class BannerComponent implements OnInit {
  @Input() color: 'primary' | 'warn' = 'primary';
  @Input() border: boolean;

  public classes: string;

  private colorClassMap: Record<'primary' | 'warn', string>;

  ngOnInit() {
    this.colorClassMap = {
      primary: `text-primary bg-primary-muted ${this.border ? 'border-primary border-2' : ''}`,
      warn: `text-warn bg-warn-translucent ${this.border ? 'border-warn border-2' : ''}`,
    };

    this.classes = this.colorClassMap[this.color];
  }
}
