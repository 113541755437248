<ng-container [ngSwitch]="filter.controlType">
  <ng-container *ngSwitchCase="'input'">
    <label
      class="flex flex-row border-2 pl-3 w-44 border-secondary-muted text-secondary h-11 rounded-md items-center">
      <mat-icon matPrefix class="icon-size-4 mr-2" [svgIcon]="filter.svgIcon"></mat-icon>
      <input
        class="w-full text-black text-md font-base tracking-wide"
        [placeholder]="filter.placeholder"
        matInput
        [formControl]="control" />
    </label>
  </ng-container>
  <ng-container *ngSwitchCase="'select'">
    <mat-form-field class="fuse-mat-no-subscript w-52 sm:w-36 md:w-52">
      <mat-select placeholder="All" class="text-secondary text-bold" [formControl]="control">
        <mat-option *ngFor="let option of filter.options" [value]="option.value">
          {{ option.displayValue }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </ng-container>
  <ng-container *ngSwitchCase="'date'"></ng-container>
  <ng-container *ngSwitchCase="'dateRange'"></ng-container>
  <ng-container *ngSwitchCase="'number'"></ng-container>
  <ng-container *ngSwitchCase="'checkbox'"></ng-container>
  <ng-container *ngSwitchCase="'multiSelect'"></ng-container>
</ng-container>
