import { Component, Input } from '@angular/core';
import { appModuleAnimation } from '@shared/animations/routerTransition';

@Component({
  selector: 'tag-status-badge',
  templateUrl: './tag-status-badge.component.html',
  animations: [appModuleAnimation()],
})
export class TagStatusBadgeComponent {
  @Input() colorHex: string = '';
}

export type TagStatusBadgeStatusType =
  | 'default'
  | 'danger'
  | 'warning'
  | 'success'
  | 'progress'
  | 'iddle'
  | 'queued';
