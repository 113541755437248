<mat-label class="text-secondary text-left text-base font-medium">{{ label }}</mat-label>
<mat-form-field class="w-full s-mat-dense s-mat-no-subscript mt-2 mb-3">
  <mat-select
    class="text-secondary font-bold"
    placeholder="Choose your inbox privacy configuration"
    [(ngModel)]="inboxPrivacyConfiguration"
    [ngModelOptions]="{ standalone: true }"
    (ngModelChange)="onSelectedInboxConfig()">
    <mat-option *ngFor="let option of inboxConfigurations" [value]="option.value">
      <div class="inline-block">{{ option.text }}</div>
    </mat-option>
  </mat-select>
</mat-form-field>
