import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CampaignDto, CampaignServiceProxy } from '@shared/service-proxies/service-proxies';
import { debounceTime, finalize, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs/internal/Subject';
import { AppCampaignStatus } from '@shared/AppEnums';

@Component({
  selector: 'add-leads-to-campaign',
  templateUrl: './add-leads-to-campaign.component.html',
  styleUrls: ['./add-leads-to-campaign.component.scss'],
  styles: [
    `
      .text-amber {
        color: #d97706 !important;
      }

      .text-green {
        color: #31c48d !important;
      }

      .bg-amber-muted {
        background-color: #feecdc !important;
      }

      .bg-green-muted {
        background-color: #def7ec !important;
      }
    `,
  ],
})
export class AddLeadsToCampaignComponent implements OnInit, OnDestroy {
  campaigns: CampaignDto[] = [];
  campaignId: number;
  loading: boolean = false;
  leadsCount: number = 1;
  pageNumber: number = 0;
  campaignNameControl: FormControl = new FormControl('');
  searchString = '';

  searchInputSubject$: Subject<void> = new Subject<void>();

  /// Private
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: LeadsCount,
    public dialogRef: MatDialogRef<AddLeadsToCampaignComponent>,
    private _campaignService: CampaignServiceProxy,
  ) {}

  get isAddButtonDisabled(): boolean {
    return this.campaignId == null || this.loading;
  }

  ngOnInit(): void {
    this.getCampaigns();
    this.setFiltersEvents();
    this.leadsCount = this.data.leadsCount || 0;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getCampaigns(): void {
    this.loading = true;
    this._campaignService
      .getCampaignsForAddLeads(this.pageNumber, this.searchString)
      .pipe(
        finalize(() => {
          this.loading = false;
        }),
      )
      .subscribe((res) => {
        this.campaigns = this.campaigns.concat(res.items);
      });
  }

  addLeadsToCampaign() {
    this.dialogRef.close({
      campaignId: this.campaignId,
    });
  }

  setFiltersEvents() {
    this.searchInputSubject$
      .asObservable()
      .pipe(debounceTime(500), takeUntil(this.destroy$))
      .subscribe(() => {
        this.campaigns = [];
        this.searchString = this.campaignNameControl.value;
        this.pageNumber = 0;
        this.getCampaigns();
      });
  }

  onSearchInput() {
    this.searchInputSubject$.next();
  }

  onScrolledFilterCampaign() {
    this.pageNumber++;
    this.getCampaigns();
  }

  getStatusColor(status: AppCampaignStatus) {
    const colorString = 'status-';
    switch (status) {
      case AppCampaignStatus.DRAFT:
        return colorString + 'gray';
      case AppCampaignStatus.LOCKED:
      case AppCampaignStatus.PAUSED:
        return 'text-amber bg-amber-muted';
      case AppCampaignStatus.CANCELED:
      case AppCampaignStatus.FAILED:
        return colorString + 'red';
      case AppCampaignStatus.FINISHED:
        return colorString + ' text-green bg-green-muted';
      default:
        return colorString + 'blue';
    }
  }

  getStatusText(status: AppCampaignStatus) {
    switch (status) {
      case AppCampaignStatus.DRAFT:
        return 'Draft';
      case AppCampaignStatus.IN_PROGRESS:
      case AppCampaignStatus.IN_PROGRESS_TRAILING:
        return 'Ongoing';
      case AppCampaignStatus.PAUSED:
        return 'Paused';
      case AppCampaignStatus.FINISHED:
        return 'Finished';
      case AppCampaignStatus.CANCELED:
        return 'Canceled';
      case AppCampaignStatus.FAILED:
        return 'Failed';
      case AppCampaignStatus.LOCKED:
        return 'Locked';
    }
  }
}

export interface LeadsCount {
  leadsCount: number;
}
