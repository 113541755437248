import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgxPaginationModule } from 'ngx-pagination';

import { AppSessionService } from './session/app-session.service';
import { AppUrlService } from './nav/app-url.service';
import { AppAuthService } from './auth/app-auth.service';
import { AppAuthenticatedRouteGuard, AppRouteGuard } from './auth/auth-route-guard';

// Pipes
import { DateFormatPipe } from '@shared/pipes/date-format.pipe';
import { PluralOnNumberPipe } from '@shared/pipes/plural-on-number.pipe';
import { YoutubeSafeVideoUrlPipe } from '@shared/pipes/linkedin-searches-video-pipe';
import { ConnectAccountFormStatePipe } from '@shared/pipes/connect-account-form-state-pipe';
import { showWorkflowCopyButton } from '@app/campaigns/create-campaign/workflow/show-workflow-copy-button.pipe';
import { MaxPipe } from '@shared/components/data-table/pipes/max-number.pipe';
import { MinPipe } from '@shared/components/data-table/pipes/min-number.pipe';

import { AbpPaginationControlsComponent } from './components/pagination/abp-pagination-controls.component';
import { AbpValidationSummaryComponent } from './components/validation/abp-validation.summary.component';
import { AbpModalHeaderComponent } from './components/modal/abp-modal-header.component';
import { AbpModalFooterComponent } from './components/modal/abp-modal-footer.component';
import { LayoutStoreService } from './layout/layout-store.service';
import { AlertComponent } from './components/alert/alert.component';
import { HotReloadService } from './hotReload/hotReload.service';

import { BusyDirective } from './directives/busy.directive';
import { EqualValidator } from './directives/equal-validator.directive';

import { IconsModule } from './icons/icons.module';
import { MaterialModule } from './material.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ConfirmationDialogComponent } from './components/dialogs/confirmation-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedEndpointAutocompleteComponent } from './components/user-interface/shared-endpoint-autocomplete/shared-endpoint-autocomplete.component';
import { IsLoadingDirective } from './directives/loading.directive';
import { NodeDrawerService } from '@app/campaigns/create-campaign/node-drawer.service';
import { ProxySelectionComponent } from './components/user-interface/proxy-selection/proxy-selection.component';
import { UserAvatarComponent } from './components/user-avatar/user-avatar.component';
import { InboxService } from './inbox-service/inbox.service';
import { LinkedinSelectAccountComponent } from './components/linkedin/select-account/linkedin-select-account.component';
import { LinkedinProfileDisplayComponent } from './components/linkedin/profile-display/linkedin-profile-display.component';
import { LinkedinProfileUrlComponent } from './components/linkedin/profile-display/linkedin-profile-url.component';
import { LinkedinCompanyDisplayComponent } from './components/linkedin/company-display/linkedin-company-display.component';
import { FromToTimePickerComponent } from './components/user-interface/from-to-timepicker/from-to-timepicker.component';
import { ProfileTagService } from './inbox-service/profile-tag.service';
import { CustomTimepickerComponent } from './components/user-interface/timepicker/custom-timepicker.component';
import { ModularDialogComponent } from './components/dialogs/modular-dialog/modular-dialog.component';
import { TimezoneSelector } from './components/user-interface/timezone-selector/timezone-selector.component';
import { UserDisplayNameComponent } from './components/user-display-name/user-display-name.component';
import { UserImageNameComponent } from './components/user-image-name/user-image-name.component';
import { SearchTypeCardComponent } from './components/search-type-card/search-type-card.component';
import { TutorialComponent } from './components/tutorial/tutorial.component';
import { TagsListComponent } from '@app/inbox/profile-tag/tags-list/tags-list.component';
import { SSIPieChartComponent } from './components/ssi-pie-chart/ssi-pie-chart.component';
import { SSIBarChartComponent } from './components/ssi-bar-chart/ssi-bar-chart.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { SecurePipe } from './pipes/secure.pipe';
import { ProfileComponent } from '@app/inbox/profile/profile.component';
import { AddonFeaturesService } from './email-credits/email-credits.service';
import { CrmImageNameComponent } from './components/crm-image-name/crm-image-name.component';
import { ExportToCrmComponent } from './components/dialogs/export-to-crm/export-to-crm.component';
import { AddLeadsToCampaignComponent } from './components/dialogs/add-leads-to-campaign/add-leads-to-campaign.component';
import { IntegrationsService } from '@app/integrations/crm-integrations/integrations-service/integrations.service';
import { LinkedinListsService } from '@app/linkedin-lists/services/linkedin-lists.service';
import { CrmButtonComponent } from '@app/integrations/crm-button/crm-button.component';
import { EmailEnrichButtonComponent } from './components/email-enrich-button/email-enrich-button.component';
import { CreateTagDialogComponent } from '@app/inbox/profile-tag/create-tag-dialog/create-tag-dialog.component';
import { ConnectAccountComponent } from '@app/linkedin-accounts/connect-account/connect-account.component';
import { ConnectProgressComponent } from '@app/linkedin-accounts/connect-account/connect-progress/connect-progress.component';
import { LinkedInCompanySearchSelectComponent } from './components/linkedin/company-search-select/linkedin-company-search-select.component';
import { TeamInviteComponent } from './team-invite/team-invite.component';
import { TagInputModule } from 'ngx-chips';
import { LinkedInAccountCompanySelectComponent } from './components/linkedin/account-companies/linkedin-account-company-select.component';
import { LinkedInInboxConfigurationComponent } from './components/linkedin/inbox-configuration/inbox-configuration.component';
import { LinkedInAccountSearchLockedIndicator } from './components/linkedin/account-search-locked-indicator/account-search-locked-indicator.component';
import { ImportCSVComponent } from './components/import-csv/import-csv.component';
import { ImportMappingComponent } from './components/import-csv/import-mapping/import-mapping.component';
import { ImportCSVDialogComponent } from './components/dialogs/import-csv-dialog/import-csv-dialog.component';
import { TableRowDirective } from './directives/component-directives/table/table-row.directive';
import { TableCellDirective } from './directives/component-directives/table/table-cell.directive';
import { TableContainerDirective } from './directives/component-directives/table/table-container.directive';
import { TableHeaderDirective } from './directives/component-directives/table/table-header.directive';
import { TableHeaderCellDirective } from './directives/component-directives/table/table-header-cell.directive';
import { HeyReachCardComponent } from './components/user-interface/card/heyreach-card.component';
import { StatusBadgeComponent } from './components/user-interface/status-badge/status-badge.component';
import { TagStatusBadgeComponent } from './components/user-interface/status-badge/tag-status-badge.component';
import { InfoCardDisplayComponent } from './components/info-card-display/info-card-display.component.';
import { CodeInputModule } from 'angular-code-input';
import { SecurityCodeComponent } from './components/security-code/security-code.component';
import { ConnectAccountExtensionComponent } from '@app/linkedin-accounts/connect-account/connect-account-extension/connect-account-extension.component';
import { CampaignSelectComponent } from './components/campaign-select/campaign-select.component';
import { CustomTemplateTooltipComponent } from './components/custom-template-tooltip/custom-template-tooltip.component';
import { CustomTemplateTooltipDirective } from './components/custom-template-tooltip/custom-template-tooltip.directive';
import { UserInterfaceService } from './user-interface/user-interface.service';
import { PaginatorComponent } from './components/user-interface/paginator/paginator.component';
import { InputStyleDirective } from './directives/input.directive';
import { PageTitleComponent } from './components/user-interface/page-title/page-title.component';
import { HeaderComponent } from '@app/layout/header.component';
import { PageHeaderComponent } from './components/user-interface/page-header/page-header.component';
import { PageContentContainerComponent } from './components/user-interface/page-content-container/page-content-container.component';
import { DialogContainerComponent } from './components/user-interface/dialog/dialog-container.component';
import { DialogHeaderComponent } from './components/user-interface/dialog/dialog-header.component';
import { ErrorSnackbarComponent } from './components/error-snackbar/error-snackbar.component';
import { DrawerHeaderComponent } from './components/user-interface/drawer/drawer-header.component';
import { DrawerContainerComponent } from './components/user-interface/drawer/drawer-container.component';
import { CustomProxyService } from '@app/linkedin-accounts/connect-account/custom-proxy-service';
import { TabGroupComponent } from './components/tab/tab-group.component';
import { ProgressBarComponent } from './components/user-interface/progress-bar/progress-bar.component';
import { AddLeadsFromMemberIdsOrInboxComponent } from './components/dialogs/add-leads-to-inbox/add-leads-from-member-ids-or-inbox-dialog.component';
import { ListSelectComponent } from '@app/inbox/list-select/list-select.component';
import { AutoOpenMenuComponent } from './components/user-interface/auto-open-menu/auto-open-menu.component';
import { ChipsOverflowComponent } from '@app/inbox/profile-tag/chips-overflow.component';
import { SettingSideBarMenuComponent } from './components/settings-sidebar-menu/settings-sidebar-menu.component';
import { ClickStopPropagation } from './directives/click-stop-propagation';
import { HeyReachButtonDirective } from './directives/heyreach-button.directive';
import { ProgressSpinnerComponent } from './components/user-interface/progress-spinner/progress-spinner.component';
import { StepInfoComponent } from './components/step-info/step-info.component';
import { PasswordVisibilityComponent } from './components/toggle-password-visibility/password-visibility.component';
import { ViewCampaignsService } from '@app/campaigns/view-campaigns.service';
import { BannerComponent } from './components/user-interface/banner/banner.component';
import { UserDisplayNamePipe } from './components/user-display-name/user-display-name.pipe';
import { TableLoadingAnimationComponent } from './components/table-loading-animation/table-loading-animation';
import { CustomCheckboxDirective } from './directives/custom-checkbox.directive';
import { LinkedInUserProfileSubscriptionsComponent } from './components/account-subscriptions/linkedin-user-profile-subscriptions.component';
import { LinkedInAccountSubscriptionsComponent } from '@shared/components/account-subscriptions/linkedin-account-subscriptions';
import { SearchFieldComponent } from '@app/integrations/search-field/search-field.component';
import { SelectComponent } from './components/select/select.component';
import { ProfileTagsPipe } from '@app/inbox/inbox-pipes/profile-tags.pipe';
import { BadgeStatusClassPipe } from './components/user-interface/status-badge/badge-status-class.pipe';
import { IsPinValidPipe } from '@app/linkedin-accounts/connect-account/pipes/IsPinValidPipe';
import { TagUpdateInProgressPipe } from '@app/inbox/profile-tag/tags-list/tag-update-inprogress.pipe';
import { AccountActionButtonPipe } from '@app/linkedin-accounts/pipes/AccountActionButtonPipe';
import { AccountStatusBadgePipe } from '@app/linkedin-accounts/pipes/AccountStatusBadgePipe';
import { AccountActionsPipe } from '@app/linkedin-accounts/pipes/AccountActionsPipe';
import { WorkingHoursService } from '@shared/working-hours/working-hours.service';
import { DropdownSelectComponent } from './components/select/dropdown-select.component';
import { MoreSelectedOptionsPipe } from './components/select/more-selected-options.pipe';
import { CampaignsStatusTextPipe } from './components/data-table/pipes/campign-status-text.pipe';
import { CampaignStatusColorPipe } from './components/data-table/pipes/campaign-status-color.pipe';
import { AnimatedNumberDirective } from '@shared/directives/animated-number.directive';
import { FractionToIntegerPipe } from '@shared/pipes/fraction-to-integer.pipe';
import { SvgIconComponent } from '@shared/components/svg-icon/svg-icon.component';
import { ToggleSwitcherComponent } from '@app/toggle-switcher/toggle-switcher.component';
import { ColumnPickerComponent } from './components/data-table/column-picker/column-picker.component';
import { DynamicFieldComponent } from './components/data-table/dynamic-field/dynamic-field.component';
import { DynamicFilterRowComponent } from './components/data-table/dynamic-filter-row/dynamic-filter-row.component';
import { MessageBoxComponent } from './components/data-table/message-box/message-box.component';
import { DataTableComponentExtended } from './components/data-table/data-table.component';
import { ColumnNameComponent } from './components/data-table/column-name/column-name.component';
import { TableRowFieldComponent } from '@app/linkedin-lists/table-row-field/table-row-field.component';
import { DigitsOnlyPipe } from '@shared/pipes/digits-only.pipe';
import { AccountStatusBadgeComponent } from '@app/linkedin-accounts/account-status-badge/account-status-badge.component';
import { AddLeadsToListComponent } from './components/dialogs/add-leads-to-list/add-leads-to-list-dialog.component';
import { ListInNewTabComponent } from '@shared/components/list-in-new-tab/list-in-new-tab.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NgxPaginationModule,
    IconsModule,
    MaterialModule,
    InfiniteScrollModule,
    ReactiveFormsModule,
    FormsModule,
    NgApexchartsModule,
    TagInputModule,
    CodeInputModule,
    ErrorSnackbarComponent,
    NgOptimizedImage,
    LinkedInUserProfileSubscriptionsComponent,
    LinkedInAccountSubscriptionsComponent,
    SvgIconComponent,
  ],
  declarations: [
    AbpPaginationControlsComponent,
    AbpValidationSummaryComponent,
    ProfileTagsPipe,
    AbpModalHeaderComponent,
    AbpModalFooterComponent,
    AnimatedNumberDirective,
    BusyDirective,
    IsLoadingDirective,
    EqualValidator,
    DateFormatPipe,
    PluralOnNumberPipe,
    SecurePipe,
    MaxPipe,
    MinPipe,
    ConnectAccountFormStatePipe,
    showWorkflowCopyButton,
    AlertComponent,
    ConfirmationDialogComponent,
    ImportCSVDialogComponent,
    LinkedinSelectAccountComponent,
    SharedEndpointAutocompleteComponent,
    ProxySelectionComponent,
    UserAvatarComponent,
    ChipsOverflowComponent,
    CreateTagDialogComponent,
    TagsListComponent,
    TagUpdateInProgressPipe,
    LinkedinProfileDisplayComponent,
    LinkedinProfileUrlComponent,
    LinkedinCompanyDisplayComponent,
    CustomTimepickerComponent,
    FromToTimePickerComponent,
    ModularDialogComponent,
    TimezoneSelector,
    UserDisplayNameComponent,
    UserDisplayNamePipe,
    UserImageNameComponent,
    SearchTypeCardComponent,
    ImportCSVComponent,
    ImportMappingComponent,
    TutorialComponent,
    SSIPieChartComponent,
    SSIBarChartComponent,
    ProfileComponent,
    CrmImageNameComponent,
    ExportToCrmComponent,
    AddLeadsToCampaignComponent,
    CrmButtonComponent,
    EmailEnrichButtonComponent,
    ConnectAccountComponent,
    ConnectProgressComponent,
    LinkedInCompanySearchSelectComponent,
    TeamInviteComponent,
    LinkedInAccountCompanySelectComponent,
    LinkedInAccountSearchLockedIndicator,
    LinkedInInboxConfigurationComponent,
    TableContainerDirective,
    TableRowDirective,
    TableCellDirective,
    TableHeaderDirective,
    TableHeaderCellDirective,
    HeyReachCardComponent,
    StatusBadgeComponent,
    BadgeStatusClassPipe,
    TagStatusBadgeComponent,
    InfoCardDisplayComponent,
    SecurityCodeComponent,
    ConnectAccountExtensionComponent,
    CampaignSelectComponent,
    CustomTemplateTooltipComponent,
    CustomTemplateTooltipDirective,
    PaginatorComponent,
    InputStyleDirective,
    PageHeaderComponent,
    PageTitleComponent,
    PageContentContainerComponent,
    DialogContainerComponent,
    DialogHeaderComponent,
    DrawerHeaderComponent,
    DrawerContainerComponent,
    TabGroupComponent,
    ProgressBarComponent,
    YoutubeSafeVideoUrlPipe,
    ConnectAccountFormStatePipe,
    showWorkflowCopyButton,
    AddLeadsFromMemberIdsOrInboxComponent,
    ListSelectComponent,
    AddLeadsToListComponent,
    AutoOpenMenuComponent,
    SettingSideBarMenuComponent,
    ClickStopPropagation,
    HeyReachButtonDirective,
    ProgressSpinnerComponent,
    StepInfoComponent,
    PasswordVisibilityComponent,
    HeaderComponent,
    SearchFieldComponent,
    SelectComponent,
    BannerComponent,
    CustomCheckboxDirective,
    TableLoadingAnimationComponent,
    IsPinValidPipe,
    AccountStatusBadgePipe,
    AccountActionButtonPipe,
    AccountActionsPipe,
    DropdownSelectComponent,
    MoreSelectedOptionsPipe,
    CampaignsStatusTextPipe,
    CampaignStatusColorPipe,
    TableRowFieldComponent,
    ColumnNameComponent,
    DataTableComponentExtended,
    MessageBoxComponent,
    DynamicFilterRowComponent,
    DynamicFieldComponent,
    ColumnPickerComponent,
    ToggleSwitcherComponent,
    FractionToIntegerPipe,
    DigitsOnlyPipe,
    AccountStatusBadgeComponent,
    ListInNewTabComponent,
  ],
  exports: [
    AbpPaginationControlsComponent,
    AbpValidationSummaryComponent,
    AbpModalHeaderComponent,
    AbpModalFooterComponent,
    AlertComponent,
    AnimatedNumberDirective,
    BusyDirective,
    IsLoadingDirective,
    EqualValidator,
    DateFormatPipe,
    PluralOnNumberPipe,
    SecurePipe,
    ConnectAccountFormStatePipe,
    showWorkflowCopyButton,
    MaterialModule,
    InfiniteScrollModule,
    LinkedinSelectAccountComponent,
    SharedEndpointAutocompleteComponent,
    ProxySelectionComponent,
    UserAvatarComponent,
    ChipsOverflowComponent,
    CreateTagDialogComponent,
    TagsListComponent,
    TagUpdateInProgressPipe,
    LinkedinProfileDisplayComponent,
    LinkedinProfileUrlComponent,
    LinkedinCompanyDisplayComponent,
    CustomTimepickerComponent,
    FromToTimePickerComponent,
    ModularDialogComponent,
    TimezoneSelector,
    UserDisplayNameComponent,
    UserDisplayNamePipe,
    UserImageNameComponent,
    SearchTypeCardComponent,
    ImportCSVComponent,
    ImportMappingComponent,
    TutorialComponent,
    SSIPieChartComponent,
    SSIBarChartComponent,
    NgApexchartsModule,
    ProfileComponent,
    CrmImageNameComponent,
    ExportToCrmComponent,
    AddLeadsToCampaignComponent,
    CrmButtonComponent,
    EmailEnrichButtonComponent,
    ConnectAccountComponent,
    ConnectProgressComponent,
    LinkedInCompanySearchSelectComponent,
    TeamInviteComponent,
    TagInputModule,
    LinkedInAccountCompanySelectComponent,
    LinkedInAccountSearchLockedIndicator,
    LinkedInInboxConfigurationComponent,
    TableContainerDirective,
    TableRowDirective,
    TableCellDirective,
    TableHeaderDirective,
    TableHeaderCellDirective,
    HeyReachCardComponent,
    StatusBadgeComponent,
    BadgeStatusClassPipe,
    TagStatusBadgeComponent,
    InfoCardDisplayComponent,
    CodeInputModule,
    SecurityCodeComponent,
    CampaignSelectComponent,
    CustomTemplateTooltipComponent,
    CustomTemplateTooltipDirective,
    PaginatorComponent,
    InputStyleDirective,
    PageHeaderComponent,
    PageTitleComponent,
    PageContentContainerComponent,
    DialogContainerComponent,
    DialogHeaderComponent,
    ErrorSnackbarComponent,
    DrawerHeaderComponent,
    DrawerContainerComponent,
    TabGroupComponent,
    ProgressBarComponent,
    YoutubeSafeVideoUrlPipe,
    ConnectAccountFormStatePipe,
    showWorkflowCopyButton,
    AddLeadsFromMemberIdsOrInboxComponent,
    AddLeadsToListComponent,
    ListSelectComponent,
    AutoOpenMenuComponent,
    SettingSideBarMenuComponent,
    ClickStopPropagation,
    HeyReachButtonDirective,
    ProgressSpinnerComponent,
    StepInfoComponent,
    PasswordVisibilityComponent,
    HeaderComponent,
    SearchFieldComponent,
    SelectComponent,
    BannerComponent,
    CustomCheckboxDirective,
    TableLoadingAnimationComponent,
    ProfileTagsPipe,
    IsPinValidPipe,
    AccountStatusBadgePipe,
    AccountActionButtonPipe,
    AccountActionsPipe,
    DropdownSelectComponent,
    MoreSelectedOptionsPipe,
    CampaignsStatusTextPipe,
    CampaignStatusColorPipe,
    FractionToIntegerPipe,
    DigitsOnlyPipe,
    TableRowFieldComponent,
    ColumnNameComponent,
    DataTableComponentExtended,
    MessageBoxComponent,
    DynamicFilterRowComponent,
    DynamicFieldComponent,
    ColumnPickerComponent,
    ToggleSwitcherComponent,
    AccountStatusBadgeComponent,
    ListInNewTabComponent,
  ],
  entryComponents: [
    ConfirmationDialogComponent,
    ImportCSVDialogComponent,
    CreateTagDialogComponent,
    ExportToCrmComponent,
    AddLeadsToCampaignComponent,
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        AppSessionService,
        HotReloadService,
        NodeDrawerService,
        InboxService,
        AddonFeaturesService,
        ProfileTagService,
        AppUrlService,
        AppAuthService,
        AppRouteGuard,
        LayoutStoreService,
        DateFormatPipe,
        AppAuthenticatedRouteGuard,
        ModularDialogComponent,
        IntegrationsService,
        LinkedinListsService,
        UserInterfaceService,
        CustomProxyService,
        ViewCampaignsService,
        WorkingHoursService,
        FractionToIntegerPipe,
        DigitsOnlyPipe,
      ],
    };
  }
}
