<mat-form-field class="w-full">
  <mat-select
    appearance="outline"
    placeholder="Choose list"
    (selectionChange)="onSelectedListChange()"
    [(ngModel)]="selectedList"
    msInfiniteScroll
    (infiniteScroll)="onScrolled()"
    [infiniteScrollThrottle]="500">
    <mat-option>
      <ngx-mat-select-search
        closeSvgIcon="close"
        placeholderLabel="Search or select list"
        #searchInput
        noEntriesFoundLabel=""
        [(ngModel)]="searchTerm"
        (ngModelChange)="searchTermUpdate.next(searchInput.value)"
        [disableScrollToActiveOnOptionsChanged]="true"
        [alwaysRestoreSelectedOptionsMulti]="false"></ngx-mat-select-search>
    </mat-option>
    <mat-option [value]="selectedList" *ngIf="selectedList">
      {{ selectedList?.name }}
    </mat-option>

    <mat-option *ngFor="let list of filteredLists" [value]="list">
      {{ list.name }}
    </mat-option>

    <div *ngIf="!fetchingFromApi && lists.length == 0" class="w-10/12 flex justify-start">
      <div class="ml-4 flex text-base">No lists found.</div>
    </div>

    <div *ngIf="fetchingFromApi" class="flex justify-center">
      <progress-bar [determinate]="false" class="mx-4"></progress-bar>
    </div>
  </mat-select>
</mat-form-field>
