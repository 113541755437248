<div
  *ngIf="hasDynamicFont"
  [ngStyle]="{ 'width.vw': size, 'height.vw': size }"
  class="relative min-image-list"
  [ngClass]="{ 'tiny-image': tiny }">
  <ng-container *ngIf="imageUrl">
    <img class="w-full h-full rounded-full object-cover" [src]="imageUrl" alt="Avatar" />
  </ng-container>
  <ng-container *ngIf="!imageUrl && !fullName && firstName && lastName">
    <div
      [ngStyle]="{ 'font-size.rem': fontSize }"
      class="min-text-list flex items-center justify-center w-full h-full rounded-full uppercase bg-gray-100 text-gray-600">
      {{ firstName.charAt(0) + lastName.charAt(0) }}
    </div>
  </ng-container>
  <ng-container *ngIf="!imageUrl && !fullName && !firstName && !lastName && emailAddress">
    <div
      [ngStyle]="{ 'font-size.rem': fontSize }"
      class="min-text-list flex items-center justify-center w-full h-full rounded-full uppercase bg-gray-100 text-gray-600">
      {{ emailAddress.charAt(0) }}
    </div>
  </ng-container>
  <ng-container *ngIf="!imageUrl && fullName">
    <div
      [ngStyle]="{ 'font-size.rem': fontSize }"
      class="min-text-list flex items-center justify-center w-full h-full rounded-full uppercase bg-gray-100 text-gray-600">
      {{ fullName.charAt(0) }}
    </div>
  </ng-container>
  <ng-container *ngIf="!imageUrl && !fullName && !firstName && !emailAddress">
    <img
      class="w-full h-full rounded-full object-cover"
      src="assets/images/avatars/avatar-empty.png"
      alt="Avatar" />
  </ng-container>
</div>

<div
  *ngIf="!hasDynamicFont"
  [ngStyle]="{ 'width.px': size, 'height.px': size }"
  class="relative min-image-list"
  [ngClass]="{ 'tiny-image': tiny }">
  <ng-container *ngIf="imageUrl">
    <img class="w-full h-full rounded-full object-cover" [src]="imageUrl" alt="Avatar" />
  </ng-container>
  <ng-container *ngIf="!imageUrl && !fullName && firstName && lastName">
    <div
      [ngStyle]="{ 'font-size.px': customFontSize }"
      class="min-text-list flex items-center justify-center w-full h-full rounded-full uppercase bg-gray-100 text-gray-600">
      {{ firstName.charAt(0) + lastName.charAt(0) }}
    </div>
  </ng-container>
  <ng-container *ngIf="!imageUrl && fullName">
    <div
      [ngStyle]="{ 'font-size.px': customFontSize }"
      class="min-text-list flex items-center justify-center w-full h-full rounded-full uppercase bg-gray-100 text-gray-600">
      {{ fullName.charAt(0) }}
    </div>
  </ng-container>
  <ng-container *ngIf="!imageUrl && !fullName && !firstName && !lastName && emailAddress">
    <div
      [ngStyle]="{ 'font-size.px': customFontSize }"
      class="min-text-list flex items-center justify-center w-full h-full rounded-full uppercase bg-gray-100 text-gray-600">
      {{ emailAddress.charAt(0) }}
    </div>
  </ng-container>
  <ng-container *ngIf="!imageUrl && !fullName && !firstName && !emailAddress">
    <img
      class="w-full h-full rounded-full object-cover"
      src="assets/images/avatars/avatar-empty.png"
      alt="Avatar" />
  </ng-container>
</div>
