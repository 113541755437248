<div class="flex flex-col items-center p-6 w-100">
  <button class="absolute top-0 right-0 m-4 text-gray-500 hover:text-gray-700" (click)="onClose()">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="h-6 w-6"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor">
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M6 18L18 6M6 6l12 12" />
    </svg>
  </button>
  <div
    *ngIf="data.isMessageHtml !== true; else messageAsHtml"
    class="text-center text-gray-900 font-semibold text-xl mb-6">
    {{ data.message }}
  </div>
  <ng-template #messageAsHtml>
    <div
      class="text-center text-gray-900 font-semibold text-xl mb-6"
      [innerHTML]="sanitizeHtml(data.message)"></div>
  </ng-template>

  <div class="text-center text-gray-600 text-lg mb-6">{{ data.description }}</div>
  <div *ngIf="showButtons" class="flex justify-center space-x-4">
    <button
      class="bg-white hover:bg-gray-300 text-gray-500 font-semibold py-2 px-4 border border-gray-300 hover:border-transparent rounded-lg"
      (click)="onCancel()">
      {{ data.cancelButtonText ?? 'Cancel' }}
    </button>
    <button
      class="bg-{{ confirmButtonColor }}-500 hover:bg-{{
        confirmButtonColor
      }}-600 text-white font-bold py-2 px-4 rounded-lg"
      (click)="onConfirm()">
      {{ data.confirmButtonText ?? 'Confirm' }}
    </button>
  </div>
</div>
