import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { accountModuleAnimation } from '@shared/animations/routerTransition';
import {
  AccountCompanyScrapeCompanyResponseDto,
  LinkedInAccountDto,
} from '@shared/service-proxies/service-proxies';

@Component({
  selector: 'linkedin-account-company-select',
  templateUrl: 'linkedin-account-company-select.component.html',
  animations: [accountModuleAnimation()],
})
export class LinkedInAccountCompanySelectComponent implements OnChanges {
  @Input() selectedAccount: LinkedInAccountDto;
  @Input() selectedUsername: string;
  @Input() selectedCompany: AccountCompanyScrapeCompanyResponseDto;
  @Output() selectedCompanyChange = new EventEmitter<AccountCompanyScrapeCompanyResponseDto>();
  @Input() disabled: boolean = false;

  companies: AccountCompanyScrapeCompanyResponseDto[] = [];
  isLoading: boolean = false;

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes['selectedAccount'] || this.selectedAccount == null) {
      return;
    }

    this.isLoading = true;
  }

  onSelectedCompany(): void {
    this.selectedCompanyChange.emit(this.selectedCompany);
  }
}
