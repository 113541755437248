import { Component, Input } from '@angular/core';
import { accountModuleAnimation } from '@shared/animations/routerTransition';
import { LinkedInCompanyDto } from '@shared/service-proxies/service-proxies';
@Component({
  selector: 'linkedin-company-display',
  templateUrl: './linkedin-company-display.component.html',
  animations: [accountModuleAnimation()],
})
export class LinkedinCompanyDisplayComponent {
  @Input() company: LinkedInCompanyDto;
}
