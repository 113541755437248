import { Component, EventEmitter, Input, Output } from '@angular/core';
import { searchTextMap } from '@app/linkedin-searching/getSearchTextMap';
import { WhiteLabelService } from '@app/white-label.service';
import { LinkedInSearchType } from '@shared/service-proxies/service-proxies';

@Component({
  selector: 'search-type-card',
  templateUrl: './search-type-card.component.html',
})
export class SearchTypeCardComponent {
  searchTextMap;
  searchImage: string = '';
  searchTitle: string = '';
  _searchType: LinkedInSearchType;
  get searchType(): LinkedInSearchType {
    return this._searchType;
  }
  @Input() set searchType(value: LinkedInSearchType) {
    this._searchType = value;
    const searchType = this.searchType.toString();
    this.searchImage = this.searchTextMap[searchType].logo;
    this.searchTitle = this.searchTextMap[searchType].title;
  }
  @Output() onCreateSearchClick = new EventEmitter<LinkedInSearchType>();
  @Input() selectedSearchType: LinkedInSearchType | null = null;

  /**
   *
   */
  constructor(private whiteLabelService: WhiteLabelService) {
    this.searchTextMap = searchTextMap(this.whiteLabelService.companyName);
  }
  createSearchClick() {
    this.onCreateSearchClick.emit(this.searchType);
  }
}
