<div>
  <mat-form-field class="w-full mb-0" appearance="fill">
    <mat-icon class="w-6 mr-2 text-gray-500 icon-size-5" [svgIcon]="defaultIcon"></mat-icon>
    <mat-select
      class="font-semibold"
      [placeholder]="placeholderLabel"
      [multiple]="true"
      [(ngModel)]="selectedOptions"
      (ngModelChange)="onSelectionsChange()"
      [disabled]="disabled">
      <mat-option>
        <ngx-mat-select-search
          [formControl]="optionsServerSideFilteringControl"
          placeholderLabel="Search"
          closeSvgIcon="close">
          <div ngxMatSelectNoEntriesFound></div>
        </ngx-mat-select-search>
      </mat-option>
      <div *ngIf="labelToDisplay !== 'NONE'">
        <div [ngSwitch]="labelToDisplay" class="px-2 text-base text-gray-400 my-2">
          <p *ngSwitchCase="'MIN_CHARACTERS'">
            Enter at least {{ searchInputMinimumCharacters }} characters to begin search.
          </p>
          <p *ngSwitchCase="'SEARCHING'">Searching...</p>
          <ng-container *ngSwitchCase="'NO_RESULTS'">
            <p *ngIf="searchTerm.length > 0">No results found.</p>
          </ng-container>
        </div>
      </div>
      <mat-option *ngFor="let option of optionsToShow" [value]="option">
        {{ option.label }}
      </mat-option>

      <mat-option *ngFor="let option of selectedOptions" [value]="option">
        {{ option.label }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div *ngFor="let option of selectedOptions" class="inline-block pr-2 mt-2">
  <mat-chip-listbox>
    <mat-chip-option
      class="border-none outline-none"
      color="primary"
      (removed)="onSelectionRemove(option)"
      [disabled]="disabled">
      {{ option.label }}
      <button matChipRemove>
        <mat-icon class="icon-size-4 inline align-middle" svgIcon="heroicons_solid:x"></mat-icon>
      </button>
    </mat-chip-option>
  </mat-chip-listbox>
</div>
