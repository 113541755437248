<div class="flex">
  <button heyreachButton buttonType="custom-border" (click)="toggleDrawer()">
    <mat-icon class="icon-size-4 mr-2" svgIcon="custom:configure"></mat-icon>
    <span>Edit Columns</span>
  </button>
  <div
    *ngIf="isDrawerOpen"
    class="fixed inset-y-0 right-0 w-90 bg-white shadow-lg z-50 overflow-y-auto max-h-screen transition-all duration-500 ease-in-out">
    <div class="p-4 h-full flex flex-col">
      <div class="my-2 ml-1 justify-between inline-flex">
        <p class="w-72 h-6 text-secondary text-xl ml-1 font-semibold">Edit columns</p>
        <button (click)="closeDrawer(false)">
          <mat-icon svgIcon="heroicons_outline:x"></mat-icon>
        </button>
      </div>
      <!-- Search -->
      <div class="bg-white rounded-lg p-2 space-y-2 max-w-xs transition-all">
        <app-search-field
          class="w-full flex items-center"
          (filter)="searchInputChanged($event)"
          [placeholder]="'Search keyword'"></app-search-field>
        <!-- Config Options -->
        <div
          class="flex items-center space-x-2"
          *ngFor="let tableColumn of tempTableColumns; let id = index; trackBy: trackByFn">
          <ng-container *ngIf="tableColumn.onSearchFilterActive">
            <div
              class="border border-secondary-muted rounded-lg p-2 w-full hover:bg-light"
              (dragover)="onDragOver($event)"
              (drop)="onDrop($event, id)">
              <div
                class="flex items-center justify-between"
                [draggable]="tableColumn.hidden ? 'true' : 'false'"
                (dragstart)="onDragStart($event, tableColumn)"
                (dragend)="onDragEnd($event, tableColumn)"
                [class.cursor-pointer]="tableColumn.hidden"
                [class.cursor-move]="!tableColumn.hidden">
                <mat-icon svgIcon="custom:drag"></mat-icon>
                <div class="flex items-center space-x-2">
                  <span class="text-secondary text-base font-semibold">
                    {{ tableColumn.header }}
                  </span>
                </div>
                <app-toggle-switcher
                  [hidden]="tableColumn.hidden"
                  [id]="tableColumn.id"
                  [required]="tableColumn.required"
                  (checkedChange)="onCheckboxChange($event)"></app-toggle-switcher>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="mt-auto p-4">
        <button
          heyreachButton
          buttonType="primary"
          (click)="handleSave()"
          data-cy="column-picker__save"
          class="w-full">
          Apply changes
        </button>
      </div>
    </div>
  </div>
</div>
