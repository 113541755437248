import {
  Directive,
  ElementRef,
  Injectable,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import {
  animationFrameScheduler,
  BehaviorSubject,
  combineLatest,
  switchMap,
  map,
  interval,
  takeWhile,
  endWith,
  distinctUntilChanged,
  takeUntil,
  Observable,
  ReplaySubject,
} from 'rxjs';

@Injectable()
export class Destroy extends Observable<void> implements OnDestroy {
  private readonly destroySubject$ = new ReplaySubject<void>(1);

  constructor() {
    super((subscriber) => this.destroySubject$.subscribe(subscriber));
  }

  ngOnDestroy(): void {
    this.destroySubject$.next();
    this.destroySubject$.complete();
  }
}

/**
 * Quadratic Ease-Out Function: f(x) = x * (2 - x)
 */
const easeOutQuad = (x: number): number => x * (2 - x);

@Directive({
  selector: '[appAnimatedNumber]',
  providers: [Destroy],
})
export class AnimatedNumberDirective implements OnInit {
  private readonly count$ = new BehaviorSubject(0);
  private readonly duration$ = new BehaviorSubject(500);
  private previousCount: number = 0;

  private readonly currentCount$ = combineLatest([this.count$, this.duration$]).pipe(
    switchMap(([count, duration]) => {
      // get the time when animation is triggered
      const startTime = animationFrameScheduler.now();

      return interval(0, animationFrameScheduler).pipe(
        // calculate elapsed time
        map(() => animationFrameScheduler.now() - startTime),
        // calculate progress
        map((elapsedTime) => elapsedTime / duration),
        // complete when progress is greater than 1
        takeWhile((progress) => progress <= 1),
        // apply quadratic ease-out
        // for faster start and slower end of counting
        map(easeOutQuad),
        // calculate current count
        map((progress) => Math.round(progress * count + (1 - progress) * this.previousCount)),
        // make sure that last emitted value is count
        endWith(count),
        distinctUntilChanged(),
      );
    }),
  );

  @Input('appAnimatedNumber')
  set count(count: number) {
    this.count$.next(count);
  }

  @Input()
  set duration(duration: number) {
    this.duration$.next(duration);
  }

  constructor(
    private readonly elementRef: ElementRef,
    private readonly renderer: Renderer2,
    private readonly destroy$: Destroy,
  ) {}

  ngOnInit(): void {
    this.displayCurrentCount();
  }

  private displayCurrentCount(): void {
    this.currentCount$.pipe(takeUntil(this.destroy$)).subscribe((currentCount) => {
      this.renderer.setProperty(this.elementRef.nativeElement, 'innerHTML', currentCount);
      if (currentCount === this.count$.value) {
        this.previousCount = currentCount;
      }
    });
  }
}
