import { Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { AlertType } from '../alert/alert.types';
import { themeAnimations } from '../../../shared/animations';

@Component({
  selector: 'inline-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: themeAnimations,
})
export class AlertComponent {
  @Input() type: AlertType = 'error';

  color: 'primary' | 'warn' = 'primary';

  constructor() {
    switch (this.type) {
      case 'info':
      case 'success':
        this.color = 'primary';
        break;
      case 'warning':
      case 'error':
        this.color = 'warn';
        break;
    }
  }

  @HostBinding('class') get classList(): any {
    return {
      'alert-type-info': this.type === 'info' || this.type === 'success',
      'alert-type-warning': this.type === 'warning' || this.type === 'error',
    };
  }
}
