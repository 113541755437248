import { Component, EventEmitter, Input, Output } from '@angular/core';
@Component({
  selector: 'drawer-header',
  templateUrl: './drawer-header.component.html',
})
export class DrawerHeaderComponent {
  @Input() title: string = '';
  @Output() close: EventEmitter<void> = new EventEmitter<void>();

  onCloseButtonClick(): void {
    this.close.emit();
  }
}
