<mat-form-field class="w-full">
  <mat-select
    placeholder="Search..."
    [(ngModel)]="selectedCompany"
    (ngModelChange)="onSelectedCompany()">
    <!-- place ngx-mat-select-search inside mat-option to allow opening without initial selection -->
    <mat-option>
      <ngx-mat-select-search
        ngModel
        (ngModelChange)="onSearchInputChange($event)"
        placeholderLabel="Start typing to search for your company..."
        closeSvgIcon="close"
        noEntriesFoundLabel=""></ngx-mat-select-search>
    </mat-option>
    <div *ngIf="!searching; else searchingDisplay">
      <mat-option *ngFor="let company of companies" [value]="company">
        <div class="flex">
          <div class="inline-block w-9/12">{{ company?.title }}</div>
          <div
            class="inline-block justify-self-end ml-auto mt-auto mb-auto"
            *ngIf="company?.imgUrl">
            <img src="{{ company.imgUrl }}" class="icon-size-7" />
          </div>
        </div>
      </mat-option>
    </div>
    <ng-template #searchingDisplay>
      <span class="ml-4">Searching...</span>
    </ng-template>
    <div *ngIf="showNoResultsLabel">
      <span class="ml-4">No results</span>
    </div>
  </mat-select>
</mat-form-field>
