import { Component, Input } from '@angular/core';

@Component({
  selector: 'progress-spinner',
  templateUrl: 'progress-spinner.component.html',
})
export class ProgressSpinnerComponent {
  @Input() diameter: number = 8;
  @Input() color: 'primary' | 'white' | 'secondary' | 'warn' = 'white';

  constructor() {}

  public get spinnerColorClass(): string {
    return `text-${this.color}`;
  }

  public get spinnerSizeClass(): string {
    return `h-${this.diameter} w-${this.diameter}`;
  }
}
