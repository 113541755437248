import { Component, EventEmitter, Input, Output } from '@angular/core';
import { appModuleAnimation } from '@shared/animations/routerTransition';

@Component({
  selector: 'paginator',
  templateUrl: './paginator.component.html',
  animations: [appModuleAnimation()],
})
export class PaginatorComponent {
  @Input() pageNumber: number = 0;
  @Output() pageNumberChange = new EventEmitter<number>();
  @Input() total: number = 0;
  @Input() resultsPerPage: number = 0;

  get isNextButtonDisabled(): boolean {
    if (!this.resultsPerPage) return true;

    const totalPages = Math.ceil(this.total / this.resultsPerPage);

    return totalPages == 0 || this.pageNumber === totalPages - 1;
  }

  get isPrevButtonDisabled(): boolean {
    return this.pageNumber == 0;
  }

  prevButtonClick(): void {
    this.pageNumber--;
    this.pageNumberChange.emit(this.pageNumber);
  }

  nextButtonClick(): void {
    this.pageNumber++;
    this.pageNumberChange.emit(this.pageNumber);
  }
}
