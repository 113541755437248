import { Component, Input } from '@angular/core';
import { appModuleAnimation } from '@shared/animations/routerTransition';

@Component({
  selector: 'page-content-container',
  templateUrl: './page-content-container.component.html',
  animations: [appModuleAnimation()],
})
export class PageContentContainerComponent {
  // Only for full-screen pages
  @Input() applyTopPadding: boolean = true;
}
