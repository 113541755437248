import { KeyValue } from '@angular/common';
import { Component, Input } from '@angular/core';
import { CustomUserFieldDto, IImportProfileDto } from '@shared/service-proxies/service-proxies';

@Component({
  selector: 'import-mapping',
  templateUrl: './import-mapping.component.html',
  // styleUrls: ['./import-csv.component.scss'],
})
export class ImportMappingComponent {
  @Input() CSVFields: string[] = [];
  @Input() fieldMapping: IImportProfileDto;

  requiredFieldNames: string[] = ['LinkedIn Profile Url', 'First Name', 'Last Name'];

  fieldNames: IImportProfileDto = {
    profileUrl: 'LinkedIn Profile Url',
    firstName: 'First Name',
    lastName: 'Last Name',
    location: 'Location',
    summary: 'Headline',
    companyName: 'Company Name',
    position: 'Title',
    about: 'About',
    emailAddress: 'Email Address',
    customUserFields: undefined,
  };

  get sortedKeyValFieldMapping(): string[] {
    return Object.keys(this.fieldMapping);
  }

  isFieldRequired(fieldName: string): boolean {
    return this.requiredFieldNames.includes(fieldName);
  }
}
