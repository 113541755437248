<div
  class="flex flex-auto items-center h-11 rounded-lg border-2 px-4 py-2 border-pale bg-gray-50"
  [ngClass]="{ 'bg-gray-200 border-gray-200': disabled }">
  <mat-icon
    class="icon-size-5 text-gray-300 mr-2"
    [ngClass]="{ 'bg-gray-200': disabled }"
    svgIcon="custom:search-default"></mat-icon>
  <input
    [attr.type]="type ? type : 'text'"
    [attr.id]="id ? type : null"
    type="text"
    #searchField
    [placeholder]="placeholder"
    class="form-control w-full border-none bg-gray-50 placeholder-gray-300"
    [class.form-control-sm]="isSmall"
    [attr.aria-label]="ariaLabel"
    [formControl]="control"
    data-cy="search-field__input"
    [ngClass]="{ 'bg-gray-200': disabled }"
    [attr.disabled]="disabled ? disabled : null" />
</div>
