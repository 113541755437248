<div class="w-56 ml-6 mr-0 bg-white rounded-xl pb-1 min-h-full">
  <div class="flex flex-col overflow-y-auto flex-auto" [style.height.px]="calculatedHeightPx">
    <div
      class="px-4 py-2 text-base flex items-center border-l-2 cursor-pointer border-transparent text-primary opacity-90 font-bold mt-7 mb-2"
      [class.text-secondary.opacity-90]="!isActiveLink('update-password')"
      [class.hover:opacity-100]="!isActiveLink('integrations')"
      [class.border-primary]="isActiveLink('update-password')"
      [class.border-box]="isActiveLink('update-password')"
      [routerLink]="getSideBarMenuUrls('updatePassword')">
      <mat-icon class="icon-size-5 mr-2" svgIcon="custom:settings"></mat-icon>
      Profile Settings
    </div>
    <div
      class="px-4 py-2 text-base flex text-primary border-l-2 cursor-pointer border-transparent opacity-90 font-bold mb-2"
      [class.text-secondary.opacity-90]="!isActiveLink('invitations')"
      [class.hover:opacity-100]="!isActiveLink('integrations')"
      [class.border-primary]="isActiveLink('invitations')"
      [class.border-box]="isActiveLink('invitations')"
      [routerLink]="getSideBarMenuUrls('invitations')">
      <mat-icon svgIcon="custom:teams" class="icon-size-5 mr-2"></mat-icon>
      Team Members
    </div>
    <div
      class="px-4 py-2 text-base flex text-primary border-l-2 cursor-pointer border-transparent opacity-90 font-bold mb-2"
      *ngIf="!hideBilling"
      [class.text-secondary.opacity-90]="!isActiveLink('billing')"
      [class.hover:opacity-100]="!isActiveLink('integrations')"
      [class.border-primary]="isActiveLink('billing')"
      [class.border-box]="isActiveLink('billing')"
      [routerLink]="getSideBarMenuUrls('billing')">
      <mat-icon class="icon-size-5 mr-2" svgIcon="custom:card-tick"></mat-icon>
      Billing
    </div>
    <div
      class="px-4 py-2 text-base flex text-primary border-l-2 cursor-pointer border-transparent opacity-90 font-bold mb-2"
      [class.text-secondary.opacity-90]="!isActiveLink('integrations')"
      [class.hover:opacity-100]="!isActiveLink('integrations')"
      [class.border-primary]="isActiveLink('integrations')"
      [class.border-box]="isActiveLink('integrations')"
      [routerLink]="getSideBarMenuUrls('integrations')">
      <mat-icon svgIcon="custom:integrations" class="icon-size-5 mr-2"></mat-icon>
      Integrations
    </div>
  </div>
</div>
