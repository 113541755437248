import { Component, Input } from '@angular/core';

@Component({
  selector: 'table-row-field',
  templateUrl: './table-row-field.component.html',
  styleUrls: ['./table-row-field.component.scss'],
})
export class TableRowFieldComponent {
  @Input() text: string;
  @Input() width: string = '10rem';
  @Input() enriched: boolean = false;

  constructor() {}
}
