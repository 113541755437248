export function PluralOnNumber(input: number): string {
  return input === 1 ? '' : 's';
}

export function PickTextColorBasedOnBgColorSimple(
  bgColor,
  lightColor = '#FFFFFF',
  darkColor = '#000000',
) {
  var color = bgColor.charAt(0) === '#' ? bgColor.substring(1, 7) : bgColor;
  var r = parseInt(color.substring(0, 2), 16); // hexToR
  var g = parseInt(color.substring(2, 4), 16); // hexToG
  var b = parseInt(color.substring(4, 6), 16); // hexToB
  return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? darkColor : lightColor;
}

export function getRandomColor(randomColorPossibilities) {
  const randomIndex = Math.floor(Math.random() * randomColorPossibilities.length);
  return randomColorPossibilities[randomIndex];
}

export function ResolveUpperCasesInMainUrl(searchUrl: string, splitCharacter: string): string {
  let splitUrl = searchUrl.split(splitCharacter);
  splitUrl[0] = splitUrl[0].toLowerCase();
  return splitUrl.join(splitCharacter);
}

export function CheckIfStringIsUrl(value: string): boolean {
  return new RegExp(
    '([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?',
  ).test(value);
}

export function isUniqueObject(a: any, b: any): boolean {
  return JSON.stringify(a) === JSON.stringify(b);
}
