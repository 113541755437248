import { Component, OnInit, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'custom-template-tooltip',
  templateUrl: './custom-template-tooltip.component.html',
})
export class CustomTemplateTooltipComponent {
  @Input() contentTemplate: TemplateRef<any>;
  @Input() tooltipData: any;
  constructor() {}
}
