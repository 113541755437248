import { Component, Input } from '@angular/core';

@Component({
  selector: 'info-card-display',
  templateUrl: './info-card-display.component.html',
})
export class InfoCardDisplayComponent {
  @Input() title: string;
  @Input() summary: string;
  @Input() hasBottomSpace: boolean = true;
}
