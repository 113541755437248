import { ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'tutorial-component',
  templateUrl: 'tutorial.component.html',
  styleUrls: ['tutorial.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TutorialComponent {
  @Input() title = 'Tutorial';
  @Input() header = '';
  @Input() steps: TutorialStep[] = [];

  @ViewChild('horizontalStepper', { static: false }) stepper: MatStepper;

  constructor(private sanitizer: DomSanitizer) {}

  goBack() {
    this.stepper.previous();
  }

  goForwards() {
    this.stepper.next();
  }

  getSafeBody(body: string) {
    return this.sanitizer.bypassSecurityTrustHtml(body);
  }

  getSafeUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}

export interface TutorialStep {
  title: string;
  body: string;
  image?: TutorialImage;
  embed?: string;
}

export interface TutorialImage {
  src: string;
  width?: number;
  height?: number;
}

export interface TutorialEmbed {
  src: string;
  width?: number;
  height?: number;
}
