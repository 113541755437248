import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { CodeInputComponent } from 'angular-code-input';

@Component({
  selector: 'security-code',
  templateUrl: './security-code.component.html',
  styleUrls: ['security-code.component.scss'],
})
export class SecurityCodeComponent {
  @Input() pinCode: string = '';
  @Output() pinCodeChange = new EventEmitter<string>();
  @Input() disabled: boolean = false;
  @Input() applyRedBorder: boolean = false;
  @ViewChild('codeInput') codeInput!: CodeInputComponent;

  onCodeChange(newCode: string) {
    this.pinCode = newCode;
    this.pinCodeChange.emit(this.pinCode);
  }

  resetPin(): void {
    this.codeInput.reset();
  }
}
