import { Pipe, PipeTransform } from '@angular/core';
import { AppCampaignStatus } from '@shared/AppEnums';
import { StatusBadgeStatusType } from '@shared/components/user-interface/status-badge/status-badge.component';

@Pipe({
  name: 'campaignStatusColor',
})
export class CampaignStatusColorPipe implements PipeTransform {
  transform(status: AppCampaignStatus): StatusBadgeStatusType {
    return {
      [AppCampaignStatus.DRAFT]: 'default',
      [AppCampaignStatus.LOCKED]: 'warning',
      [AppCampaignStatus.PAUSED]: 'warning',
      [AppCampaignStatus.CANCELED]: 'danger',
      [AppCampaignStatus.FAILED]: 'danger',
      [AppCampaignStatus.FINISHED]: 'success',
      [AppCampaignStatus.IN_PROGRESS]: 'progress',
      [AppCampaignStatus.IN_PROGRESS_TRAILING]: 'progress',
      [AppCampaignStatus.STARTING]: 'progress',
    }[status as string];
  }
}
