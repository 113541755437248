import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WhiteLabelService } from '@app/white-label.service';

@Component({
  selector: 'app-root',
  template: `
    <router-outlet></router-outlet>
  `,
})
export class RootComponent {
  constructor(
    private _route: ActivatedRoute,
    private whiteLabelService: WhiteLabelService,
  ) {
    this.whiteLabelService.applyTheme();
    this._route.queryParams.subscribe((params) => {
      let selectedPlanQuantity = params['selectedPlanQuantity'];
      let selectedPlanTrial = params['selectedPlanTrial'];

      if (selectedPlanQuantity) {
        localStorage.setItem('selectedPlanQuantity', parseInt(selectedPlanQuantity).toString());
        localStorage.setItem('immediatelyPurchase', 'true');
      }

      if (selectedPlanTrial) {
        localStorage.setItem('selectedPlanTrial', selectedPlanTrial);
        localStorage.setItem('immediatelyPurchase', 'true');
      }
    });
  }
}
