import { EventEmitter, Injectable } from '@angular/core';
import { HubConnection } from '@aspnet/signalr';
import { AppMessageType } from '@shared/AppEnums';
import {
  ConversationDto,
  ConversationLinkedInProfileIdDto,
  ConversationMessagesResponseDto,
  CrmExportLeadProperties,
  ExportCrmInboxContactsDto,
  GetAllChatRoomsInputDto,
  InboxConversationDto,
  InboxConversationDtoPagedResultDto,
  InboxFiltersDto,
  InboxServiceProxy,
  LinkedInAccountDto,
  LinkedInAccountServiceProxy,
  LinkedInProfileTagDto,
  LnkLinkedInProfileTagDto,
  SendMessageDto,
} from '@shared/service-proxies/service-proxies';
import { PermissionCheckerService } from 'abp-ng2-module';
import { Dictionary } from 'lodash';
import * as moment from 'moment';
import { Observable, of, Subscription, timer } from 'rxjs';
import { ChatDetails } from './inbox.types';
import { BehaviorSubject, EMPTY } from '@node_modules/rxjs';
import { Router } from '@node_modules/@angular/router';
import { concatMap, filter, finalize, map, switchMap, take, tap } from 'rxjs/operators';
import { NavigationEnd } from '@angular/router';
import { InterceptorDisabler } from '@shared/interceptors/disable-interceptor';
import { HasFiltersPipe } from '@app/inbox/pipes/has-filters.pipe';
import { AddLeadsFromMemberIdsOrInboxMode } from '@shared/components/dialogs/add-leads-to-inbox/add-leads-from-member-ids-or-inbox-dialog.component';

export const DEFAULT_FILTERS = {
  linkedInAccountIds: [],
  campaignIds: [],
  tagIds: [],
  searchString: '',
  conversationType: undefined,
  notReplied: undefined,
  seen: undefined,
  sortBy: 0,
};
@Injectable()
export class InboxService {
  public onInboxChange = new EventEmitter();
  public initialized: boolean = false;
  public inboxHub: HubConnection;
  enabled: boolean;
  public totalChatRooms: number = 0;
  //TODO: Why do we have this chat details here? (Kiril)
  public chatDetails: Dictionary<ChatDetails> = {};
  public limit: number = 20;
  // Filters
  public filters: InboxFiltersDto = new InboxFiltersDto(DEFAULT_FILTERS);
  private loading: boolean = false;
  private page: number = 0;
  private new: number = 0;
  private sortBy: number = 0;
  private readonly conversationMessagesSubject$ =
    new BehaviorSubject<ConversationMessagesResponseDto>(new ConversationMessagesResponseDto());
  public readonly conversationMessages$ = this.conversationMessagesSubject$.asObservable();

  private readonly totalChatRooms$$ = new BehaviorSubject<number>(null);
  public readonly totalChatRooms$ = this.totalChatRooms$$.asObservable();
  // TODO: Why is everything of type InboxConversationDto????
  private readonly chatRoomsSubject$ = new BehaviorSubject<InboxConversationDto[]>([]);
  public readonly chatRooms$: Observable<InboxConversationDto[]> =
    this.chatRoomsSubject$.asObservable();
  private readonly initialLoadingSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false,
  );
  public readonly initialLoading$: Observable<boolean> = this.initialLoadingSubject$.asObservable();
  private readonly loadingNextPageSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false,
  );
  public readonly loadingNextPage$: Observable<boolean> =
    this.loadingNextPageSubject$.asObservable();
  private readonly searchTerm$$ = new BehaviorSubject<string>(null);
  public readonly searchTerm$ = this.searchTerm$$.asObservable();
  private unreadMessages$$ = new BehaviorSubject<number>(0);
  public unreadMessages$ = this.unreadMessages$$.asObservable();

  private isManuallyReloadingFromLinkedinSubject$ = new BehaviorSubject<boolean>(false);
  public isManuallyReloadingFromLinkedin$ =
    this.isManuallyReloadingFromLinkedinSubject$.asObservable();

  private checkIfMessageIsSentSubscription?: Subscription;

  private readonly selectedChatSubject$ = new BehaviorSubject<InboxConversationDto>(undefined);
  public readonly selectedChat$ = this.selectedChatSubject$.asObservable();
  private readonly selectedChatDetailsSubject$ = new BehaviorSubject<ChatDetails>(null);
  public readonly selectedChatDetails$ = this.selectedChatDetailsSubject$.asObservable();

  private readonly chatIsLoadingSubject$ = new BehaviorSubject<boolean>(false);
  public readonly chatIsLoading$ = this.chatIsLoadingSubject$.asObservable();
  public readonly selectedAll$;
  public readonly accounts$: Observable<LinkedInAccountDto[]>;
  private selectedChatsCount: number = 0;
  private readonly selectedChats$$ = new BehaviorSubject<InboxConversationDto[]>([]);
  public readonly selectedChats$ = this.selectedChats$$.asObservable();
  private readonly selectedAll$$ = new BehaviorSubject<boolean>(false);
  public readonly selectedChatsCount$ = this.selectedChats$.pipe(
    map((selectedChats) => selectedChats.length),
    tap((selectedChatsCount) => {
      this.selectedChatsCount = selectedChatsCount;
      if (this.selectedChatsCount === 0) {
        this.selectedAll$$.next(false);
      }
    }),
  );

  constructor(
    private _inboxService: InboxServiceProxy,
    private _permissionChecker: PermissionCheckerService,
    private _router: Router,
    private interceptorDisabler: InterceptorDisabler,
    private _accountsService: LinkedInAccountServiceProxy,
  ) {
    this.enabled = this._permissionChecker.isGranted('Pages.Inbox');
    // DEBUG
    this.enabled = true;

    if (this.enabled) {
      this.refresh().subscribe();
    }

    this.accounts$ = this._accountsService
      .getLinkedInAccountsForTennant()
      .pipe(
        map((linkedinAccounts) =>
          linkedinAccounts.filter((linkedinAccount) => linkedinAccount.authIsValid),
        ),
      );

    this.selectedAll$ = this.selectedAll$$.asObservable();

    _router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(async (event) => {
        const e = event as NavigationEnd;
        if (e.url.startsWith('/app/inbox')) {
          // get preselected chatroom
          const accountId = e.url.split('/')[3];
          const conversationId = decodeURIComponent(e.url.split('/')[4]);

          if (!accountId || !conversationId) {
            this.selectedChatSubject$.next(null);
            return;
          }

          const chat: InboxConversationDto = await this.getChat(
            parseInt(accountId),
            conversationId,
          );

          if (!chat) {
            this.selectedChatSubject$.next(null);
            void this._router.navigateByUrl('app/inbox');
            return;
          }

          this.selectedChatSubject$.next({ ...chat } as InboxConversationDto);
          this.chatIsLoadingSubject$.next(true);
          await this.getMessages(chat.accountId, chat.id);
          this.chatIsLoadingSubject$.next(false);
          this.selectedChatDetailsSubject$.next({
            ...this.chatDetails[chat?.id + chat?.accountId],
          } as ChatDetails);

          const conversationMessages = this.conversationMessagesSubject$.value;
          chat.lastMessageSuccess =
            conversationMessages.messages[conversationMessages.messages.length - 1].success;
        }
      });

    this.selectedChat$.subscribe((selectedChatRoom: InboxConversationDto) => {
      if (
        !selectedChatRoom ||
        (selectedChatRoom.read && selectedChatRoom.unreadMessageCount == 0)
      ) {
        return;
      }

      this.unreadMessages$$.next(this.unreadMessages$$.value - 1);
      this.interceptorDisabler.disable();
      this._inboxService.setSeenStatus(selectedChatRoom.accountId, selectedChatRoom.id).subscribe();
      this.selectedChatSubject$.next({
        ...selectedChatRoom,
        read: true,
        unreadMessageCount: 0,
      } as InboxConversationDto);
    });
  }

  public set chatRooms(selectedChats: InboxConversationDto[]) {
    this.chatRoomsSubject$.next([
      ...selectedChats.map(
        (chat) =>
          ({
            ...chat,
            linkedInAccount: {
              ...chat.linkedInAccount,
            },
            correspondentProfile: {
              tagLinks: {
                ...chat.correspondentProfile?.tagLinks,
              },
              ...chat.correspondentProfile,
            },
            ...chat,
          }) as InboxConversationDto,
      ),
    ]);
  }

  updateFilters(filters: Partial<InboxFiltersDto>): void {
    this.filters = new InboxFiltersDto({
      ...this.filters,
      ...filters,
    });
  }

  public getCurrentChatRooms = () => this.chatRoomsSubject$.value;

  public readonly selectedChatValue = () => this.selectedChatSubject$.value;

  public updateChat(chat: Partial<InboxConversationDto>) {
    const oldValue = this.selectedChatSubject$.value;
    const newValue = {
      ...oldValue,
      linkedInAccount: {
        ...chat.linkedInAccount,
      },
      correspondentProfile: {
        tagLinks: {
          ...chat.correspondentProfile.tagLinks,
        },
        ...chat.correspondentProfile,
      },
      ...chat,
    };
    this.selectedChatSubject$.next(newValue as InboxConversationDto);
  }

  public readonly chatRoomsValue = () => this.chatRoomsSubject$.value;
  public readonly selectedChatDetailsValue = () => this.selectedChatDetailsSubject$.value;

  public async clearChatrooms() {
    this.chatRoomsSubject$.next([]);
    this.initialLoadingSubject$.next(true);
    this.page = 0;
    this.new = 0;
    this.refresh().subscribe(() => {
      this.initialLoadingSubject$.next(false);
    });
  }

  public setSeenStatusOnSelectedChat() {
    this._inboxService
      .setSeenStatus(this.selectedChatSubject$.value.accountId, this.selectedChatSubject$.value.id)
      .subscribe();
  }

  public updateTag(tag: LinkedInProfileTagDto) {
    if (tag) {
      const chatRoomsUpdated: InboxConversationDto[] = this.chatRoomsValue().map((chatRoom) =>
        this.updateTagOnChat(tag, chatRoom),
      );
      this.chatRoomsSubject$.next(chatRoomsUpdated);

      const updateSelectedChatTag = this.updateTagOnChat(tag, this.selectedChatSubject$.value);

      this.selectedChatSubject$.next(updateSelectedChatTag);
    }
  }

  public assignTagsToChatrooms(tags: LnkLinkedInProfileTagDto[]) {
    tags.forEach((x) => {
      const chatrooms = this.chatRoomsValue().filter((z) => z.correspondentMemberId == x.profileId);

      chatrooms.forEach((chatroom) => {
        if (!chatroom?.correspondentProfile?.tagLinks?.includes(x)) {
          chatroom?.correspondentProfile?.tagLinks?.push(x);
        }
      });
    });
  }

  public unAssignTagFromAllChatrooms(tagId: number) {
    this.chatRoomsValue()
      .filter((x) => x.correspondentProfile?.tagLinks.find((z) => z.profileTagId == tagId))
      .forEach((x) => {
        x.correspondentProfile.tagLinks = x.correspondentProfile?.tagLinks.filter(
          (z) => z.profileTagId != tagId,
        );
      });
  }

  public unAssignTagFromSpecificChatrooms(tagId: number, conversations: InboxConversationDto[]) {
    this.chatRoomsValue()
      .filter((x) => conversations.find((z) => z.accountId == x.accountId && z.id == x.id))
      .filter((x) => x.correspondentProfile?.tagLinks.find((z) => z.profileTagId == tagId))
      .forEach((x) => {
        x.correspondentProfile.tagLinks = x.correspondentProfile?.tagLinks.filter(
          (z) => z.profileTagId != tagId,
        );
      });
  }

  unAssignTagForProfiles(tagId: number, profileIds: string[]) {
    const chatrooms = this.chatRoomsValue().filter((z) =>
      profileIds.includes(z.correspondentMemberId),
    );

    chatrooms.forEach((chatroom) => {
      chatroom.correspondentProfile.tagLinks = chatroom.correspondentProfile?.tagLinks.filter(
        (z) => z.profileTagId != tagId,
      );
    });
  }

  // Public methods
  public async loadNextPage() {
    this.loadingNextPageSubject$.next(true);
    this.page++;
    this.refreshMessages().subscribe(() => this.loadingNextPageSubject$.next(false));
  }

  public refresh(): Observable<null> {
    // Get messages from db and stuff
    return this.refreshMessages().pipe(
      concatMap(() => {
        this.getTotalUnreadMessages();
        return of(null);
      }),
    );
  }

  public getTotalUnreadMessages() {
    const input = new GetAllChatRoomsInputDto();
    input.offset = this.limit * this.page;
    input.limit = this.limit;
    input.filters = new InboxFiltersDto(this.filters);

    this._inboxService.countUnseenChatRoomsV2(input).subscribe((numberOfUnreadMessages) => {
      this.unreadMessages$$.next(numberOfUnreadMessages);
    });
  }

  public refreshMessages(): Observable<void | InboxConversationDtoPagedResultDto> {
    if (this.loading) {
      return EMPTY;
    } else {
      this.loading = true;
      const input = new GetAllChatRoomsInputDto();
      input.offset = this.limit * this.page; // - this.seen + this.new;
      input.limit = this.limit;
      input.filters = new InboxFiltersDto(this.filters);

      return this._inboxService.getChatrooms(input).pipe(
        tap((result) => {
          if (!result) {
            return;
          }
          this.totalChatRooms = result.totalCount;
          this.totalChatRooms$$.next(result.totalCount);

          if (this.selectedAll) {
            const oldSelectedChats = this.selectedChats$$.value;
            this.selectedChats$$.next([...oldSelectedChats, ...result.items]);
          }

          let totalExistingChats = 0;

          // TODO: This is done because there was a bug that doesn't filter the chat rooms when searching so This checks if the total items is
          // less then the previous chatRoomsItemsLegnth Meaning that filters are being applied and they are being reduced
          const chatRooms =
            result.totalCount < this.chatRoomsValue().length ? [] : this.chatRoomsValue();

          // concat only those that are not existing
          result.items.forEach((chatRoom) => {
            // find existing chatroom by id
            const existing = this.getChatRoomById(chatRoom.accountId, chatRoom.id);
            if (existing) {
              totalExistingChats++;
            } else {
              chatRooms.push(chatRoom);
              this.afterAddedChatRoom(chatRoom);
            }
          });
          this.chatRoomsSubject$.next([...chatRooms]);

          this.initialized = true;
        }),
        finalize(() => {
          this.loading = false;
        }),
      );
    }
  }

  upsertChatRooms(
    chatRooms: { conversation: InboxConversationDto; messages: ConversationMessagesResponseDto }[],
  ) {
    if (!this.enabled) {
      return;
    }

    let newMessages = 0;
    chatRooms.forEach((chatRoom) => {
      const conversation: InboxConversationDto = chatRoom.conversation;
      // check for unread messages
      newMessages += conversation.unreadMessageCount;

      // find existing chatroom by id
      let existing = this.getChatRoomById(conversation.accountId, conversation.id);

      if (existing) {
        existing = {
          ...conversation,
          lastCorrespondentSeenAt: moment(conversation.lastCorrespondentSeenAt),
          lastActivityAt: moment(conversation.lastActivityAt),
          lastMessageAt: moment(conversation.lastMessageAt),
          lastReadAt: moment(conversation.lastReadAt),
        } as InboxConversationDto;

        this.chatRoomsSubject$.next([
          existing,
          ...this.chatRoomsValue().filter((_chatRoom) => _chatRoom.id !== existing.id),
        ]);
        this.afterAddedChatRoom(existing);
      } else {
        // FILTER

        if (!this.matchFilters(conversation)) {
          return;
        }

        // FILTER

        if (this.chatRoomsValue().length > 20 && conversation.read) {
          return;
        }

        conversation.lastActivityAt = moment(conversation.lastActivityAt);
        conversation.lastCorrespondentSeenAt = moment(conversation.lastCorrespondentSeenAt);
        conversation.lastMessageAt = moment(conversation.lastMessageAt);
        conversation.lastReadAt = moment(conversation.lastReadAt);

        // create new chatroom
        this.chatRoomsSubject$.next([conversation, ...this.chatRoomsSubject$.value]);
        this.afterAddedChatRoom(conversation);
        this.new++;
      }

      if (this.selectedChatSubject$.value?.id == chatRoom.conversation.id) {
        chatRoom.messages.messages = chatRoom.messages.messages.map((x) => {
          return {
            ...x,
            createdAt: moment(x.createdAt),
          } as ConversationDto;
        });
        this.conversationMessagesSubject$.next({
          ...chatRoom.messages,
        } as ConversationMessagesResponseDto);
      }
    });

    if (newMessages > 0) {
      this.unreadMessages$$.next(this.unreadMessages$$.value + newMessages);
    }
  }

  async getChat(accountId: number, chatRoomId: string): Promise<InboxConversationDto> {
    const existing: InboxConversationDto = this.getChatRoomById(accountId, chatRoomId);
    if (existing) {
      return existing;
    } else {
      const chatRoom: InboxConversationDto = await this._inboxService
        .getChatroom(accountId, chatRoomId)
        .toPromise();
      if (chatRoom && chatRoom.id) {
        this.chatRoomsSubject$.next([chatRoom, ...this.chatRoomsValue()]);
        this.afterAddedChatRoom(chatRoom);
        return chatRoom;
      }
    }
  }

  afterAddedChatRoom(chatRoom: InboxConversationDto) {
    if (!this.chatDetails[chatRoom.id + chatRoom.accountId]) {
      this.chatDetails[chatRoom.id + chatRoom.accountId] = {
        message: '',
      } as ChatDetails;
    }
  }

  async getMessages(accountId: number, chatRoomId: string): Promise<void> {
    const chat: ConversationMessagesResponseDto = await this._inboxService
      .getConversation(accountId, chatRoomId)
      .toPromise();
    this.conversationMessagesSubject$.next(chat);
  }

  getLatestMessagesFromLinkedin(accountId: number, chatRoomId: string) {
    this.isManuallyReloadingFromLinkedinSubject$.next(true);
    this._inboxService
      .getLiveConversation(accountId, chatRoomId)
      .pipe(finalize(() => this.isManuallyReloadingFromLinkedinSubject$.next(false)))
      .subscribe((chat: ConversationMessagesResponseDto) => {
        if (!chat?.messages || chat.messages.length === 0) {
          return;
        }

        const currentMessages = this.conversationMessagesSubject$.value;
        const updatedMessages = chat.messages.sort((a, b) =>
          a.createdAt.isBefore(b.createdAt) ? -1 : 1,
        );
        updatedMessages.forEach((message) => {
          const existingMessage = currentMessages.messages.find(
            (m) => m.messageId === message.messageId,
          );
          if (existingMessage) {
            existingMessage.subject = message.subject;
            existingMessage.body = message.body;
            // It's from LinkedIn, so if it's there, it is successful
            existingMessage.success = true;
          } else {
            message.success = true;
            currentMessages.messages.push(message);
          }
        });
        const lastMessage = currentMessages.messages[currentMessages.messages.length - 1];
        const currentSelectedChat = this.selectedChatSubject$.value;
        currentSelectedChat.lastMessageText = lastMessage.body;
        currentSelectedChat.lastMessageAt = lastMessage.createdAt;
        currentSelectedChat.lastMessageSender = lastMessage.sender;
        currentSelectedChat.lastMessageType = lastMessage.messageType;
        currentSelectedChat.lastMessageSuccess = true;
        this.conversationMessagesSubject$.next(currentMessages);
        this.selectedChatSubject$.next(currentSelectedChat);
        this.selectedChatSubject$.next({ ...currentSelectedChat } as InboxConversationDto);
        this.chatRoomsSubject$.next([
          currentSelectedChat,
          ...this.chatRoomsValue().filter((chatRoom) => chatRoom.id !== currentSelectedChat.id),
        ]);
      });
  }

  public sendMessage(chatDetails: ChatDetails): Observable<boolean> {
    if (!this.enabled) {
      return;
    }

    const selectedChatRoom: InboxConversationDto = this.selectedChatSubject$.value;
    if (selectedChatRoom == undefined) {
      return of(false);
    }

    // validate message
    chatDetails.message = chatDetails.message.trim();
    if (
      (chatDetails.message == null || chatDetails.message === '') &&
      chatDetails.file == undefined
    ) {
      return of(false);
    }

    // send message request
    const textMessage = new SendMessageDto({
      accountId: selectedChatRoom.accountId,
      memberId: selectedChatRoom.correspondentMemberId,
      message: chatDetails.message,
      subject: undefined,
      messageId: undefined,
      nodePath: undefined,
      sequenceId: undefined,
      conversationId: selectedChatRoom.id,
    } as SendMessageDto);

    this.checkIfMessageIsSentSubscription?.unsubscribe();
    this.checkIfMessageIsSentSubscription = undefined;

    let latestMessage = new ConversationDto();
    latestMessage.createdAt = moment();
    latestMessage.body = chatDetails.message;
    latestMessage.senderMemberId = selectedChatRoom.linkedInAccount.linkedInUserProfile.id;
    latestMessage.messageType = AppMessageType.Text;
    latestMessage.success = null;

    const messages = this.conversationMessagesSubject$.value;
    messages.messages.push(latestMessage);
    this.conversationMessagesSubject$.next(messages);
    selectedChatRoom.lastMessageAt = moment();
    selectedChatRoom.lastMessageSender = 0;
    selectedChatRoom.lastMessageText = chatDetails.message;
    selectedChatRoom.lastMessageType = AppMessageType.Text;
    selectedChatRoom.lastMessageSuccess = null;
    this.selectedChatSubject$.next({ ...selectedChatRoom } as InboxConversationDto);
    this.chatRoomsSubject$.next([
      selectedChatRoom,
      ...this.chatRoomsValue().filter((chatRoom) => chatRoom.id !== selectedChatRoom.id),
    ]);

    return this._inboxService.sendMessage(textMessage).pipe(
      map((x: ConversationDto) => {
        if (!x || !x.messageId) {
          selectedChatRoom.lastMessageSuccess = false;
          this.selectedChatSubject$.next({ ...selectedChatRoom } as InboxConversationDto);
          return false;
        }

        latestMessage = x;
        this.conversationMessagesSubject$.next(messages);
        this.checkIfMessageIsSent(selectedChatRoom.id);
        return true;
      }),
    );
  }

  private checkIfMessageIsSent(chatRoomId: string) {
    const chatRoom = this.chatRoomsValue().find((x) => x.id === chatRoomId);
    if (!chatRoom) {
      return;
    }

    this.checkIfMessageIsSentSubscription = timer(2000, 2000)
      .pipe(
        tap(() => {
          const currentChatRoom = this.chatRoomsValue().find((x) => x.id === chatRoomId);
          if (
            currentChatRoom?.lastMessageSuccess === true ||
            currentChatRoom?.lastMessageSuccess === false
          ) {
            this.checkIfMessageIsSentSubscription?.unsubscribe();
            this.checkIfMessageIsSentSubscription = undefined;
          }
        }),
        switchMap(() => this._inboxService.getConversation(chatRoom.accountId, chatRoom.id)),
        tap((response) => {
          const lastMessage = response.messages[response.messages.length - 1];

          if (lastMessage.success === true || lastMessage.success === false) {
            if (this.selectedChatSubject$.value.id === chatRoomId) {
              const messages = this.conversationMessagesSubject$.value;
              messages.messages = response.messages;
              this.conversationMessagesSubject$.next(messages);
            }

            chatRoom.lastMessageSuccess = lastMessage.success;
            this.chatRoomsSubject$.next([
              chatRoom,
              ...this.chatRoomsValue().filter((c) => c.id !== chatRoom.id),
            ]);
            this.checkIfMessageIsSentSubscription?.unsubscribe();
            this.checkIfMessageIsSentSubscription = undefined;
          }
        }),
        take(3),
      )
      .subscribe();
  }

  public matchFilters(chatRoom: InboxConversationDto) {
    if (!new HasFiltersPipe().transform(this.filters, true)) {
      return true;
    }

    return (
      (this.filters.campaignIds.length == 0 ||
        this.filters.campaignIds.includes(chatRoom.campaignId)) &&
      (this.filters.linkedInAccountIds.length == 0 ||
        this.filters.linkedInAccountIds.includes(chatRoom.accountId)) &&
      (this.filters.tagIds.length == 0 ||
        this.filters.tagIds.some((z) =>
          chatRoom.correspondentProfile.tagLinks.map((x) => x.profileTagId).includes(z),
        )) &&
      (!this.filters.conversationType ||
        this.filters.conversationType == chatRoom.conversationType) &&
      (!this.filters.searchString ||
        chatRoom.correspondentProfile.fullName
          .toLocaleLowerCase()
          .includes(this.filters.searchString.toLowerCase()))
    );
  }

  public refreshDetails(chatRoom: InboxConversationDto) {
    chatRoom['refreshing'] = true;
  }

  public setSelectedChatDetails(chat: ChatDetails) {
    this.selectedChatDetailsSubject$.next({ ...chat } as ChatDetails);
  }

  private updateTagOnChat(
    tag: LinkedInProfileTagDto,
    chatRoom: InboxConversationDto,
  ): InboxConversationDto {
    const found = chatRoom?.correspondentProfile?.tagLinks.find((z) => z.profileTagId == tag.id);
    if (found) {
      found.profileTag.colorHex = tag.colorHex;
      found.profileTag.displayName = tag.displayName;
    }
    const chatRoomUpdated = {
      ...chatRoom,
      correspondentProfile: {
        ...chatRoom?.correspondentProfile,
        tagLinks: chatRoom?.correspondentProfile?.tagLinks.map((z) => {
          if (z.profileTagId == tag.id) {
            z.profileTag.colorHex = tag.colorHex;
            z.profileTag.displayName = tag.displayName;
          }
          return z;
        }),
      },
    };
    return chatRoomUpdated as InboxConversationDto;
  }

  private getChatRoomById(accountId: number, chatRoomId: string) {
    return this.chatRoomsValue()?.find((x) => x.accountId == accountId && x.id === chatRoomId);
  }

  public get selectedAll() {
    return this.selectedAll$$.value;
  }

  public set selectedAll(flag: boolean) {
    this.selectedAll$$.next(flag);
  }

  public set selectedChats(selectedChats: InboxConversationDto[]) {
    this.selectedChats$$.next(selectedChats);
  }

  public getSelectedChats = () => this.selectedChats$$.value;

  public getSelectedChatCorrespondentProfiles = () =>
    this.selectedChats$$.value.map((x) => x.correspondentProfile).map((x) => x.id);

  public getSelectedChatCorrespondentProfilesIds = () =>
    this.selectedChats$$.value.map((x) => x.correspondentProfile).map((x) => x.id);

  getExcludedProfileIds() {
    const selectedChats = this.selectedChats$$.getValue();
    const selectedProfileIds = selectedChats.map((x) => x.correspondentProfile.id);
    const currentChatRooms = this.getCurrentChatRooms().map((i) => i.correspondentMemberId);

    return currentChatRooms.filter((item) => !selectedProfileIds.includes(item));
  }

  getExcludedConversations() {
    const selectedChats = this.selectedChats$$.getValue();
    const currentChatRooms = this.getCurrentChatRooms();
    return currentChatRooms.filter((item) => !selectedChats.includes(item));
  }

  public updateSelectedChats(chat: InboxConversationDto, value: boolean) {
    const oldSelectedChatsValue = this.selectedChats$$.getValue();
    if (value) {
      this.selectedChats$$.next([...oldSelectedChatsValue, chat]);
    } else {
      this.selectedChats$$.next(
        oldSelectedChatsValue.filter((x) => x.id != chat.id || x.accountId != chat.accountId),
      );
    }
  }

  public changeSelectionOnAllChats(value: boolean) {
    if (value) {
      this.selectedChats$$.next([...this.getCurrentChatRooms()]);
    } else {
      this.selectedChats$$.next([]);
    }
  }

  exportDataToCrm(): ExportCrmInboxContactsDto {
    return {
      inboxFilters: this.filters,
      inboxConversations: this.getSelectedChats(),
      crmInfoId: null,
      exportLeadProperties: new CrmExportLeadProperties(),
    } as ExportCrmInboxContactsDto;
  }

  openCrmWithSelectedChats(): ConversationLinkedInProfileIdDto[] {
    return this.getSelectedChats()
      .filter((x: InboxConversationDto) => x.correspondentMemberId)
      .map((x) => {
        return new ConversationLinkedInProfileIdDto({
          conversationId: x.id,
          memberId: x.correspondentMemberId,
          accountId: x.accountId,
        });
      });
  }

  openAddToListDialog() {
    const mode: AddLeadsFromMemberIdsOrInboxMode = this.selectedAll
      ? 'FROM_INBOX'
      : 'FROM_MEMBER_IDS';

    return {
      mode: mode,
      memberIds: this.getSelectedChatCorrespondentProfiles(),
      inboxFilters: this.filters,
      excludeMemberIds: this.getSelectedChatCorrespondentProfilesIds(),
      leadsCount: !this.selectedAll ? this.selectedChatsCount : this.totalChatRooms,
    };
  }

  addToCampaignData() {
    return {
      inboxFilters: this.selectedAll ? this.filters : null,
      inboxConversations: this.selectedAll
        ? this.getExcludedConversations()
        : this.getSelectedChats(),
      leadsCount: this.selectedAll
        ? this.totalChatRooms - this.getExcludedConversations().length
        : this.selectedChatsCount,
    };
  }

  assignTag(tags: LnkLinkedInProfileTagDto[]) {
    const selectedChat = this.selectedChatValue();
    selectedChat.correspondentProfile.tagLinks = [
      ...selectedChat.correspondentProfile.tagLinks,
      ...tags,
    ];
    this.updateChat(this.selectedChatValue());
    const getCurrentChatRooms = this.getCurrentChatRooms();

    const findCurrentChatRoom = getCurrentChatRooms.find(
      (chat) => chat.id === selectedChat?.id && chat.accountId === selectedChat?.accountId,
    );

    findCurrentChatRoom.correspondentProfile.tagLinks = [
      ...selectedChat.correspondentProfile?.tagLinks,
      ...tags,
    ];

    this.chatRooms = getCurrentChatRooms;
  }

  unAssignTag(tagId: number) {
    const selectedChat = this.selectedChatValue();

    this.selectedChatValue().correspondentProfile.tagLinks =
      this.selectedChatValue().correspondentProfile.tagLinks.filter(
        (x) => x.profileTagId !== tagId,
      );
    this.updateChat(this.selectedChatValue());

    const getCurrentChatRooms = this.getCurrentChatRooms();

    const findCurrentChatRoom = getCurrentChatRooms.find(
      (chat) => chat.id === selectedChat?.id && chat.accountId === selectedChat?.accountId,
    );

    findCurrentChatRoom.correspondentProfile.tagLinks =
      this.selectedChatValue().correspondentProfile.tagLinks.filter(
        (x) => x.profileTagId !== tagId,
      );
    this.chatRooms = getCurrentChatRooms;
  }
}
