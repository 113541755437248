import { Component, Input } from '@angular/core';
import { appModuleAnimation } from '@shared/animations/routerTransition';

@Component({
  selector: 'heyreach-card',
  templateUrl: './heyreach-card.component.html',
  animations: [appModuleAnimation()],
})
export class HeyReachCardComponent {
  @Input() overflowHidden: boolean = false;
}
