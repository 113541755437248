<ng-container *ngIf="profile">
  <div class="flex flex-col items-center mt-8 px-7">
    <div class="flex flex-col items-center">
      <user-avatar
        [imageUrl]="profile.imageUrl"
        [firstName]="profile.firstName"
        [lastName]="profile.lastName"
        [hasDynamicFont]="false"
        [size]="100"
        [customFontSize]="30"
        class="flex-shrink-0"></user-avatar>
      <div
        class="mt-4 text-xl font-semibold text-gray-700 text-center flex flex-wrap justify-center">
        <span>{{ profile.firstName + ' ' + profile.lastName }}</span>
        <linkedin-profile-url [profile]="profile"></linkedin-profile-url>
      </div>
    </div>
    <div class="flex justify-center mt-2">
      <email-enrich-button
        [profile]="profile"
        [showAsButton]="true"
        [disableEmailTooltip]="true"
        [enableEmailEditing]="true"></email-enrich-button>
    </div>
    <div class="flex mt-2">
      <crm-button>
        <button
          heyreachButton
          buttonType="icon"
          (click)="onExportToCrmClick()"
          matTooltip="Export to CRM">
          <mat-icon
            svgIcon="custom:export_to_crm"
            class="icon-size-5 ml-0.5 text-secondary"></mat-icon>
        </button>
      </crm-button>
      <button
        heyreachButton
        buttonType="icon"
        matTooltip="Add to campaign"
        (click)="openAddToCampaignDialog()"
        *ngIf="accountId">
        <mat-icon svgIcon="custom:send_campaign" class="icon-size-5"></mat-icon>
      </button>
      <button
        heyreachButton
        buttonType="icon"
        matTooltip="Add to list"
        (click)="openAddToListDialog()">
        <mat-icon svgIcon="custom:add_to_list" class="icon-size-5"></mat-icon>
      </button>
    </div>
  </div>

  <div class="py-5 pr-3">
    <div class="mt-2 space-y-4">
      <div class="mb-4">
        <tags-list
          [selectedProfiles]="[profile]"
          [selectedAll]="false"
          [isSingleTagView]="false"
          (onAssignTag)="onAssignTag($event)"
          (onUnassignTag)="onUnassignTag($event)"
          (onUpdateTagChange)="updateTag($event)"></tags-list>
      </div>
      <info-card-display
        *ngIf="profile.summary"
        [title]="'Headline'"
        [summary]="profile.summary"></info-card-display>
      <info-card-display
        *ngIf="profile.about"
        [title]="'About'"
        [summary]="profile.about"></info-card-display>
      <info-card-display
        *ngIf="profile.companyName"
        [title]="'Company'"
        [summary]="profile.companyName"></info-card-display>
      <info-card-display
        *ngIf="profile.location"
        [title]="'Address'"
        [summary]="profile.location"></info-card-display>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="!profile">
  <div class="flex flex-col items-center mt-8 px-7">
    <user-avatar [size]="9"></user-avatar>
    <div class="mt-4 text-lg font-medium text-center">LinkedIn Member</div>
  </div>
</ng-container>
