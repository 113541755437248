import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import {
  LinkedInAccountSettingsServiceProxy,
  TimeZoneDto,
} from '@shared/service-proxies/service-proxies';

@Component({
  selector: 'timezone-select',
  templateUrl: './timezone-selector.component.html',
  animations: [appModuleAnimation()],
})
export class TimezoneSelector implements OnInit {
  @Input() disabled: boolean = false;
  @Input() selectedTimezone: string;
  @Output() selectedTimezoneChange = new EventEmitter<string>();

  timezones: TimeZoneDto[] = [];
  filteredTimezones: TimeZoneDto[] = [];

  constructor(private _linkedInAccountSettingsService: LinkedInAccountSettingsServiceProxy) {}

  ngOnInit(): void {
    this._linkedInAccountSettingsService.getAvailableTimezones().subscribe((res) => {
      this.timezones = res;
      this.filteredTimezones = this.timezones;
    });
  }

  filterTimezones(searchTerm: string): void {
    this.filteredTimezones = this.timezones.filter((x) =>
      x.displayName.toLowerCase().includes(searchTerm.toLowerCase()),
    );
  }

  onTimezoneChange(): void {
    this.selectedTimezoneChange.emit(this.selectedTimezone);
  }
}
