import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AccountStatusBadgeData } from '@app/linkedin-accounts/linkedin-accounts.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: 'account-status-badge.component.html',
  selector: 'account-status-badge',
})
export class AccountStatusBadgeComponent {
  @Input()
  public statusBadgeData: AccountStatusBadgeData;
}
