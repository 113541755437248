import { Injectable } from '@angular/core';
import { AccountScheduleDto } from '@shared/service-proxies/service-proxies';

@Injectable()
export class WorkingHoursService {
  constructor() {}

  isOutsideWorkingHours(schedule: AccountScheduleDto): boolean {
    const currentTime = new Date();
    const timeZoneOffset = this.getTimeZoneOffset(schedule.timeZoneInfoId);
    currentTime.setMinutes(currentTime.getMinutes() + timeZoneOffset);

    const dayMapping: { [key: number]: string } = {
      0: 'Sunday',
      1: 'Monday',
      2: 'Tuesday',
      3: 'Wednesday',
      4: 'Thursday',
      5: 'Friday',
      6: 'Saturday',
    };

    const currentDay = dayMapping[currentTime.getUTCDay()];
    const enabledDayKey = `enabled${currentDay}`;
    const startOffsetKey = `${currentDay.toLowerCase()}StartOffset`;
    const endOffsetKey = `${currentDay.toLowerCase()}EndOffset`;

    if (!schedule[enabledDayKey]) {
      return true;
    }

    const startTime = this.convertToUtc(currentTime, schedule[startOffsetKey]);
    const endTime = this.convertToUtc(currentTime, schedule[endOffsetKey]);

    return currentTime < startTime || currentTime > endTime;
  }

  private convertToUtc(baseTime: Date, offset: string): Date {
    const [hours, minutes, seconds] = offset
      .split(':')
      .map((s) => {
        if (s === '1.00') {
          return '24';
        }

        return s;
      })
      .map(Number);
    const offsetTime = new Date(baseTime);
    offsetTime.setUTCHours(hours, minutes, seconds, 0);
    return offsetTime;
  }

  private getTimeZoneOffset(timeZoneId: string): number {
    const timeZoneMapping: { [key: string]: number } = {
      'Etc/GMT-12': 720,
      'Etc/GMT-11': 660,
      'Etc/GMT-10': 600,
      'Etc/GMT-9': 540,
      'Etc/GMT-8': 480,
      'Etc/GMT-7': 420,
      'Etc/GMT-6': 360,
      'Etc/GMT-5': 300,
      'Etc/GMT-4': 240,
      'Etc/GMT-3': 180,
      'Etc/GMT-2': 120,
      'Etc/GMT-1': 60,
      'Etc/GMT+0': 0,
      'Etc/GMT+1': -60,
      'Etc/GMT+2': -120,
      'Etc/GMT+3': -180,
      'Etc/GMT+4': -240,
      'Etc/GMT+5': -300,
      'Etc/GMT+6': -360,
      'Etc/GMT+7': -420,
      'Etc/GMT+8': -480,
      'Etc/GMT+9': -540,
      'Etc/GMT+10': -600,
      'Etc/GMT+11': -660,
      'Etc/GMT+12': -720,
    };

    return timeZoneMapping[timeZoneId] || 0;
  }
}
