import {
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  ElementRef,
  Input,
  ViewContainerRef,
} from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { ProgressSpinnerComponent } from '@shared/components/user-interface/progress-spinner/progress-spinner.component';

@Directive({
  selector: '[loading]',
})
export class IsLoadingDirective {
  componentRef: ComponentRef<MatProgressSpinner>;
  spinnerElement: ComponentRef<ProgressSpinnerComponent>;

  constructor(
    private _element: ElementRef,
    public viewContainerRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver,
  ) {}

  alreadyHidden: boolean;

  private _isLoading: boolean = false;
  @Input() set loading(isLoading: boolean) {
    this._isLoading = isLoading;
    this.refreshState();
  }

  private _diameter: number = 8;
  @Input() set loadingDiameter(diameter: number) {
    this._diameter = diameter;
    this.refreshState();
  }

  private _color: 'primary' | 'white' | 'secondary' | 'warn' = 'white';
  @Input() set loadingColor(color) {
    this._color = color;
    this.refreshState();
  }

  refreshState(): void {
    if (this._isLoading === undefined) {
      return;
    }

    if (this._isLoading) {
      if (this.spinnerElement) {
        this.spinnerElement.instance.diameter = this._diameter;
        this.spinnerElement.instance.color = this._color;
        return;
      }

      this.alreadyHidden = this._element.nativeElement.classList.contains('loading-invis');
      if (!this.alreadyHidden) this._element.nativeElement.classList.add('loading-invis');

      const componentFactory =
        this.componentFactoryResolver.resolveComponentFactory(ProgressSpinnerComponent);
      this.viewContainerRef.clear();

      this.spinnerElement = this.viewContainerRef.createComponent(componentFactory);
      this.spinnerElement.instance.diameter = this._diameter;
      this.spinnerElement.instance.color = this._color;
      this.spinnerElement.location.nativeElement.classList.add('loading-spinner');

      this._element.nativeElement.parentNode.append(this.spinnerElement?.location?.nativeElement);
    } else {
      if (!this.alreadyHidden) this._element.nativeElement.classList.remove('loading-invis');

      this.spinnerElement?.location?.nativeElement.remove();
      this.spinnerElement = null;
      this.viewContainerRef.clear();
    }
  }
}
