import { Component, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'new-campaign-dialog',
  templateUrl: 'new-campaign-dialog.component.html',
})
export class NewCampaignDialogComponent {
  newCampaignName: string = '';
  isEditMode: boolean = false;

  constructor(
    private _dialogRef: MatDialogRef<NewCampaignDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit(): void {
    this.newCampaignName = this.data?.campaignName !== undefined ? this.data.campaignName : '';
    this.isEditMode = this.data?.campaignName !== undefined ? true : false;
  }

  onSubmit(): void {
    this._dialogRef.disableClose = true;
    this._dialogRef.close(this.newCampaignName);
  }

  get isNameValid(): boolean {
    return this.newCampaignName.length > 0;
  }
}
