import { Component, EventEmitter, Input, Output } from '@angular/core';
import { appModuleAnimation } from '@shared/animations/routerTransition';

@Component({
  selector: 'page-header',
  templateUrl: './page-header.component.html',
  animations: [appModuleAnimation()],
})
export class PageHeaderComponent {
  @Input() pageTitle: string;

  get canReportBug(): boolean {
    return window?.birdeatsbug?.isBrowserSupported;
  }

  reportBug() {
    window?.birdeatsbug?.trigger();
  }
}
