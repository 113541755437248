import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import {
  LinkedInAccountServiceProxy,
  OwnProxyDto,
  UpdateProxyDto,
} from '@shared/service-proxies/service-proxies';
import { AppSessionService } from '@shared/session/app-session.service';
import { startWithTap } from '@shared/pipes/start-with-tap.pipe';
import { InterceptorDisabler } from '@shared/interceptors/disable-interceptor';
import { isEmpty } from 'lodash-es';

@Injectable()
export class CustomProxyService {
  private stateSubject$ = new BehaviorSubject<ProxyInputState>({
    isCustomProxyMandatory: false,
    isUsingCustomProxy: false,
    proxyInput: new OwnProxyDto(),
    isProxyInputOkay: false,
    isCustomProxyTested: false,
    isTestingProxyInProgress: false,
    responseCountryCode: '',
    isUpdatingProxyForAccount: false,
    verificationStatus: '',
  });

  public state$ = this.stateSubject$.asObservable();

  private proxyVerifiedNotificationSubject$ = new Subject<void>();
  public proxyVerifiedNotification$ = this.proxyVerifiedNotificationSubject$.asObservable();

  private proxyVerifiedErrorNotificationSubject$ = new Subject<string>();
  public proxyVerifiedErrorNotification$ =
    this.proxyVerifiedErrorNotificationSubject$.asObservable();

  private proxyUpdateNotificationSubject$ = new Subject<void>();
  public proxyUpdateNotification$ = this.proxyUpdateNotificationSubject$.asObservable();

  constructor(
    private _linkedinAccountService: LinkedInAccountServiceProxy,
    private _appSession: AppSessionService,
    private _interceptorDisabler: InterceptorDisabler,
  ) {
    this.stateSubject$.next({
      ...this.stateSubject$.value,
      isCustomProxyMandatory:
        this._appSession.subscription?.subscriptionType?.isCustomProxyRequired ?? false,
    });
    this.stateSubject$.next({
      ...this.stateSubject$.value,
      isUsingCustomProxy: this.isCustomProxyMandatory ? true : this.isUsingCustomProxy,
    });
  }

  public get isCustomProxyMandatory(): boolean {
    return this.stateSubject$.value.isCustomProxyMandatory;
  }

  public get isUsingCustomProxy(): boolean {
    return this.stateSubject$.value.isUsingCustomProxy;
  }

  public get proxyInput(): OwnProxyDto {
    return isEmpty(this.stateSubject$.value.proxyInput)
      ? undefined
      : this.stateSubject$.value.proxyInput;
  }

  public get proxyCountryCode(): string {
    return this.stateSubject$.value.responseCountryCode;
  }

  public get isTestingProxyInProgress(): boolean {
    return this.stateSubject$.value.isTestingProxyInProgress;
  }

  public get isUpdatingProxyForAccount(): boolean {
    return this.stateSubject$.value.isUpdatingProxyForAccount;
  }

  public get isVerificationStatus(): string {
    return this.stateSubject$.value.verificationStatus;
  }

  public get proxyHost(): string {
    return this.stateSubject$.value.proxyInput.host;
  }

  public set proxyHost(value: string) {
    this.updateCustomProxyState(
      new OwnProxyDto({ ...this.stateSubject$.value.proxyInput, host: value }),
    );
  }

  public get proxyPort(): number {
    return this.stateSubject$.value.proxyInput.port;
  }

  public set proxyPort(value: number) {
    this.updateCustomProxyState(
      new OwnProxyDto({ ...this.stateSubject$.value.proxyInput, port: value }),
    );
  }

  public get proxyUsername(): string {
    return this.stateSubject$.value.proxyInput.user;
  }

  public set proxyUsername(value: string) {
    this.updateCustomProxyState(
      new OwnProxyDto({ ...this.stateSubject$.value.proxyInput, user: value }),
    );
  }

  public get proxyPassword(): string {
    return this.stateSubject$.value.proxyInput.pass;
  }

  public set proxyPassword(value: string) {
    this.updateCustomProxyState(
      new OwnProxyDto({ ...this.stateSubject$.value.proxyInput, pass: value }),
    );
  }

  public toggleCustomProxy(): void {
    if (this.stateSubject$.value.isCustomProxyMandatory) {
      return;
    }
    this.updateState({ isUsingCustomProxy: !this.isUsingCustomProxy });
  }

  public updateCustomProxyState(proxy: OwnProxyDto): void {
    this.updateState({
      proxyInput: proxy || new OwnProxyDto(),
      isCustomProxyTested: false,
      isProxyInputOkay: this.isProxyValid(proxy),
      verificationStatus: '',
    });
  }

  public testCustomProxy(): void {
    this.updateState({ isTestingProxyInProgress: true });
    this._linkedinAccountService
      .testProxy(this.stateSubject$.value.proxyInput)
      .pipe(
        finalize(() => {
          this.updateState({ isTestingProxyInProgress: false });
        }),
        startWithTap(() => {
          this._interceptorDisabler.disable();
        }),
        catchError((error): Observable<never> => {
          this.updateState({ isTestingProxyInProgress: false });
          this.updateState({ verificationStatus: 'failed' });
          this.proxyVerifiedErrorNotificationSubject$.next(
            JSON.parse(error.response)?.error?.message ||
              'An error occurred while trying to connect the account.',
          );
          return throwError(error);
        }),
      )
      .subscribe((res) => {
        this.updateState({
          responseCountryCode: res,
          isCustomProxyTested: true,
          verificationStatus: 'success',
        });
        this.proxyVerifiedNotificationSubject$.next();
      });
  }

  public updateCustomProxyForAccount(linkedInAccountId: number): void {
    this.updateState({ isUpdatingProxyForAccount: true });
    this.updateState({ isUsingCustomProxy: true });
    this._linkedinAccountService
      .updateProxy({
        linkedInAccountId: linkedInAccountId,
        proxy: this.proxyInput,
        countryCode: this.proxyCountryCode,
      } as UpdateProxyDto)
      .pipe(
        finalize(() => {
          this.updateState({ isUpdatingProxyForAccount: false });
        }),
      )
      .subscribe(() => {
        this.proxyUpdateNotificationSubject$.next();
        this.updateCustomProxyState(new OwnProxyDto());
        this.updateState({ isCustomProxyTested: false, verificationStatus: '' });
      });
  }

  public isProxyValid(proxy: OwnProxyDto): boolean {
    if (!proxy || !proxy.host || !proxy.port) {
      return false;
    }
    return true;
  }

  public resetProxyErrorMessage() {
    this.proxyVerifiedErrorNotificationSubject$.next(undefined);
  }

  public resetState() {
    this.stateSubject$ = new BehaviorSubject<ProxyInputState>({
      isCustomProxyMandatory:
        this._appSession.subscription?.subscriptionType?.isCustomProxyRequired ?? false,
      isUsingCustomProxy: this.isCustomProxyMandatory ? true : this.isUsingCustomProxy,
      proxyInput: new OwnProxyDto(),
      isProxyInputOkay: false,
      isCustomProxyTested: false,
      isTestingProxyInProgress: false,
      responseCountryCode: '',
      isUpdatingProxyForAccount: false,
      verificationStatus: '',
    });
  }

  private updateState(newState: Partial<ProxyInputState>): void {
    const currentState = this.stateSubject$.value;
    this.stateSubject$.next({ ...currentState, ...newState });
  }
}

interface ProxyInputState {
  isCustomProxyMandatory: boolean;
  isUsingCustomProxy: boolean;
  proxyInput: OwnProxyDto;
  isProxyInputOkay: boolean;
  isCustomProxyTested: boolean;
  isTestingProxyInProgress: boolean;
  responseCountryCode: string;
  isUpdatingProxyForAccount: boolean;
  verificationStatus: 'failed' | 'success' | '';
}
