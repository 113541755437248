import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
})
export class ConfirmationDialogComponent implements OnInit {
  showButtons: boolean = true;
  confirmButtonColor: string;

  constructor(
    private domSanitizer: DomSanitizer,
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogData,
  ) {
    this.confirmButtonColor = this.data.confirmButtonColor || 'blue';
  }

  ngOnInit() {
    this.showButtons = this.data.showButtons !== undefined ? this.data.showButtons : true;
  }

  onConfirm(): void {
    this.dialogRef.close(true);
  }

  onCancel(): void {
    this.dialogRef.close(false);
  }

  onClose(): void {
    this.dialogRef.close();
  }

  sanitizeHtml(html: string): SafeHtml {
    return this.domSanitizer.bypassSecurityTrustHtml(html);
  }
}

export interface ConfirmationDialogData {
  message: string;
  description: string;
  showButtons?: boolean;
  confirmButtonColor?: string;
  isMessageHtml?: boolean;
  cancelButtonText?: string;
  confirmButtonText?: string;
}
