import { Component, EventEmitter, Input, Output } from '@angular/core';
import { accountModuleAnimation } from '@shared/animations/routerTransition';
import {
  LinkedInAccountDto,
  LinkedInCompanySearchResultDto,
  LinkedInSearchServiceProxy,
} from '@shared/service-proxies/service-proxies';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, finalize } from 'rxjs/operators';

@Component({
  selector: 'linkedin-company-search-select',
  templateUrl: 'linkedin-company-search-select.component.html',
  animations: [accountModuleAnimation()],
})
export class LinkedInCompanySearchSelectComponent {
  @Input() selectedAccount: LinkedInAccountDto;
  @Input() selectedCompany: LinkedInCompanySearchResultDto;
  @Output() selectedCompanyChange = new EventEmitter<LinkedInCompanySearchResultDto>();
  @Input() disabled: boolean = false;

  companies: LinkedInCompanySearchResultDto[] = [];
  searching: boolean;
  searchInputUpdate = new Subject<string>();
  searchSubscription: Subscription;
  searchInputDebounceTime: number = 400;
  minLettersToSearch: number = 2;
  showNoResultsLabel: boolean = false;

  constructor(private _linkedinSearchService: LinkedInSearchServiceProxy) {
    this.searchSubscription = this.searchInputUpdate
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        filter((res) => res.length >= this.minLettersToSearch),
      )
      .subscribe((res) => {
        this.searchCompaniesRequest(res);
      });
  }

  searchCompaniesRequest(searchTerm: string): void {
    if (this.selectedAccount == null) {
      return;
    }
    this.searching = true;
    this._linkedinSearchService
      .searchCompanies(this.selectedAccount.id, searchTerm)
      .pipe(
        finalize(() => {
          this.searching = false;
          this.showNoResultsLabel = this.companies.length == 0 && !this.searching;
        }),
      )
      .subscribe((res) => {
        this.companies = res;
        if (
          this.selectedCompany &&
          !this.companies.map((x) => x.companyId).includes(this.selectedCompany.companyId)
        ) {
          this.companies.push(this.selectedCompany);
        }
      });
  }

  onSearchInputChange(input: string): void {
    this.showNoResultsLabel = false;
    this.searchInputUpdate.next(input);
  }

  onSelectedCompany(): void {
    this.selectedCompanyChange.emit(this.selectedCompany);
  }
}
