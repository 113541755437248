import { Directive, Input, HostBinding } from '@angular/core';

@Directive({
  selector: '[table-header-cell]',
  host: {
    class:
      'whitespace-nowrap truncate font-semibold border-none bg-gray-50 border-dark text-dark-muted',
  },
})
export class TableHeaderCellDirective {
  @Input('table-header-cell') width: string;
  @Input() textAlign: 'left' | 'right' | 'center' = 'left'; // Default to 'left' alignment
  @Input() isFirst: boolean = false;

  @HostBinding('style.width') get cellWidth() {
    return this.width;
  }

  @HostBinding('class') get cellAlignment() {
    switch (this.textAlign) {
      case 'center':
        return 'text-center';
      case 'left':
        return 'text-left';
      case 'right':
        return 'text-right';
    }
  }

  @HostBinding('class') get cellPadding() {
    switch (this.isFirst) {
      case true:
        return 'px-6';
      case false:
        return 'px-3';
    }
  }
}
