import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { searchTextMap } from '@app/linkedin-searching/getSearchTextMap';
import { WhiteLabelService } from '@app/white-label.service';

@Pipe({
  name: 'youtubeSafeVideoUrl',
})
export class YoutubeSafeVideoUrlPipe implements PipeTransform {
  searchTextMap;

  constructor(
    private sanitizer: DomSanitizer,
    private whiteLabelService: WhiteLabelService,
  ) {
    this.searchTextMap = searchTextMap(this.whiteLabelService.companyName);
  }

  transform(searchType: number): SafeResourceUrl {
    const videoUrl = this.searchTextMap[searchType]?.videoUrl;

    if (videoUrl) {
      const videoId = this.extractYouTubeVideoId(videoUrl);
      const safeUrl = `https://www.youtube.com/embed/${videoId}`;
      return this.sanitizer.bypassSecurityTrustResourceUrl(safeUrl);
    }
  }

  private extractYouTubeVideoId(url: string): string | null {
    const match = url.match(
      /(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/,
    );
    return match ? match[1] : null;
  }
}
