import { Inject, Injectable, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class UserInterfaceService {
  private headerHeight: number = 0;
  private footerHeight: number = 0;
  private tableRowHeightPx: number = 30;
  private substructedPaddingPx: number = 165;

  constructor(@Inject(DOCUMENT) private document: Document) {}

  private setHeaderHeight() {
    const headerElement = document.getElementById('main-header-container');
    if (headerElement) {
      this.headerHeight = headerElement.offsetHeight;
    }
  }

  private setFooterHeight() {
    const footerElement = document.getElementById('main-footer-container');
    if (footerElement) {
      this.footerHeight = footerElement.offsetHeight;
    }
  }

  private calculateHeaderAndFooterHeight() {
    this.setHeaderHeight();
    this.setFooterHeight();
  }

  getTotalElementsHeight() {
    this.calculateHeaderAndFooterHeight();
    return this.headerHeight + this.footerHeight;
  }

  get getTableRowHeightPx(): number {
    return this.tableRowHeightPx;
  }

  calculateHeight() {
    this.calculateHeaderAndFooterHeight();
    return window.innerHeight - this.getTotalElementsHeight() - this.substructedPaddingPx;
  }

  toggleIntercom(renderer: Renderer2, show: boolean): void {
    const intercomLauncher = this.document.getElementsByClassName(
      'intercom-launcher',
    )[0] as HTMLElement;
    if (intercomLauncher) {
      if (show) {
        renderer.removeStyle(intercomLauncher, 'display');
      } else {
        renderer.setStyle(intercomLauncher, 'display', 'none');
      }
    }
  }
}
