<ng-container *ngFor="let field of sortedKeyValFieldMapping; let i = index">
  <mat-form-field class="w-full">
    <mat-label>
      Map
      <b>{{ fieldNames[field] }}</b>
      field from:
    </mat-label>
    <mat-select [(ngModel)]="fieldMapping[field]" [required]="isFieldRequired(fieldNames[field])">
      <mat-option>-- None --</mat-option>
      <mat-divider></mat-divider>
      <mat-option *ngFor="let CSVField of CSVFields" [value]="CSVField">
        {{ CSVField }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</ng-container>
