import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { TimeUtility } from '@shared/helpers/time-utility';

// It is possible to optimize this class by using change detection & some extra variables
@Component({
  selector: 'timepicker',
  templateUrl: './custom-timepicker.component.html',
  animations: [appModuleAnimation()],
})
export class CustomTimepickerComponent implements OnInit, OnChanges {
  @Input() min: string;
  @Input() max: string;
  @Input() step: number = 30;
  @Input() disabled: boolean = false;

  @Input() value: string;
  @Output() valueChange = new EventEmitter<string>();

  options: string[] = [];

  ngOnInit(): void {
    this.setTimepickerOptions();
    this.value = this.value.slice(0, 5);
  }

  setTimepickerOptions() {
    // better performance
    if (this.step === 30) {
      this.options = TimeUtility.defaultTimeSelectOptions;
    }

    this.options = [];
    for (let i = 0; i < 1440 / this.step; ++i) {
      this.options.push(TimeUtility.getTimeInDayFromMinutes(i * this.step));
    }
  }

  onSelectedValueChange(opt: string): void {
    this.value = opt;
    this.valueChange.emit(this.value);
  }

  isOptionDisabled(opt: string): boolean {
    const optVal = TimeUtility.getTimeInDayInMinutesFromString(opt);
    if (this.max) {
      const maxVal = TimeUtility.getTimeInDayInMinutesFromString(this.max);
      if (optVal >= maxVal) {
        return true;
      }
    }
    if (this.min) {
      const minVal = TimeUtility.getTimeInDayInMinutesFromString(this.min);
      if (optVal <= minVal) {
        return true;
      }
    }
    return false;
  }

  get valueToDisplay(): string {
    return this.value ? this.value : '--:--';
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value && this.value.length >= 5) {
      this.value = this.value.slice(0, 5);
    }
  }
}
