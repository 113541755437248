<ng-container>
  <mat-form-field class="flex-auto s-mat-dense s-mat-no-subscript w-full mb-4">
    <input
      standardInput
      placeholder="Host"
      [disabled]="_customProxyService.isTestingProxyInProgress"
      matInput
      [(ngModel)]="_customProxyService.proxyHost" />
  </mat-form-field>
  <mat-form-field class="flex-auto s-mat-dense s-mat-no-subscript w-full mb-4">
    <input
      standardInput
      class="font-medium"
      placeholder="Port"
      [disabled]="_customProxyService.isTestingProxyInProgress"
      matInput
      [(ngModel)]="_customProxyService.proxyPort"
      type="number" />
  </mat-form-field>
  <div class="mb-4">
    <div class="md:flex lg:flex xs:gap-0 md:gap-4 lg:gap-4 xs:flex xs:flex-col w-full">
      <mat-form-field class="flex-auto s-mat-dense s-mat-no-subscript w-full">
        <input
          standardInput
          placeholder="Proxy Username"
          [disabled]="_customProxyService.isTestingProxyInProgress"
          matInput
          [(ngModel)]="_customProxyService.proxyUsername" />
      </mat-form-field>
      <mat-form-field class="flex-auto s-mat-dense s-mat-no-subscript w-full">
        <input
          standardInput
          placeholder="Proxy Password"
          [disabled]="_customProxyService.isTestingProxyInProgress"
          matInput
          [(ngModel)]="_customProxyService.proxyPassword" />
      </mat-form-field>
      <div>
        <button
          [disabled]="_customProxyService.isTestingProxyInProgress || !isFormFilled()"
          heyreachButton
          buttonType="default"
          (click)="verifyCustomProxy()">
          <mat-icon class="icon-size-4" svgIcon="heroicons_solid:check"></mat-icon>
          <div class="ml-2 text-md text-nowrap">Verify proxy</div>
        </button>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="!_customProxyService.isTestingProxyInProgress">
  <div
    *ngIf="_customProxyService.isVerificationStatus === 'success'"
    class="max-w-md text-secondary relative">
    <span class="flex items-center mt-0">
      <mat-icon
        class="text-success icon-size-4 mr-2 align-middle"
        svgIcon="heroicons_solid:check"></mat-icon>
      <span class="font-medium text-sm text-success">Proxy successfully verified</span>
    </span>
  </div>
  <div
    *ngIf="_customProxyService.isVerificationStatus === 'failed'"
    class="mt-0 max-w-md text-secondary relative">
    <mat-icon
      class="text-warn icon-size-4 mr-2 align-middle"
      svgIcon="heroicons_solid:circle-warrning"></mat-icon>
    <span class="font-medium text-sm text-warn">Proxy verification failed</span>
  </div>
</ng-container>
