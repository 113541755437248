import { AfterViewInit, Directive, ElementRef, Input, Renderer2 } from '@angular/core';
import { UserInterfaceService } from '@shared/user-interface/user-interface.service';
import { Subject } from 'rxjs';

@Directive({
  selector: '[table-row]',
  host: {
    class:
      'm-auto w-full bg-white hover:bg-gray-100 border-b border-gray-200 transition duration-500 ease-out',
  },
})
export class TableRowDirective implements AfterViewInit {
  @Input() isRowSelectable: boolean = true;
  constructor(
    private _el: ElementRef,
    private _renderer: Renderer2,
    private _uiService: UserInterfaceService,
  ) {}

  ngAfterViewInit(): void {
    this.applyTableStyles();
    this.checkIfRowSelectable();
  }

  checkIfRowSelectable() {
    if (this.isRowSelectable) {
      this._renderer.addClass(this._el.nativeElement, 'cursor-pointer');
    }
  }

  applyTableStyles(): void {
    let height = this._uiService.getTableRowHeightPx;

    this._renderer.setStyle(this._el.nativeElement, 'height', `${height}px`);
    this._renderer.setStyle(this._el.nativeElement, 'max-height', `${height}px`);
    this._renderer.setStyle(this._el.nativeElement, 'overflow', 'hidden');

    const divs = this._el.nativeElement.querySelectorAll('tr');
    for (const div of divs) {
      this._renderer.setStyle(div, 'height', `${height}px`);
      this._renderer.setStyle(div, 'max-height', `${height}px`);
    }
  }
}
