import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { TableColumn } from '../table-models/table-column.model';
import { ColumnVisibility } from '@app/toggle-switcher/toggle-switcher.component';

@Component({
  selector: 'app-column-picker',
  templateUrl: './column-picker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColumnPickerComponent implements OnChanges {
  @Input() public tableColumns: TableColumn[] = [];
  @Output() public readonly tableColumnsChange: EventEmitter<TableColumn[]> = new EventEmitter<
    TableColumn[]
  >();

  isDrawerOpen = false;
  searchKeyword: string;

  public tempTableColumns: TableColumn[] = [];
  public dropdownOpen = false;

  public ngOnChanges(changes: any): void {
    if (changes.tableColumns && changes.tableColumns?.currentValue) {
      this.tempTableColumns = this.tableColumns.map((i) => ({ ...i, onSearchFilterActive: true }));
    }
  }

  isChecked(tableColumns: TableColumn): boolean {
    return tableColumns.hidden;
  }

  onDragStart(event: DragEvent, tableColumn: TableColumn): void {
    if (tableColumn.hidden) {
      event.preventDefault();
    } else {
      event.dataTransfer?.setData('text/plain', JSON.stringify(tableColumn));
      tableColumn.dragging = true;
    }
  }

  onDragEnd(event: DragEvent, tableColumns: TableColumn): void {
    tableColumns.dragging = false;
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
  }

  onDrop(event: DragEvent, dropIndex: number): void {
    event.preventDefault();
    const data = event.dataTransfer?.getData('text/plain');
    if (data) {
      const draggedOption: TableColumn = JSON.parse(data);
      const dropOption = this.tempTableColumns[dropIndex];
      if (!draggedOption.hidden && !dropOption.hidden) {
        const dragIndex = this.tempTableColumns.findIndex(
          (option) => option.header === draggedOption.header,
        );
        if (dragIndex !== -1) {
          this.tempTableColumns.splice(dropIndex, 0, this.tempTableColumns.splice(dragIndex, 1)[0]);
        }
      }
    }
  }

  onCheckboxChange(tableColumn: ColumnVisibility) {
    const itemIndex = this.tempTableColumns.findIndex((item) => item.id === tableColumn.id);

    if (itemIndex > -1) {
      this.tempTableColumns[itemIndex] = {
        ...this.tempTableColumns[itemIndex],
        hidden: tableColumn.hidden,
      };
    }

    if (tableColumn.hidden) {
      const itemToBeAdded = this.tempTableColumns[itemIndex];
      if (itemIndex > -1) {
        this.tempTableColumns.splice(itemIndex, 1);
      }
      this.tempTableColumns = [...this.tempTableColumns, itemToBeAdded];
    } else {
      if (this.tempTableColumns[0].hidden) {
        const itemToBeAdded = this.tempTableColumns[itemIndex];
        this.tempTableColumns.splice(itemIndex, 1);
        this.tempTableColumns = [itemToBeAdded, ...this.tempTableColumns];
      } else {
        const itemToBeAdded = this.tempTableColumns[itemIndex];
        if (itemIndex > -1) {
          this.tempTableColumns.splice(itemIndex, 1);
        }
        const firstHiddenItemIndex = this.tempTableColumns.findIndex(
          (item) => item.hidden === true,
        );
        if (firstHiddenItemIndex > -1) {
          this.tempTableColumns.splice(firstHiddenItemIndex, 0, itemToBeAdded);
        } else {
          this.tempTableColumns = [...this.tempTableColumns, itemToBeAdded];
        }
      }
    }
  }

  removeItemFromList(item: TableColumn, list: TableColumn[]): TableColumn[] {
    return list.filter((listItem) => listItem.header !== item.header);
  }

  toggleDrawer() {
    this.isDrawerOpen = !this.isDrawerOpen;
  }

  closeDrawer(applyChanges: boolean) {
    if (!applyChanges) {
      this.resetTempColumns();
    }
    this.isDrawerOpen = false;
    this.searchKeyword = '';
  }

  resetTempColumns() {
    this.tempTableColumns = this.tableColumns.map((i) => ({ ...i, onSearchFilterActive: true }));
  }

  searchInputChanged(searchTerm: string | null): void {
    this.searchKeyword = searchTerm || '';

    this.tempTableColumns = this.tempTableColumns.map((option) => ({
      ...option,
      onSearchFilterActive: option.header.toLowerCase().includes(this.searchKeyword.toLowerCase()),
    }));
  }

  public handleSave(): void {
    this.tableColumnsChange.emit(this.tempTableColumns);
    this.dropdownOpen = false;
    this.closeDrawer(true);
  }

  trackByFn(index: number, item: any): any {
    return item.id || index;
  }
}
