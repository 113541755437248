<ng-container *ngIf="!isExtensionConnecting">
  <div class="flex flex-col max-w-160 m-auto mb-5 lg:px-2 md:px-0">
    <div *ngIf="!accountProfile">
      <div class="flex flex-col">
        <div class="w-full mx-auto items-center">
          <p
            class="text-center font-semibold mb-2 text-secondary mt-6"
            *ngIf="!isLoadingAccountProfile">
            Feeling lazy?
            <span
              [ngClass]="{
                'text-primary underline cursor-pointer': hasExtension,
                'pointer-events-none': !hasExtension
              }"
              (click)="openLoginConfirmation()">
              Connect via our Chrome extension
            </span>
            instead
          </p>
          <div *ngIf="isLoadingAccountProfile" class="flex items-center justify-center">
            <progress-spinner color="primary" [diameter]="12"></progress-spinner>
          </div>
        </div>
        <div class="flex flex-col items-center w-full mx-auto mt-2" *ngIf="!hasExtension">
          <button
            heyreachButton
            buttonType="default"
            class="w-auto mx-auto"
            (click)="openExtensionInStore()">
            <div class="flex items-center" [loading]="isLoadingAccountProfile">
              <mat-icon svgIcon="custom:chrome" class="icon-size-5"></mat-icon>
              <span class="ml-2 text-secondary">Install Chrome Extension</span>
            </div>
          </button>
          <p class="text-xs text-center text-secondary p-2 mb-2">
            *Only available for Google Chrome
          </p>
        </div>
      </div>
    </div>
    <!-- Is user has connected the profile successfully with the extension -->
    <div *ngIf="accountProfile">
      <div>
        <img
          [src]="accountProfile.profileImage"
          class="rounded-full w-20 mx-auto mt-5 mb-5"
          [ngClass]="{
            'mt-0': _customProxyService.isUsingCustomProxy,
            'mt-5': !_customProxyService.isUsingCustomProxy
          }" />
        <div *ngIf="!accountAlreadyConnected">
          <div
            *ngIf="!_customProxyService.isUsingCustomProxy"
            class="text-xl text-center font-bold mt-5 mb-5">
            Do you want to connect {{ accountProfile.firstName }} to
            <br />
            {{ companyName }}?
          </div>
        </div>
      </div>
      <div
        *ngIf="_customProxyService.isUsingCustomProxy && !isAgency"
        class="flex py-2 px-3 mb-7 text-md bg-sand-muted rounded-lg text-sand"
        role="alert">
        <mat-icon
          class="icon-size-5 relative top-1 text-sand"
          svgIcon="heroicons_solid:warning-triangle"></mat-icon>
        <span class="mt-1 ml-2">
          <p class="text-sm font-medium">
            Adding your own proxy, can affect your overall {{ companyName }}
            experience. Proceed only if you know what you are doing.
          </p>
        </span>
      </div>
      <!-- If proxy is toggled -->
      <div
        class="w-full mb-8"
        *ngIf="accountProfile && (_customProxyService.isUsingCustomProxy || isAgency)">
        <div
          *ngIf="!accountAlreadyConnected"
          class="flex flex-col gt-xs:flex-row gt-xs:items-baseline mb-2 text-left">
          <proxy-select></proxy-select>
        </div>
      </div>
      <div *ngIf="!accountAlreadyConnected">
        <linkedin-inbox-configuration
          [inboxConfigurations]="inboxConfigurations"
          [label]="inboxScrapeConfigurationLabel"
          [inboxPrivacyConfiguration]="accountProfile.inboxScrapeConfiguration"
          (onSelectedInboxOptionChange)="
            selectedInboxOptionChange($event)
          "></linkedin-inbox-configuration>
      </div>
      <!-- Main action -->
      <div class="flex justify-between flex-col w-full">
        <button
          heyreachButton
          buttonType="primary"
          class="w-full mb-4"
          [ngClass]="{
            'w-full mx-0': _customProxyService.isUsingCustomProxy
          }"
          [disabled]="
            accountProfile
              | isConnectAccountFormDisabledPipe
                : _customProxyService.isUsingCustomProxy
                : _customProxyService.isVerificationStatus
                : true
                : isExtensionConnecting
          "
          (click)="connectAccountViaExtension()"
          *ngIf="!accountAlreadyConnected">
          <div class="flex items-center">
            <span class="ml-1 text-md">Yes, connect</span>
          </div>
        </button>
        <button
          class="w-full"
          heyreachButton
          buttonType="default"
          (click)="changeLinkedInAccount()"
          [ngClass]="{
            'w-full mx-0': _customProxyService.isUsingCustomProxy
          }">
          No, change account
        </button>
      </div>
    </div>
    <div class="w-full mt-6 mb-2 text-center" *ngIf="accountProfile">
      or
      <a class="underline text-primary font-bold" (click)="loginWithLinkedIn()">
        login manually with credentials
      </a>
    </div>
  </div>
</ng-container>
