import { Pipe, PipeTransform } from '@angular/core';
import { PluralOnNumber } from '@shared/AppFunctions';

@Pipe({
  name: 'pluralOnNumber',
})
export class PluralOnNumberPipe implements PipeTransform {
  transform(value: number): string {
    if (value === undefined) {
      return '';
    }

    return PluralOnNumber(value);
  }
}
