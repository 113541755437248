<div
  (click)="createSearchClick()"
  [ngClass]="{
    'bg-secondary-muted': selectedSearchType === searchType,
    'bg-white': selectedSearchType !== searchType
  }"
  class="hover:bg-secondary-muted border-secondary-muted border-2 rounded-lg cursor-pointer px-3 sm:h-20 md:h-14 md:py-1 items-center flex">
  <div class="w-full flex items-center">
    <img [src]="searchImage" class="icon-size-12" />
    <div class="select-none sm:ml-3 ml-4">
      <p class="text-base font-semibold text-left">{{ searchTitle }}</p>
    </div>
  </div>
</div>
