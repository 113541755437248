import { Component, Input } from '@angular/core';
import { accountModuleAnimation } from '@shared/animations/routerTransition';
import { LinkedInUserProfileDto } from '@shared/service-proxies/service-proxies';
@Component({
  selector: 'linkedin-profile-url',
  templateUrl: './linkedin-profile-url.component.html',
  animations: [accountModuleAnimation()],
})
export class LinkedinProfileUrlComponent {
  @Input() profile: LinkedInUserProfileDto;

  getLinkedinProfileUrl(): string {
    if (!this.profile) return '';

    let username =
      this.profile.username || this.profile.profileId || this.profile.navigatorProfileId;
    if (!username) return '';

    return `https://www.linkedin.com/in/${username}/`;
  }
}
