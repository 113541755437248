<div class="flex items-center justify-between mb-4">
  <div class="flex-1 text-2xl font-semibold pr-2">
    {{ title }}
  </div>

  <div class="self-end">
    <button
      heyreachButton
      buttonType="icon"
      (click)="onCloseButtonClick()"
      [disabled]="closeDisabled">
      <mat-icon svgIcon="close"></mat-icon>
    </button>
  </div>
</div>
