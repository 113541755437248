import { SortBy, SortOrderType } from '../table-models/sort-types.model';

export const genericSortItems = (sortModel: SortBy, listItems: any[]): void => {
  if (sortModel) {
    switch (sortModel?.type) {
      case 'date':
        sortByDate(sortModel, listItems);
        break;
      default:
        sortByString(sortModel, listItems);
        break;
    }
  }
};

const sortByDate = (sortModel: SortBy, listItems: any[]): void => {
  listItems.sort((a, b) => {
    const _a = getTime(a[sortModel.key]);
    const _b = getTime(b[sortModel.key]);
    const expressions = sortModel.sortOrder === SortOrderType.ASC ? _a < _b : _a > _b;

    return _a !== _b ? (expressions ? -1 : 1) : 0;
  });
};

const sortByString = (sortModel: SortBy, listItems: any[]) => {
  listItems.sort((a, b) => {
    const _a = a[sortModel.key];
    const _b = b[sortModel.key];
    const expressions =
      sortModel.sortOrder === SortOrderType.ASC
        ? _a.toLowerCase() < _b.toLowerCase()
        : _a.toLowerCase() > _b.toLowerCase();

    return _a.toLowerCase() !== _b.toLowerCase() ? (expressions ? -1 : 1) : 0;
  });
};

const getTime = (date?: string): number => {
  return date != null ? Date.parse(date) : 0;
};
