import { Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appCustomCheckbox]',
})
export class CustomCheckboxDirective {
  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
  ) {
    const checkBoxCustomClasses: string =
      'mt-1 w-4 h-4 text-primary bg-white border-secondary-muted rounded focus:ring-primary dark:focus:ring-primary dark:ring-secondary-muted focus:ring-2 dark:bg-secondary dark:border-secondary form-checkbox border-2 border-secondary-muted rounded-md checked:bg-pale transition duration-150 ease-in-out';

    if (checkBoxCustomClasses) {
      const classesArray = checkBoxCustomClasses.split(' ');
      classesArray.forEach((className) => {
        this.renderer.addClass(this.elementRef.nativeElement, className);
      });
    }
  }
}
