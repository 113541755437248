import { Component, EventEmitter, Input, Output } from '@angular/core';
import { appModuleAnimation } from '@shared/animations/routerTransition';

@Component({
  selector: 'dialog-header',
  templateUrl: './dialog-header.component.html',
  animations: [appModuleAnimation()],
})
export class DialogHeaderComponent {
  @Output() closeClick = new EventEmitter<void>();
  @Input() title: string = '';
  @Input() closeDisabled: boolean = false;

  onCloseButtonClick(): void {
    this.closeClick.emit();
  }
}
