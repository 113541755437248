import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-search-field',
  templateUrl: './search-field.component.html',
  styleUrls: ['./search-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchFieldComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public customCssclass = '';
  @Input() public label = '';
  @Input() public id!: string;
  @Input() public type = 'text';
  @Input() public placeholder = 'Filter data';
  @Input() public ariaLabel = 'search item';
  @Input() public minLength = 1;
  @Input() public debounceTime = 300;
  @Input() public initialValue: string | null = null;
  @Input() public isSmall = true;
  @Input() public disabled = false;
  @Input() public autocomplete = 'on';
  @Output() public readonly filter: EventEmitter<string | null> = new EventEmitter();
  @ViewChild('searchField') public readonly searchField!: ElementRef;

  public readonly control = new UntypedFormControl();

  private readonly destroy$$ = new Subject<void>();

  public ngOnInit(): void {
    this.control.valueChanges
      .pipe(
        debounceTime(this.debounceTime),
        distinctUntilChanged(),
        filter((v: string) => {
          if (v && v.length >= this.minLength) {
            return true;
          } else {
            this.filter.emit(null);
            return false;
          }
        }),
        tap((params) => this.filter.emit(params)),
        takeUntil(this.destroy$$),
      )
      .subscribe();
  }

  public ngOnDestroy(): void {
    this.destroy$$.next();
    this.destroy$$.complete();
  }

  public resetValue(): void {
    this.control.setValue(null);
  }

  public ngOnChanges(simpleChanges: SimpleChanges): void {
    if (simpleChanges['initialValue']) {
      if (this.initialValue !== null && this.initialValue !== '') {
        this.control.patchValue(this.initialValue, { emitEvent: false });
      }
    }

    if (simpleChanges['initialValue']) {
      if (this.initialValue === null) {
        this.resetValue();
      }
    }
  }
}
