import { Component, EventEmitter, Input, Output } from '@angular/core';
export interface ColumnVisibility {
  id: string;
  hidden: boolean;
}
@Component({
  selector: 'app-toggle-switcher',
  templateUrl: './toggle-switcher.component.html',
  styleUrls: ['./toggle-switcher.component.scss'],
})
export class ToggleSwitcherComponent {
  @Input() hidden: boolean = false;
  @Input() required: boolean = false;
  @Input() id: string;
  @Output() checkedChange: EventEmitter<ColumnVisibility> = new EventEmitter<ColumnVisibility>();

  onChange(event: Event): void {
    this.hidden = (event.target as HTMLInputElement).checked;

    this.checkedChange.emit({
      id: this.id,
      hidden: !this.hidden,
    });
  }
}
