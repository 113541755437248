import { Injectable } from '@angular/core';

@Injectable()
export class InterceptorDisabler {
  public enabled: boolean = true;

  public enable() {
    this.enabled = true;
  }

  /**
   * This method disables the interceptor for a single intercept
   * (careful when using with multiple requests at once as this will be valid only for the first finished request)
   */
  public disable() {
    this.enabled = false;
  }
}
