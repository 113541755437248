<div class="w-fill inline-flex items-center">
  <span class="w-10/12 text-gray text-lg">{{ valueToDisplay }}</span>
  <button
    class="w-2/12"
    [disabled]="disabled"
    heyreachButton
    buttonType="icon"
    [matMenuTriggerFor]="menu">
    <mat-icon class="text-sm" svgIcon="access_time_filled" iconSize="3"></mat-icon>
  </button>
</div>
<mat-menu #menu="matMenu" class="max-h-50 timepicker-menu">
  <div class="grid grid-cols-1 gap-0 grid-flow-row">
    <ng-container *ngFor="let opt of options">
      <button
        [disabled]="isOptionDisabled(opt)"
        (click)="onSelectedValueChange(opt)"
        [ngClass]="
          isOptionDisabled(opt)
            ? 'text-gray-200 cursor-pointer'
            : 'text-secondary  hover:bg-gray-200'
        "
        class="text-lg w-fill">
        {{ opt }}
      </button>
    </ng-container>
  </div>
</mat-menu>
