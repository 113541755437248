import { PickTextColorBasedOnBgColorSimple, getRandomColor } from '@shared/AppFunctions';

export class ChipWithOverflow {
  displayText: string = '';
  backgroundColor: string = '';
  textColor: string = '';
  randomColor: boolean = true;
  svgIcon: string = '';
  urlLocation: string = '';
  roundingStyle: 'rounded-full' | 'rounded-md';
  customClass: string = '';

  randomColorPossibilities: { textColor: string; bgColor: string }[] = [
    { textColor: 'bg-primary-muted', bgColor: 'text-primary' },
    { textColor: 'bg-secondary-muted', bgColor: 'text-secondary' },
    { textColor: 'bg-success-muted', bgColor: 'text-success' },
    { textColor: 'bg-sand-muted', bgColor: 'text-sand' },
    { textColor: 'bg-ink-muted', bgColor: 'text-ink' },
    { textColor: 'bg-purple-muted', bgColor: 'text-purple' },
  ];

  constructor(
    displayText: string = '',
    backgroundColor: string = '',
    textColor: string = '',
    randomColor: boolean = true,
    svgIcon: string = '',
    urlLocation: string = '',
    roundingStyle: 'rounded-full' | 'rounded-md',
    customClass: string = '',
  ) {
    this.displayText = displayText;

    if (randomColor) {
      const randomTextAndBgColor = getRandomColor(this.randomColorPossibilities);
      this.customClass = randomTextAndBgColor.bgColor + ' ' + randomTextAndBgColor.textColor;
    }

    if (backgroundColor) {
      textColor = PickTextColorBasedOnBgColorSimple(backgroundColor);
    }

    this.backgroundColor = backgroundColor;
    this.textColor = textColor;
    this.svgIcon = svgIcon;
    this.urlLocation = urlLocation;
    this.customClass += ' ' + roundingStyle;
  }
}
