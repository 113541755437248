import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import {
  LinkedInAccountServiceProxy,
  RecruiterContractDto,
} from '@shared/service-proxies/service-proxies';

@Component({
  templateUrl: './recruiter-subscriptions-dialog.component.html',
  animations: [appModuleAnimation()],
  styleUrls: ['./recruiter-subscriptions-dialog.component.scss'],
})
export class RecruiterSubscriptionsDialogComponent implements OnInit {
  selectedContract: RecruiterContractDto;
  contracts: RecruiterContractDto[] = [];
  accountId: number;
  isLoading: boolean = false;

  constructor(
    private _dialogRef: MatDialogRef<RecruiterSubscriptionsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: RecruiterPlanSelectionDialogData,
    private _linkedInAccountService: LinkedInAccountServiceProxy,
  ) {
    _dialogRef.disableClose = true;
    this.contracts = data.recruiterPlans;
    this.accountId = data.accountId;
  }

  ngOnInit(): void {}

  get isSubmitButtonDisabled(): boolean {
    return this.accountId == null || this.selectedContract == null || this.isLoading;
  }

  onSubmitButtonClick(): void {
    if (this.accountId == null || this.selectedContract == null) {
      return;
    }

    let recruiterContract: RecruiterContractDto = {
      applicationInstance: this.selectedContract.applicationInstance,
      contract: this.selectedContract.contract,
      enterpriseProfile: this.selectedContract.enterpriseProfile,
      ssoEnabled: this.selectedContract.ssoEnabled,
      contractName: this.selectedContract.contractName,
      category: this.selectedContract.category,
    } as RecruiterContractDto;
    this._linkedInAccountService
      .pickRecruiterContract(this.accountId, recruiterContract)
      .subscribe(() => {
        this._dialogRef.close();
      });
  }
}

export interface RecruiterPlanSelectionDialogData {
  recruiterPlans: RecruiterContractDto[];
  accountId: number;
}
