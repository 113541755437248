<a
  [routerLink]="['/app/my-list', list?.id ?? listId]"
  [queryParams]="{ listType: list?.listType ?? listType }"
  target="_blank">
  <mat-icon
    matTooltip="View list in new tab"
    class="icon-size-5 align-middle ml-1 text-gray-800"
    [svgIcon]="'heroicons_outline:arrow-top-right-on-square'"
    (click)="$event.stopPropagation()"></mat-icon>
</a>
