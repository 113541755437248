<div class="flex items-center bg-warn m-0">
  <div class="flex-1">
    <span class="text-white">
      {{ message }}
    </span>
  </div>
  <div class="w-2/12 flex text-white justify-end items-end">
    <span matSnackBarActions>
      <button
        heyreachButton
        buttonType="default"
        class="text-white"
        (click)="snackBarRef.dismissWithAction()">
        OK
      </button>
    </span>
  </div>
</div>
