import { Pipe, PipeTransform } from '@angular/core';

import * as moment from 'moment';

@Pipe({ name: 'customDateFormat' })
export class DateFormatPipe implements PipeTransform {
  transform(value: Date | moment.Moment, format: string): any {
    return moment(value).local().format(format);
  }
}
